import CommissionsReportContainer from 'containers/reports/CommissionsReport'
import React from 'react'

const CommissionsReportPage: React.FC = () => {
  return <CommissionsReportContainer />
}

CommissionsReportPage.displayName = 'CommissionsReportPage'

export default CommissionsReportPage
