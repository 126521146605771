import Debug from 'debug'

import * as Api from 'types/api/reports'
import {
  ChargesReportAgentRow,
  ChargesReportAffiliateRow,
  CommissionsReportAgentRow,
  CommissionsReportCommissionRow,
  CommissionsReportAffiliateRow,
} from 'types/app/reports'
import { mapNumber, mapString } from './common'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'

const debug = Debug('services:map:reports')

export const mapAffiliateCharges = (data: Api.ChargesReportAffiliateRowData): ChargesReportAffiliateRow => {
  try {
    return {
      affiliateId: mapString(data.affiliateId),
      affiliateName: mapString(data.affiliateName),
      totalChargesNumber: mapNumber(data.totalChargesNumber),
      totalChargesAmount: mapNumber(data.totalChargesAmount),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapAgentCharges = (data: Api.ChargesReportListData): ChargesReportAgentRow => {
  try {
    return {
      agentId: mapString(data.agentId),
      agentName: mapString(data.agentName),
      totalChargesNumber: mapNumber(data.totalChargesNumber),
      totalChargesAmount: mapNumber(data.totalChargesAmount),
      affiliates: (data.affiliates || []).map((item) => mapAffiliateCharges(item)),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapChargesReport = (data: Api.ChargesReportListData[]): ChargesReportAgentRow[] => {
  try {
    return data.map((item) => mapAgentCharges(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapAffiliateCommissionsRow = (
  data: Api.CommissionsReportAffiliateRowData,
): CommissionsReportAffiliateRow => {
  try {
    return {
      affiliateId: mapString(data.affiliateId),
      affiliateName: mapString(data.affiliateName),
      agentId: mapString(data.agentId),
      agentName: mapString(data.agentName),
      totalChargesNumber: mapNumber(data.totalChargesNumber),
      totalChargesAmount: mapNumber(data.totalChargesAmount),
      commissionPerc: mapNumber(data.commissionPerc),
      totalCommissionsAmount: mapNumber(data.totalCommissionsAmount),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapAgentCommissionsRow = (
  data: Api.CommissionsReportCommissionRowData,
): CommissionsReportCommissionRow => {
  try {
    return {
      commissionPerc: mapNumber(data.commissionPerc),
      totalCommissionsAmount: mapNumber(data.totalCommissionsAmount),
      totalChargesNumber: mapNumber(data.totalChargesNumber),
      totalChargesAmount: mapNumber(data.totalChargesAmount),
      affiliates: (data.affiliates || []).map((item) => mapAffiliateCommissionsRow(item)),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCommissionsReportRow = (data: Api.CommissionsReportListData): CommissionsReportAgentRow => {
  try {
    return {
      agentId: mapString(data.agentId),
      agentName: mapString(data.agentName),
      totalChargesNumber: mapNumber(data.totalChargesNumber),
      totalChargesAmount: mapNumber(data.totalChargesAmount),
      totalCommissionsAmount: mapNumber(data.totalCommissionsAmount),
      commissions: (data.commissions || []).map((item) => mapAgentCommissionsRow(item)),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCommissionsReport = (data: Api.CommissionsReportListData[]): CommissionsReportAgentRow[] => {
  try {
    return data.map((item) => mapCommissionsReportRow(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
