import React from 'react'

import { useTranslation } from 'react-i18next'

import { Descriptions } from 'antd'
import style from './styles.module.scss'

interface Props {
  prefix: string
  number: number
  amount: number
  currencySymbol: string
}

const CompleteCharge: React.FC<Props> = ({ prefix, number, amount, currencySymbol }) => {
  const { t } = useTranslation()

  return (
    <section className={style['Complete-charge']}>
      <Descriptions title={t('charge.summary')} column={1} bordered>
        <Descriptions.Item label={t('charge.number')}> {`${prefix} ${number}`}</Descriptions.Item>
        <Descriptions.Item label={t('charge.amount')}>
          {amount}
          {currencySymbol}
        </Descriptions.Item>
      </Descriptions>
    </section>
  )
}

CompleteCharge.displayName = 'CompleteCharge'

export default CompleteCharge
