import React, { useContext, useState } from 'react'

import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE, userContext } from 'core'

import { InvoicesFilter } from 'types/app/filters'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'
import { UserRole } from 'types/enums'
import InvoicesListTable from 'components/invoices/InvoicesListTable'
import { SearchInvoicesRequest } from 'types/api/invoices'
import { useSearchInvoices } from 'services/query/invoices'

const InvoicesTableContainer: React.FC = () => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(TABLE_PAGINATION_DEFAULT_PAGE_SIZE)
  const [sorterField, setSorterField] = useState('invoiceDate')
  const [sorterDirection, setSorterDirection] = useState('descend')
  const userSettings = useSelector(selectUserSettings)
  const { role: userRole } = useContext(userContext)

  const initialFilter: InvoicesFilter = {
    dateFrom: dayjs().subtract(1, 'months').toDate(),
    dateTo: dayjs().toDate(),
    status: null,
    userId: '',
  }

  const [filter, setFilter] = useState<InvoicesFilter>(initialFilter)

  const searchInstanceId = userRole === UserRole.SuperAdmin ? userSettings?.selectedInstance?.id || null : -1

  const queryParams: SearchInvoicesRequest = {
    page,
    pageSize,
    instanceId: searchInstanceId,
    dateFrom: filter?.dateFrom.toISOString(),
    dateTo: filter?.dateTo.toISOString(),
    status: filter?.status,
    orderBy: sorterField || 'registrationDate',
    orderByDirection: sorterDirection === 'descend' ? -1 : 1,
    userId: filter.userId,
  }

  const { data: invoices, isFetched, isLoading } = useSearchInvoices(queryParams)
  const dataLoading: boolean = isLoading || !isFetched

  const handleChange = (pagination, filters, sorter) => {
    if (pagination && pagination.current) {
      setPage(pagination.current - 1)
      setPageSize(pagination.pageSize)
    }

    setSorterField(sorter.field)

    if (sorter.hasOwnProperty('column')) {
      setSorterField(sorter.field)
      setSorterDirection(sorter.order)
    }
  }

  const handleFiltering = (filter: InvoicesFilter) => {
    setPage(0)
    setSorterField('invoiceDate')
    setSorterDirection('descend')
    setFilter(filter)
  }

  return (
    <InvoicesListTable
      initialFilter={initialFilter}
      dataSource={invoices}
      isLoading={dataLoading}
      handleFiltering={handleFiltering}
      handleChange={handleChange}
    />
  )
}

InvoicesTableContainer.displayName = 'InvoicesTableContainer'

export default InvoicesTableContainer
