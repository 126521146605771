import InvoicesTableContainer from 'containers/invoices/InvoicesTable'
import React from 'react'

const InvoicesPage: React.FC = () => {
  return <InvoicesTableContainer />
}

InvoicesPage.displayName = 'InvoicesPage'

export default InvoicesPage
