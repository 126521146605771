import Debug from 'debug'

import * as Api from 'types/api/admin'
import {
  Country,
  Customer,
  Instance,
  InstanceDetails,
  Currency,
  Language,
  CustomerDetails,
  CreditLimits,
} from 'types/app/admin'

import { mapBoolean, mapDate, mapNumber, mapString } from './common'

import { getApplicationError } from 'core'

import { ErrorCode } from 'types/enums'

const debug = Debug('services:map:admin')

export const mapCountry = (data: Api.CountryListData): Country => {
  try {
    return {
      id: mapNumber(data.id),
      name: mapString(data.name),
      iso: mapString(data.iso),
      vat: mapNumber(data.vat),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCountryList = (data: Api.CountryListData[]): Country[] => {
  try {
    return data.map((item) => mapCountry(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapInstanceDetails = (data: Api.InstanceDetailsData): InstanceDetails => {
  try {
    return {
      id: mapNumber(data.id),
      name: mapString(data.name),
      currencyId: mapNumber(data.currencyId),
      cultureCode: mapString(data.cultureCode),
      siteUrl: mapString(data.siteUrl),
      apiUrl: mapString(data.apiUrl),
      apiKey: mapString(data.apiKey),
      ipsWhitelist: mapString(data.ipsWhitelist),
      smsNumber: mapString(data.smsnumber),
      smsAuthId: mapString(data.smsauthId),
      smsAuthToken: mapString(data.smsauthToken),
      smsTemplate: mapString(data.smsTemplate),
      smtpHost: mapString(data.smtpHost),
      smtpPort: mapNumber(data.smtpPort),
      smtpUsername: mapString(data.smtpUsername),
      smtpPassword: mapString(data.smtpPassword),
      smtpProtocol: mapString(data.smtpProtocol),
      connectionString: mapString(data.connectionString),
      sendSms: mapBoolean(data.sendSms),
      viewCode: mapBoolean(data.viewCode),
      customerId: mapNumber(data.customerId),
      customer: mapString(data.customer),
      languageId: mapNumber(data.languageId),
      language: mapString(data.language),
      countryId: mapNumber(data.countryId),
      registrationDate: mapDate(data.registrationDate),
      lastUpdate: mapDate(data.lastUpdate),
      creditGranted: mapNumber(data.creditGranted),
      dailyLimit: mapNumber(data.dailyLimit),
      monthlyLimit: mapNumber(data.monthlyLimit),
      chargeExpirationDays: mapNumber(data.chargeExpirationDays),
      invoicePayTerm: mapNumber(data.invoicePayTerm),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCreateEditFromInstance = (data: InstanceDetails): Api.InstanceCreateEditRequest => {
  try {
    return {
      name: mapString(data.name),
      currencyId: mapNumber(data.currencyId),
      cultureCode: mapString(data.cultureCode),
      siteUrl: mapString(data.siteUrl),
      apiUrl: mapString(data.apiUrl),
      apiKey: mapString(data.apiKey),
      ipsWhitelist: mapString(data.ipsWhitelist),
      smsnumber: mapString(data.smsNumber),
      smsauthId: mapString(data.smsAuthId),
      smsauthToken: mapString(data.smsAuthToken),
      smsTemplate: mapString(data.smsTemplate),
      smtpHost: mapString(data.smtpHost),
      smtpPort: mapNumber(data.smtpPort),
      smtpUsername: mapString(data.smtpUsername),
      smtpPassword: mapString(data.smtpPassword),
      smtpProtocol: mapString(data.smtpProtocol),
      connectionString: mapString(data.connectionString),
      sendSms: mapBoolean(data.sendSms),
      viewCode: mapBoolean(data.viewCode),
      customerId: mapNumber(data.customerId),
      languageId: mapNumber(data.languageId),
      language: mapString(data.language),
      countryId: mapNumber(data.countryId),
      creditGranted: mapNumber(data.creditGranted),
      dailyLimit: mapNumber(data.dailyLimit),
      monthlyLimit: mapNumber(data.monthlyLimit),
      chargeExpirationDays: mapNumber(data.chargeExpirationDays),
      invoicePayTerm: mapNumber(data.invoicePayTerm),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapInstance = (data: Api.InstanceListData): Instance => {
  try {
    return {
      id: mapNumber(data.id),
      name: mapString(data.name),
      customerId: mapNumber(data.customerId),
      customer: mapString(data.customer),
      languageId: mapNumber(data.languageId),
      language: mapString(data.language),
      countryId: mapNumber(data.countryId),
      apiUrl: mapString(data.apiUrl),
      currencyId: mapNumber(data.currencyId),
      registrationDate: mapDate(data.registrationDate),
      lastUpdate: mapDate(data.lastUpdate),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapInstanceList = (data: Api.InstanceListData[]): Instance[] => {
  try {
    return data.map((item) => mapInstance(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCustomer = (data: Api.CustomerListData): Customer => {
  try {
    return {
      id: mapNumber(data.id),
      businessName: mapString(data.businessName),
      isActive: mapBoolean(data.isActive),
      town: mapString(data.town),
      email: mapString(data.email),
      countryId: mapNumber(data.countryId),
      country: mapString(data.country),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCustomerDetails = (data: Api.CustomerDetailsData): CustomerDetails => {
  try {
    return {
      id: mapNumber(data.id),
      businessName: mapString(data.businessName),
      fiscalCode: mapString(data.fiscalCode),
      vatNumber: mapString(data.vatNumber),
      email: mapString(data.email),
      phone: mapString(data.phone),
      mobilePhone: mapString(data.mobilePhone),
      address: mapString(data.address),
      town: mapString(data.town),
      countryId: mapNumber(data.countryId),
      country: mapString(data.country),
      isActive: mapBoolean(data.isActive),
      registrationDate: mapDate(data.registrationDate),
      lastUpdate: mapDate(data.lastUpdate),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCreateEditFromCustomer = (data: CustomerDetails): Api.CustomerCreateEditRequest => {
  try {
    return {
      id: mapNumber(data.id),
      businessName: mapString(data.businessName),
      fiscalCode: mapString(data.fiscalCode),
      vatNumber: mapString(data.vatNumber),
      email: mapString(data.email),
      phone: mapString(data.phone),
      mobilePhone: mapString(data.mobilePhone),
      address: mapString(data.address),
      town: mapString(data.town),
      countryId: mapNumber(data.countryId),
      isActive: mapBoolean(data.isActive),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

// Currencies
export const mapCurrency = (data: Api.CurrencyListData): Currency => {
  try {
    return {
      id: mapString(data.id),
      name: mapString(data.name),
      code: mapString(data.code),
      thousandSeparator: mapString(data.thousandSeparator),
      decimalSeparator: mapString(data.decimalSeparator),
      symbol: mapString(data.symbol),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCurrencyToApi = (data: Currency): Api.CurrencyListData => {
  const mappedData: any = {
    name: mapString(data.name),
    code: mapString(data.code),
    thousandSeparator: mapString(data.thousandSeparator),
    decimalSeparator: mapString(data.decimalSeparator),
    symbol: mapString(data.symbol),
  }
  if (data.id) {
    mappedData.id = mapString(data.id)
  }
  return mappedData
}

export const mapCurrencyList = (data: Api.CurrencyListData[]): Currency[] => {
  try {
    return data.map((item) => mapCurrency(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCustomerList = (data: Api.CustomerListData[]): Customer[] => {
  try {
    return data.map((item) => mapCustomer(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapLanguage = (data: Api.LanguageListData): Language => {
  try {
    return {
      id: mapNumber(data.id),
      name: mapString(data.name),
      iso: mapString(data.iso),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapLanguageList = (data: Api.LanguageListData[]): Language[] => {
  try {
    return data.map((item) => mapLanguage(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCreditLimits = (data: Api.CreditLimitsData): CreditLimits => {
  try {
    return { creditLimit: mapNumber(data.creditLimit), status: mapNumber(data.status) }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
