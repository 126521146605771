import ChargeNew from 'components/charges/ChargeNew'
import React, { useState } from 'react'
import { useCreateCharge, useLoadCurrentUserActiveBalance } from 'services/query/charges'
import { generatePath, useNavigate } from 'react-router-dom'
import routes from 'config/routes'
import { ChargeCreateRequest } from 'types/api/charges'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'

const ChargeNewContainer: React.FC = () => {
  const { t } = useTranslation()
  const { data: activeBalance, isFetching } = useLoadCurrentUserActiveBalance()
  const createCharge = useCreateCharge()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (isFetching) return null

  const onConfirm = async (mobile: string, amount: number) => {
    setIsSubmitting(true)
    try {
      const model: ChargeCreateRequest = {
        amount,
        mobilePhone: mobile,
      }
      await createCharge(model)
      notification.success({ message: t('messagesCrud.chargeCreated') })
      navigate(generatePath(routes.charges.url), { replace: true })
    } catch (error) {
      console.error(error)
      notification.error({ message: `${t('messagesCrud.saveError')} (${error.data?.detail || ''})` })
      setIsSubmitting(false)
    }
    setIsSubmitting(false)
  }

  return (
    <ChargeNew
      activeBalance={activeBalance}
      accountStatus={activeBalance.status}
      onConfirm={onConfirm}
      isSubmitting={isSubmitting}
    />
  )
}
ChargeNewContainer.displayName = 'ChargeNewContainer'
export default ChargeNewContainer
