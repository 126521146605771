import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'
import CountryDetails from 'components/countries/CountryDetails'
import { useLoadCountry } from 'services/query/admin'
import CountryEdit from 'components/countries/CountryEdit'
import { useRouter } from 'core/useRouter'

const CountryDetailsContainer: React.FC = () => {
  const [editMode, setEditMode] = useState(false)

  const { id } = useParams()
  const router = useRouter()
  const { data: country } = useLoadCountry(id)
  useEffect(() => {
    if (router.query.editmode) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [router.query])

  if (id === 'new') {
    return <CountryEdit />
  }

  if (editMode) {
    return <CountryEdit country={country} />
  }

  return <CountryDetails country={country} />
}

export default CountryDetailsContainer
