import ChargesReportContainer from 'containers/reports/ChargesReport'
import React from 'react'

const ChargesReportPage: React.FC = () => {
  return <ChargesReportContainer />
}

ChargesReportPage.displayName = 'ChargesReportPage'

export default ChargesReportPage
