import * as K from './constants'
import produce from 'immer'
import { LoadingState } from 'types/enums'
import { AnyAction } from 'redux'
import * as Store from 'types/store'
import { Instance } from 'types/app/admin'

export const initialState: Store.UserState = {
  user: null,
  settings: {
    selectedInstance: null,
    selectedLanguage: 'en',
  },
  login: {
    status: LoadingState.Unset,
    error: null,
  },
  forgotPassword: {
    status: LoadingState.Unset,
    error: null,
  },
  resetPassword: {
    status: LoadingState.Unset,
    error: null,
  },
}

const reducer = (state = initialState, action: AnyAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case K.SET_OPERATION_STATE:
        draft[action.payload.operation] = action.payload.state
        break
      case K.SET_OPERATION_STATUS:
        draft[action.payload.operation] = {
          ...draft[action.payload.operation],
          status: action.payload.status,
        }
        break
      case K.SET_OPERATION_ERROR:
        draft[action.payload.operation] = {
          ...draft[action.payload.operation],
          error: action.payload.error,
        }
        break
      case K.FETCH_USER_SUCCESS:
        draft.user = action.payload as Store.User
        break
      case K.SET_USER_INSTANCE:
        draft.settings = {
          ...draft.settings,
          selectedInstance: action.payload as Instance,
        }
        break
      case K.SET_USER_LANGUAGE:
        draft.settings = {
          ...draft.settings,
          selectedLanguage: action.payload as string,
        }
        break
      case K.LOGOUT:
        return {
          user: initialState.user,
          login: initialState.login,
          forgotPassword: initialState.forgotPassword,
          resetPassword: initialState.resetPassword,
        }
    }
  })
}

export default reducer
