import React from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import { useLoadLanguageList } from 'services/query/admin'
import { Language } from 'types/app/admin'
import { tableSorter } from 'core/helpers'
import TopPage from 'components/TopPage'
import TableComponent from 'components/Table'
import { Card, Button, Typography, Modal, Space, Tooltip } from 'antd'
import { DeleteFilled, EditFilled, EyeFilled, PlusOutlined } from '@ant-design/icons'
import style from './styles.module.scss'

interface Props {
  onDeleteLanguage: (language) => void
}

const LanguagesTable: React.FC<Props> = ({ onDeleteLanguage }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: languages, refetch, isFetched, isLoading } = useLoadLanguageList()
  const dataLoading: boolean = isLoading || !isFetched
  const actions = ['details', 'edit', 'delete']

  const handleDelete = (language: Language) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDeleteLanguage(language)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  React.useEffect(() => {
    refetch()
  }, [languages])

  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: '10%',
      fixed: true,
      sorter: (a, b) => tableSorter(a.id, b.id),
    },
    {
      key: 'name',
      title: t('general.name'),
      dataIndex: 'name',
      width: '30%',
      sorter: (a, b) => tableSorter(a.name, b.name),
    },
    {
      key: 'iso',
      title: t('languages.iso'),
      dataIndex: 'iso',
      width: '30%',
      sorter: (a, b) => tableSorter(a.iso, b.iso),
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'actions',
      className: 'cell-actions',
      width: '30%',
      // eslint-disable-next-line react/display-name
      render: (text, record) => (
        <Space>
          {actions.map((action) => {
            switch (action) {
              case 'edit':
                return (
                  <Link
                    key={`action-edit-${record.id}`}
                    to={generatePath(routes.languageDetails.url, { id: record.id }) + '?editMode'}
                  >
                    <Tooltip title={t('tooltip.edit')}>
                      <EditFilled />
                    </Tooltip>
                  </Link>
                )
              case 'delete':
                return (
                  <Tooltip key={`action-delete-${record.id}`} title={t('tooltip.delete')}>
                    <DeleteFilled onClick={() => handleDelete(record)} />
                  </Tooltip>
                )
              case 'details':
                return (
                  <Link
                    key={`action-details-${record.id}`}
                    to={generatePath(routes.languageDetails.url, { id: record.id })}
                  >
                    <Tooltip title={t('tooltip.details')}>
                      <EyeFilled />
                    </Tooltip>
                  </Link>
                )
              default:
                return
            }
          })}
        </Space>
      ),
    },
  ]

  const crumbs = () => [{ route: routes.home }, { route: routes.languages }]

  return (
    <section className={style['languages']}>
      <TopPage title={t('languages.title')} crumbs={crumbs()}>
        <Button
          type="primary"
          onClick={() => navigate(generatePath(routes.languageDetails.url, { id: 'new' }))}
        >
          {t('btn.createLang')} <PlusOutlined />
        </Button>
      </TopPage>
      <section className="app-main-content">
        <Card>
          <TableComponent isLoading={dataLoading} columns={columns} dataSource={languages} />
        </Card>
      </section>
    </section>
  )
}
LanguagesTable.displayName = 'Languages'

export default LanguagesTable
