import React, { useState } from 'react'

import { useDeleteInstance, useLoadCustomerList, useSearchInstances } from 'services/query/admin'
import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE } from 'core'
import { SearchInstancesRequest } from 'types/api/admin'
import { InstanceFilter } from 'types/app/filters'
import InstancesTable from 'components/instances/InstancesTable'

const InstancesTableContainer: React.FC = () => {
  const [sorterField, setSorterField] = useState('name')
  const [sorterDirection, setSorterDirection] = useState('ascend')
  const initialFilter: InstanceFilter = { customerId: null, apiUrl: '', name: '' }
  const [filter, setFilter] = useState<InstanceFilter>(initialFilter)

  const queryParams: SearchInstancesRequest = {
    page: 0,
    pageSize: TABLE_PAGINATION_DEFAULT_PAGE_SIZE,
    name: filter.name,
    apiUrl: filter.apiUrl,
    customerId: filter.customerId || null,
    orderBy: sorterField || 'name',
    orderByDirection: sorterDirection === 'ascend' ? 1 : -1,
  }

  const { data: instances, refetch, isFetched, isLoading } = useSearchInstances(queryParams)
  const dataLoading: boolean = isLoading || !isFetched
  const { data: customers } = useLoadCustomerList()
  const deleteInstance = useDeleteInstance()

  const handleChange = (pagination, filters, sorter) => {
    setSorterField(sorter.field)

    if (sorter.hasOwnProperty('column')) {
      setSorterField(sorter.field)
      setSorterDirection(sorter.order)
    }
  }

  const handleFiltering = (filter: InstanceFilter) => {
    setSorterField('name')
    setSorterDirection('ascend')
    setFilter(filter)
  }

  const handleDelete = async (id: string) => {
    await deleteInstance(id)
    await refetch()
  }

  return (
    <InstancesTable
      customers={customers}
      initialFilter={initialFilter}
      dataSource={instances.data}
      isLoading={dataLoading}
      handleChange={handleChange}
      handleFiltering={handleFiltering}
      handleDelete={handleDelete}
    />
  )
}

InstancesTableContainer.displayName = 'InstancesTableContainer'

export default InstancesTableContainer
