import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Enums from 'types/enums'
import { InfoCircleOutlined, QuestionCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'

interface Props {
  value: Enums.MessageType
}
const MessageType: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation()
  switch (value) {
    case Enums.MessageType.Info:
      return (
        <Tooltip title={t('tooltip.info')}>
          <InfoCircleOutlined style={{ color: 'green' }} />
        </Tooltip>
      )
    case Enums.MessageType.Warning:
      return (
        <Tooltip title={t('tooltip.warning')}>
          <WarningOutlined style={{ color: 'red' }} />
        </Tooltip>
      )
    case Enums.MessageType.HelpDesk:
      return (
        <Tooltip title={t('tooltip.helpDesk')}>
          <QuestionCircleOutlined style={{ color: 'orange' }} />
        </Tooltip>
      )
  }
}

export default MessageType
