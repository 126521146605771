import { createSelector } from 'reselect'
import * as Store from 'types/store'

const user = (state: { root: { user } }) => state.root.user as Store.UserState

// Data Selectors
export const selectLoginState = createSelector(user, (slice) => slice.login)
export const selectForgotPasswordState = createSelector(user, (slice) => slice.forgotPassword)
export const selectResetPasswordState = createSelector(user, (slice) => slice.resetPassword)
export const selectUser = createSelector(user, (slice) => slice.user)
export const selectUserSettings = createSelector(user, (slice) => slice.settings)
