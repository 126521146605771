import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import { TransactionsFilter } from 'types/app/filters'
import { tableSorter } from 'core/helpers'
import TopPage from 'components/TopPage'
import TableComponent from 'components/Table'
import { Card } from 'antd'
import FilterPanel from './FilterPanel'
import style from './styles.module.scss'
import TransactionType from 'components/Statuses/TransactionType'

interface Props {
  dataSource: any
  initialFilter: TransactionsFilter
  isLoading: boolean
  handleChange: (pagination, filters, sorter) => void
  handleFiltering: (filter: TransactionsFilter) => void
}

const TransactionsTable: React.FC<Props> = ({
  dataSource,
  initialFilter,
  isLoading,
  handleFiltering,
  handleChange,
}) => {
  const { t } = useTranslation()

  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: '10%',
      sorter: (a, b) => tableSorter(a.id, b.id),
    },
    {
      key: 'registrationDate',
      title: t('general.registrationDate'),
      dataIndex: 'registrationDate',
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
      sorter: (a, b) => tableSorter(a.registrationDate, b.registrationDate),
    },
    {
      key: 'transactionType',
      title: t('transactions.transactionType'),
      dataIndex: 'idType',
      sorter: (a, b) => tableSorter(a.typeId, b.typeId),
      render: (text, record) => <TransactionType value={record.idType} />,
    },
    {
      key: 'senderEmail',
      title: t('transactions.senderEmail'),
      dataIndex: 'senderName',
      sorter: (a, b) => tableSorter(a.senderEmail, b.senderEmail),
    },
    {
      key: 'recipientEmail',
      title: t('transactions.recipientEmail'),
      dataIndex: 'recipientname',
      sorter: (a, b) => tableSorter(a.recipientEmail, b.recipientEmail),
    },
    {
      key: 'amount',
      title: t('general.amount'),
      dataIndex: 'amount',
      sorter: (a, b) => tableSorter(a.amount, b.amount),
    },
    {
      key: 'creditBalancePrevious',
      title: t('transactions.creditBalancePrevious'),
      dataIndex: 'previousBalance',
      sorter: (a, b) => tableSorter(a.creditBalancePrevious, b.creditBalancePrevious),
    },
    {
      key: 'creditBalanceAfter',
      title: t('transactions.creditBalanceAfter'),
      dataIndex: 'currentBalance',
      sorter: (a, b) => tableSorter(a.creditBalanceAfter, b.creditBalanceAfter),
    },
  ]

  const crumbs = () => [{ route: routes.home }, { route: routes.transactions }]
  return (
    <section className={style['transactions-table']}>
      <TopPage title={t('menu.transactions')} crumbs={crumbs()}></TopPage>
      <section className="app-main-content">
        <FilterPanel initialFilter={initialFilter} onFilter={handleFiltering} />

        <Card className="mt-16">
          <TableComponent
            columns={columns}
            dataSource={dataSource.data}
            total={dataSource.total}
            onChange={handleChange}
            isLoading={isLoading}
          />
        </Card>
      </section>
    </section>
  )
}

export default TransactionsTable
