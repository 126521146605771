import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'

import * as Api from 'types/api/charges'
import { ApiSearchResponse } from 'types/api/common'

const axios = getAxios()

export async function searchCharges(
  queryParams: Api.SearchChargesRequest,
): Promise<ApiSearchResponse<Api.ChargeListData>> {
  try {
    const response = await axios.post(
      config.api.searchCharges(),
      queryParams || {},
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createCharge(model: Api.ChargeCreateRequest): Promise<Api.ChargeCreateResponse> {
  try {
    const response = await axios.post(config.api.charges(), model, await getAuthenticationHeader())
    return response.data as Api.ChargeCreateResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteCharge(chargeId: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.charge(chargeId), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function invalidateCharge(chargeId: number, instanceId?: number): Promise<AxiosResponse> {
  try {
    let requestUrl = config.api.charge(chargeId) + '/invalidate'
    if (instanceId) {
      requestUrl += `?iid=${instanceId}`
    }
    const response = await axios.put(requestUrl, { id: chargeId }, await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getCurrentUserActiveBalance(): Promise<Api.UserActiveBalanceResponse> {
  try {
    const response = await axios.get(config.api.activeBalance(), await getAuthenticationHeader())
    return response.data as Api.UserActiveBalanceResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
