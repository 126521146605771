import { AnyAction } from 'redux'
import { LoadingState, UserOperation } from 'types/enums'
import { ResponseCode } from 'types/enums.api'
import * as Store from 'types/store'
import * as K from './constants'
import { OperationState } from 'types/store'
import { Instance } from 'types/app/admin'

export function fetchUser(): AnyAction {
  return {
    type: K.FETCH_USER,
    payload: null,
  }
}

export function setUserOperationState(operation: UserOperation, state: OperationState): AnyAction {
  return {
    type: K.SET_OPERATION_STATE,
    payload: { operation, state },
  }
}

export function setUserOperationStatus(operation: UserOperation, status: LoadingState): AnyAction {
  return {
    type: K.SET_OPERATION_STATUS,
    payload: { operation, status },
  }
}

export function setError(operation: UserOperation, error: ResponseCode): AnyAction {
  return {
    type: K.SET_OPERATION_ERROR,
    payload: { operation, error },
  }
}

export function forgotPassword(data: Json): AnyAction {
  return {
    type: K.FORGOT_PASSWORD,
    payload: data,
  }
}

export function resetPassword(data: Json): AnyAction {
  return {
    type: K.RESET_PASSWORD,
    payload: data,
  }
}

export function changePassword(data: Json): AnyAction {
  return {
    type: K.CHANGE_PASSWORD,
    payload: data,
  }
}

export function login(data: Store.Login): AnyAction {
  return {
    type: K.LOGIN,
    payload: data,
  }
}

export function logout(): AnyAction {
  return {
    type: K.LOGOUT,
    payload: null,
  }
}

export function setUserInstance(instance: Instance): AnyAction {
  return {
    type: K.SET_USER_INSTANCE,
    payload: instance,
  }
}

export function setUserLanguage(language: string): AnyAction {
  return {
    type: K.SET_USER_LANGUAGE,
    payload: language,
  }
}
