import React from 'react'
import { DeleteFilled } from '@ant-design/icons'
import { Button, Card, Descriptions, Modal, Skeleton, Typography } from 'antd'

import TopPage from 'components/TopPage'
import routes from 'config/routes'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import style from './styles.module.scss'
import { Message } from 'types/app/messages'
import { useParams } from 'react-router-dom'

import { useEffect } from 'react'
import { useReadMessage } from 'services/query/messages'
interface Props {
  message: Message
  onDeleteMessage: (message) => void
}
const MessageDetails: React.FC<Props> = ({ message, onDeleteMessage }) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const readMessage = useReadMessage(parseInt(id))

  const crumbs = () => [
    { route: routes.home },
    { route: routes.messages },
    { route: routes.messageDetails, routeParam: { key: 'id', value: id } },
  ]

  const onReadMessage = async () => {
    try {
      await readMessage()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (message?.readOnDate === null) {
      onReadMessage()
    }
  }, [message])

  const handleDelete = (message: any) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDeleteMessage(message)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  return (
    <section className={style['messages']}>
      <TopPage title={t('message.messageDetails')} crumbs={crumbs()} />
      <section className="app-main-content">
        <Card
          style={{ width: '100%' }}
          actions={[
            <Button type="primary" style={{ width: '100px' }} key="delete">
              <DeleteFilled onClick={() => handleDelete(message.id)} style={{ color: '#fff' }} />
            </Button>,
          ]}
        >
          <Skeleton loading={false}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label={t('message.date')}>
                {dayjs(message?.sendDate).format('DD.MM.YYYY')}
              </Descriptions.Item>
              <Descriptions.Item label={t('message.senderName')}>{message?.senderName}</Descriptions.Item>
              <Descriptions.Item label={t('message.subject')}>{message?.subject}</Descriptions.Item>
              <Descriptions.Item label={t('message.body')}>{message?.body}</Descriptions.Item>
            </Descriptions>
          </Skeleton>
        </Card>
      </section>
    </section>
  )
}

MessageDetails.displayName = 'MessagesTable'

export default MessageDetails
