import React from 'react'
import { useTranslation } from 'react-i18next'
import { Message } from 'types/app/messages'
import { useDeleteMessage } from 'services/query/messages'

import MessagesTable from 'components/messages/MessagesTable'
import { notification } from 'antd'

const MessagesTableContainer: React.FC = () => {
  const { t } = useTranslation()

  const deleteMessage = useDeleteMessage()

  const onDeleteMessage = async (message: Message) => {
    try {
      await deleteMessage(message)
      notification.success({ message: t('messagesCrud.messageDeleted') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  return <MessagesTable onDeleteMessage={onDeleteMessage} />
}

MessagesTableContainer.displayName = 'MessagesTableContainer'

export default MessagesTableContainer
