import ChargesTableContainer from 'containers/charges/ChargesTable'
import React from 'react'

const ChargesPage: React.FC = () => {
  return <ChargesTableContainer />
}

ChargesPage.displayName = 'ChargesPage'

export default ChargesPage
