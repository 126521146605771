import React, { useEffect, useState } from 'react'
import { User } from 'types/app/users'
import ChangePassword from './ChangePassword'
import TopPage from 'components/TopPage'
import routes from 'config/routes'
import { Operation, UserRole } from 'types/enums'
import ChangeAccountDetails from './ChangeAccountDetails'
import ChangeRoleDetails from './ChangeRoleDetails'
import ChangeUserCredits from './ChangeUserCredits'
import { EuroCircleFilled, LockFilled, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import { Tabs } from 'antd'

import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'

const { TabPane } = Tabs

interface Props {
  user: User
  mode: string
  setMode: (value: string) => void
  operationType: Operation
  onUserDetailsSaved: (userValues: Json, creditValues?: Json) => void
  onUserPasswordChanged: (values: Json) => void
  isFetching: boolean
}

const UserEdit: React.FC<Props> = ({
  user,
  operationType,
  mode,
  setMode,
  isFetching,
  onUserDetailsSaved,
  onUserPasswordChanged,
}) => {
  const { t } = useTranslation()
  const crumbs = () => [
    { route: routes.home },
    { route: routes.users },
    { route: routes.userDetails, routeParam: { key: 'id', value: user.id } },
  ]

  const [showChangeCredits, setShowChangeCredits] = useState(false) // credits are shown only for role Affiliate or Agent
  const [accountDetails, setAccountDetails] = useState(null)

  const onAccountDetailsSubmitted = (values: Json) => {
    setMode('roleDetails')
    setAccountDetails(values)
  }

  const onRoleDetailsSubmitted = (roleDetailsValues: Json) => {
    const data = { ...accountDetails, ...roleDetailsValues }
    if (operationType === Operation.Create && showChangeCredits) {
      setMode('credit')
      setAccountDetails(data)
    } else {
      onUserDetailsSaved(data)
    }
  }

  const onUserCreditsSubmitted = (creditValues: Json) => {
    onUserDetailsSaved(accountDetails, creditValues)
  }

  const onRoleChanged = (role: UserRole) => {
    setShowChangeCredits(role === UserRole.Affiliate || role === UserRole.Agent)
  }

  useEffect(() => {
    setShowChangeCredits(user?.role === UserRole.Affiliate || user?.role === UserRole.Agent)
  }, [user])

  const tabAccountDetails = () => (
    <TabPane
      tab={
        <span onClick={() => setMode('details')}>
          <UserOutlined />
          {t('user.account')}
        </span>
      }
      key="details"
    >
      <ChangeAccountDetails onFinish={onAccountDetailsSubmitted} operationType={operationType} user={user} />
    </TabPane>
  )
  const tabRoleDetails = () => (
    <TabPane
      tab={
        <span onClick={() => setMode(operationType === Operation.Update ? 'roleDetails' : 'details')}>
          <SolutionOutlined />
          {t('user.role')}
        </span>
      }
      key="roleDetails"
    >
      <ChangeRoleDetails
        onFinish={onRoleDetailsSubmitted}
        operationType={operationType}
        user={user}
        isFetching={isFetching}
        onRoleChanged={onRoleChanged}
      />
    </TabPane>
  )

  const tabUserCredits = () => (
    <TabPane
      tab={
        <span onClick={() => setMode('credit')}>
          <EuroCircleFilled />
          {t('general.credit')}
        </span>
      }
      key="credit"
    >
      <ChangeUserCredits
        operationType={operationType}
        user={user}
        spin={isFetching}
        agentId={accountDetails?.idUpperLevel}
        onFinish={onUserCreditsSubmitted}
      />
    </TabPane>
  )

  const tabPassword = () => (
    <TabPane
      tab={
        <span onClick={() => setMode('changePass')}>
          <LockFilled />
          {t('user.changePassword')}
        </span>
      }
      key="changePass"
    >
      <ChangePassword onSave={onUserPasswordChanged} spin={isFetching} />
    </TabPane>
  )

  return (
    <section className={style['user-edit']}>
      <TopPage title={t('user.detailUser')} crumbs={crumbs()} />
      <Tabs className="mt-20" activeKey={mode}>
        {tabAccountDetails()}
        {tabRoleDetails()}
        {showChangeCredits && tabUserCredits()}
        {operationType === Operation.Update && tabPassword()}
      </Tabs>
    </section>
  )
}

UserEdit.displayName = 'UserEdit'

export default UserEdit
