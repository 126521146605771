import React from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import { Language } from 'types/app/admin'
import TopPage from 'components/TopPage'
import FloatingLabel from 'components/FloatingLabel/FloatingLabel'
import { Card, Descriptions, Row, Col, Button, Input, Form } from 'antd'
import style from './styles.module.scss'
interface Props {
  language: Language
  editMode?: boolean
  onSaveLanguage: (newLanguage) => void
}

const LanguageDetails: React.FC<Props> = ({ language, editMode = false, onSaveLanguage }) => {
  const { t } = useTranslation()
  const [isEditMode, setEditMode] = React.useState(false)
  const [formData, setFormData] = React.useState({
    id: null,
    name: null,
    iso: null,
  })
  const crumbs = () => [
    { route: routes.home },
    { route: routes.languages },
    { route: routes.languageDetails, routeParam: { key: 'id', value: language.id } },
  ]

  React.useEffect(() => {
    setEditMode(editMode)
    setFormData({
      id: language.id,
      name: language.name,
      iso: language.iso,
    })
  }, [language, editMode])
  return (
    <section className={style['lan-details']}>
      <TopPage title={t('languages.languageDetails')} crumbs={crumbs()} />
      <section className="app-main-content">
        <Card>
          {isEditMode ? (
            <>
              <Row gutter={[24, 24]}>
                <Col xs={24} lg={12}>
                  <FloatingLabel value={formData.name}>
                    <Form.Item label={t('languages.name')}>
                      <Input
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      />
                    </Form.Item>
                  </FloatingLabel>

                  <FloatingLabel value={formData.iso}>
                    <Form.Item label={t('languages.iso')} className="mt-30">
                      <Input
                        value={formData.iso}
                        onChange={(e) => setFormData({ ...formData, iso: e.target.value })}
                      />
                    </Form.Item>
                  </FloatingLabel>
                </Col>
              </Row>

              <Row className="mt-12">
                <Col xs={24} lg={{ span: 12, offset: 12 }}>
                  <Row justify="end" gutter={[12, 12]}>
                    <Col xs={12} lg={6}>
                      <Button block type="primary" onClick={() => setEditMode(false)}>
                        {t('btn.cancel')}
                      </Button>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Button block onClick={() => onSaveLanguage(formData)}>
                        {t('btn.save')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row justify="end" className="mb-12">
                <Col span={3}>
                  <Button block onClick={() => setEditMode(true)}>
                    {t('btn.edit')}
                  </Button>
                </Col>
              </Row>
              <Descriptions column={1} bordered>
                <Descriptions.Item label={t('languages.name')}>{language.name}</Descriptions.Item>
                <Descriptions.Item label={t('languages.iso')}>{language.iso}</Descriptions.Item>
              </Descriptions>
            </>
          )}
        </Card>
      </section>
    </section>
  )
}
LanguageDetails.displayName = 'LanguageDetails'

export default LanguageDetails
