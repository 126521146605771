import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import routes from 'config/routes'
import { User } from 'types/app/users'
import { useLoadAgents, useLoadRoleList } from 'services/query/users'
import { useLoadCountryList } from 'services/query/admin'
import { selectUserSettings } from 'store/user/selectors'

import { Operation, UserRole, UserRoleLevel } from 'types/enums'
import { useSelector } from 'react-redux'
import { userContext } from 'core'

import FloatingLabel from 'components/FloatingLabel'

import { Button, Col, Form, Input, InputNumber, Row, Select, Spin, Switch } from 'antd'

interface Props {
  operationType: Operation
  user: User
  isFetching: boolean
  onFinish: (values: Json) => void
  onRoleChanged: (role: UserRole) => void
}

const ChangeRoleDetails: React.FC<Props> = ({
  user,
  operationType,
  isFetching: outerIsFetching,
  onFinish,
  onRoleChanged: outerRoleChanged,
}) => {
  const { t } = useTranslation()
  const userSettings = useSelector(selectUserSettings)
  const { role: currentUserRole, userId: currentUserId } = useContext(userContext)
  const currentUserInstanceId =
    currentUserRole === UserRole.SuperAdmin
      ? userSettings?.selectedInstance?.id
      : useContext(userContext).instanceId

  const { data: roles, isFetching: isRolesFetching } = useLoadRoleList()
  const { data: countries, isFetching: isCountriesFetching } = useLoadCountryList()
  const { data: agents, isFetching: isAgentsFetching } = useLoadAgents({
    instanceId: currentUserInstanceId,
    pageSize: 100,
    page: 0,
    roleLevel: UserRoleLevel.Agent,
    email: '',
    surnameName: '',
    orderBy: 'Name',
    orderByDirection: 1,
  })

  const [form] = Form.useForm()

  const [idUpperLevelHidden, setIdUpperLevelHidden] = useState(false)
  const [commissionDisabled, setCommissionDisabled] = useState(false)
  const [idUpperLevel] = useState(user?.idUpperLevel || currentUserId)
  const [submitButtonText, setSubmitButtonText] = useState(t('btn.save'))
  const defaultUserRole = UserRole.Affiliate
  const [roleSelectValue, setRoleSelectValue] = useState(user.role)

  const [initialValues, setInitialValues] = useState({
    ...user,
    role: user?.role || defaultUserRole,
    countryId: user?.countryId || countries[0]?.id,
    instanceId: user?.instanceId || currentUserInstanceId,
    canInvalidateCharge: user?.canInvalidateCharge || false,
  })

  const updateSubmitButtonText = (role: UserRole) => {
    if (operationType === Operation.Create) {
      if (role === UserRole.Affiliate || role === UserRole.Agent) {
        setSubmitButtonText(t('btn.next'))
      } else {
        setSubmitButtonText(t('btn.save'))
      }
    } else {
      // while editing it should be always save as credit save is exclusive
      setSubmitButtonText(t('btn.save'))
    }
  }

  const onRoleChange = (value: UserRole) => {
    outerRoleChanged(value)
    setRoleSelectValue(value)

    if (value !== UserRole.Affiliate) {
      form.setFieldsValue({
        idUpperLevel: null,
      })
    }

    switch (value) {
      case UserRole.SuperAdmin:
        setIdUpperLevelHidden(true)
        setCommissionDisabled(true)
        break
      case UserRole.Operator:
      case UserRole.Admin:
        setIdUpperLevelHidden(true)
        setCommissionDisabled(true)
        break
      case UserRole.Agent:
        setIdUpperLevelHidden(true)
        setCommissionDisabled(false)
        break
      case UserRole.Affiliate:
        setIdUpperLevelHidden(false)
        setCommissionDisabled(false)
        break
      default:
        break
    }
    updateSubmitButtonText(value)
  }

  useEffect(() => {
    onRoleChange(initialValues.role as UserRole)
    updateSubmitButtonText(initialValues.role as UserRole)
  }, [user])

  useEffect(() => {
    switch (currentUserRole) {
      case UserRole.Agent:
        //setIdUpperLevelDisabled(true)
        break
      case UserRole.Affiliate:
        setInitialValues({
          ...initialValues,
          idUpperLevel: idUpperLevel,
        })
        //setIdUpperLevelDisabled(true)
        break
      default:
        break
    }
    if (userSettings?.selectedInstance) {
      //setAgentOptions(agents?.data)
      setInitialValues({
        ...initialValues,
        instanceId: userSettings?.selectedInstance?.id,
      })
    }
  }, [user])

  return (
    <section>
      {(outerIsFetching || isCountriesFetching || isRolesFetching || isAgentsFetching) && (
        <Row align="middle" justify="center" style={{ height: '200px' }}>
          <Spin size="large" />
        </Row>
      )}
      <Form form={form} name="changeUserDetails" initialValues={initialValues} onFinish={onFinish}>
        {!(outerIsFetching || isCountriesFetching || isRolesFetching || isAgentsFetching) && (
          <>
            <Row gutter={[12, 12]} align="bottom" className="mb-24">
              {/* InstanceId, hidden field */}
              <Form.Item name="instanceId">
                <Input hidden type="number" disabled={true} readOnly={true} />
              </Form.Item>
              {/* Role select */}
              <Col span={6}>
                <FloatingLabel value={initialValues.role}>
                  <Form.Item
                    label={t('user.role')}
                    name="role"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Select className="user-role" onChange={onRoleChange}>
                      {roles.map(({ level, name }) => (
                        <Select.Option key={level} value={name}>
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FloatingLabel>
              </Col>

              {/* IdUpperLevel select agents */}
              {!idUpperLevelHidden && (
                <Col span={6}>
                  <FloatingLabel value={initialValues.idUpperLevel}>
                    <Form.Item
                      label={t('general.idUpperLevel')}
                      name="idUpperLevel"
                      rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                    >
                      <Select
                        onChange={(value) => {
                          form.setFieldsValue({
                            idUpperLevel: value,
                          })
                          setInitialValues({
                            ...initialValues,
                            idUpperLevel: value,
                          })
                        }}
                      >
                        {agents.data?.map((agent) => (
                          <Select.Option
                            value={agent.id}
                            key={agent.id}
                          >{`${agent.name} ${agent.surname}`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </FloatingLabel>
                </Col>
              )}
              {!commissionDisabled && (
                <Col span={6}>
                  <FloatingLabel value={user.commission.toString()}>
                    <Form.Item
                      label={t('general.commissionPerc')}
                      name="commission"
                      rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                    >
                      <InputNumber
                        disabled={commissionDisabled}
                        hidden={commissionDisabled}
                        min={0}
                        max={100}
                        prefix={'%'}
                      />
                    </Form.Item>
                  </FloatingLabel>
                </Col>
              )}

              {roleSelectValue === UserRole.Agent && (
                <Col span={6}>
                  <Form.Item
                    className="mb-0"
                    name="canInvalidateCharge"
                    label={t('user.invalidateChargePermission')}
                  >
                    <Switch
                      checkedChildren={t('general.true')}
                      unCheckedChildren={t('general.false')}
                      checked={initialValues.canInvalidateCharge}
                      onChange={(checked) => {
                        form.setFieldsValue({
                          canInvalidateCharge: checked,
                        })

                        setInitialValues({
                          ...initialValues,
                          canInvalidateCharge: checked,
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </>
        )}

        <Form.Item className="btn" wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            <Link to={routes.users.url}>{t('btn.cancel')}</Link>
          </Button>
          <Button type="default" htmlType="submit" disabled={outerIsFetching}>
            {submitButtonText}
          </Button>
        </Form.Item>
      </Form>
    </section>
  )
}

ChangeRoleDetails.displayName = 'ChangeRoleDetails'

export default ChangeRoleDetails
