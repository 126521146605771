import React from 'react'
import TransactionsTableContainer from 'containers/transactions/TransanctionsTable'

const TransactionsPage: React.FC = () => {
  return <TransactionsTableContainer />
}

TransactionsPage.displayName = 'TransactionsPage'

export default TransactionsPage
