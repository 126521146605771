import React from 'react'
import { useParams } from 'react-router-dom'
import { useLoadLnguage } from 'services/query/admin'

import Skeleton from 'components/Skeleton'
import LanguageDetailsContainer from 'containers/languages/LanguageDetails'

const LanguageDetails: React.FC = () => {
  const { id } = useParams()
  if (id === 'new') return <LanguageDetailsContainer language={null} />

  const { data: language, isFetching: isLanguagesFetching } = useLoadLnguage(parseInt(id))
  return <>{isLanguagesFetching ? <Skeleton /> : <LanguageDetailsContainer language={language} />}</>
}

LanguageDetails.displayName = 'LanguageDetails'

export default LanguageDetails
