import React from 'react'

import { Button, Checkbox, Col, Form, Input, Row, Select, Space } from 'antd'

import { useTranslation } from 'react-i18next'

import style from './styles.module.scss'

import { Link } from 'react-router-dom'
import routes from 'config/routes'
import { useLoadCountryList } from 'services/query/admin'
import { CustomerDetails } from 'types/app/admin'
import FloatingLabel from 'components/FloatingLabel/FloatingLabel'

interface Props {
  customer: CustomerDetails
  onSave: (values: Json) => void
}

const ChangeDetails: React.FC<Props> = ({ customer, onSave }) => {
  const { t } = useTranslation()
  const { data: countries, isFetching: isCountriesFetching } = useLoadCountryList()
  const [formData, setFormData] = React.useState(customer)
  if (isCountriesFetching) return null

  const onFinish = (values: Json) => {
    onSave(values)
  }

  return (
    <section className={style['change-details']}>
      <Form name="changeCustomerDetails" initialValues={{ ...customer }} onFinish={onFinish}>
        <Row key={customer.id} gutter={[24, 24]} align="bottom">
          <Col span={12}>
            <FloatingLabel value={formData?.businessName}>
              <Form.Item
                label={t('general.businessName')}
                name="businessName"
                rules={[{ required: true, message: t('general.errors.businessName') }]}
              >
                <Input
                  type="text"
                  onChange={(e) => setFormData({ ...formData, businessName: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.countryId}>
              <Form.Item
                label={t('general.country')}
                name="countryId"
                rules={[{ required: true, message: t('general.errors.country') }]}
              >
                <Select onSelect={(value) => setFormData({ ...formData, countryId: value })}>
                  {countries.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.town}>
              <Form.Item label={t('general.city')} name="town">
                <Input
                  type="text"
                  placeholder={customer.town}
                  onChange={(e) => setFormData({ ...formData, town: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.address}>
              <Form.Item label={t('general.address')} name="address">
                <Input type="text" onChange={(e) => setFormData({ ...formData, address: e.target.value })} />
              </Form.Item>
            </FloatingLabel>
          </Col>

          <Col span={12}>
            <FloatingLabel value={formData?.email}>
              <Form.Item label={t('general.email')} name="email">
                <Input type="text" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.phone}>
              <Form.Item label={t('general.phone')} name="phone">
                <Input type="text" onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
              </Form.Item>
            </FloatingLabel>
          </Col>

          <Col span={12}>
            <FloatingLabel value={formData?.mobilePhone}>
              <Form.Item label={t('general.mobilePhone')} name="mobilePhone">
                <Input
                  type="text"
                  onChange={(e) => setFormData({ ...formData, mobilePhone: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.fiscalCode}>
              <Form.Item label={t('general.fiscalCode')} name="fiscalCode">
                <Input
                  type="text"
                  onChange={(e) => setFormData({ ...formData, fiscalCode: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>

          <Col span={12}>
            <FloatingLabel value={formData?.vatNumber}>
              <Form.Item label={t('general.vatNumber')} name="vatNumber">
                <Input
                  type="text"
                  onChange={(e) => setFormData({ ...formData, vatNumber: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <Form.Item label={t('general.active')} name="isActive" valuePropName="checked" className="m-0">
              <Checkbox type="checkbox" />
            </Form.Item>
          </Col>

          <Col span={24} className="align-right">
            <Form.Item className="m-0">
              <Space>
                <Button type="primary" htmlType="submit">
                  <Link to={routes.customers.url}>{t('btn.cancel')}</Link>
                </Button>
                <Button type="default" htmlType="submit">
                  {t('btn.save')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </section>
  )
}

ChangeDetails.displayName = 'ChangeDetails'

export default ChangeDetails
