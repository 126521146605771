import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import TableComponent from 'components/Table'
import { Card, Typography } from 'antd'
import { Charge } from 'types/app/charges'
import dayjs from 'dayjs'
import { tableSorter } from 'core/helpers'
import ChargeStatus from 'components/Statuses/ChargeStatus'
import { userContext } from 'core'
import { SmsAnswerStatus } from 'components/Statuses'
import { UserRole } from 'types/enums'

interface Props {
  data: Charge[]
  isLoading: boolean
}

const TransactionsTableComponent: React.FC<Props> = ({ data, isLoading }) => {
  const { t } = useTranslation()
  const { role: userRole } = useContext(userContext)

  const COLUMNS = [
    {
      key: 'code',
      title: t('general.code'),
      dataIndex: 'guid',
      width: 170,
      fixed: 'left',
      sorter: (a, b) => tableSorter(a.code, b.code),
    },
    {
      key: 'registrationDate',
      title: t('general.registrationDate'),
      dataIndex: 'registrationDate',
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
      sorter: (a, b) => tableSorter(a.registrationDate, b.registrationDate),
    },
    {
      key: 'mobilePhone',
      title: t('general.mobilePhone'),
      dataIndex: 'mobilePhone',
      sorter: (a, b) => tableSorter(a.mobileNumber, b.mobileNumber),
    },
    {
      key: 'amount',
      title: t('charge.amount'),
      dataIndex: 'amount',
      sorter: (a, b) => tableSorter(a.amount, b.amount),
    },
    {
      key: 'smsAnswerStatus',
      title: t('charge.smsAnswerStatus'),
      dataIndex: 'smsAnswerStatus',
      render: (status) => <SmsAnswerStatus value={status} />,
    },
    {
      key: 'status',
      title: t('general.status'),
      dataIndex: 'status',
      render: (status) => <ChargeStatus value={status} />,
    },
    {
      key: 'smsSentDate',
      title: t('charge.smsSentDate'),
      dataIndex: 'smsSentDate',
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
      sorter: (a, b) => tableSorter(a.smsSentDate, b.smsSentDate),
    },
    {
      key: 'userName',
      title: t('user.userName'),
      dataIndex: 'userName',
      hidden: userRole === UserRole.Affiliate,
      sorter: (a, b) => tableSorter(a.userName, b.userName),
    },
  ]

  return (
    <Card size="small">
      <Typography.Title level={3} className="m-0">
        {t('charts.chargesList')}
      </Typography.Title>
      <Typography.Paragraph>{t('charts.latestFiveCharges')}</Typography.Paragraph>

      <TableComponent columns={COLUMNS} dataSource={data} total={5} isLoading={isLoading} />
    </Card>
  )
}

TransactionsTableComponent.displayName = 'TransactionsTableComponent'

export default TransactionsTableComponent
