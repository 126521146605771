import React from 'react'
import routes from 'config/routes'

import { generatePath, Link, useNavigate } from 'react-router-dom'
import { Country } from 'types/app/admin'

import { useTranslation } from 'react-i18next'
import TopPage from 'components/TopPage'
import FloatingLabel from 'components/FloatingLabel/FloatingLabel'
import { Button, Card, Col, Form, Input, notification, Row } from 'antd'
import style from './styles.module.scss'
import { useCreateCountry, useEditCountry } from 'services/query/admin'
import Debug from 'debug'

interface Props {
  country?: Country
}

const debug = Debug('Frontend:CountryDetailsContainer')

const CountryEdit: React.FC<Props> = ({ country }) => {
  const { t } = useTranslation()
  const createCountry = useCreateCountry()
  const editCountry = useEditCountry()
  const navigate = useNavigate()
  const [formData, setFormData] = React.useState(country)

  const crumbs = () => [
    { route: routes.home },
    { route: routes.countries },
    {
      route: routes.countryDetails,
      routeParam: { key: 'id', value: country ? country?.id : 'new' },
    },
  ]

  const onCountryDetailsSaved = async (model: Country) => {
    debug('onCountryDetailsSaved', model)
    try {
      if (country?.id) {
        await editCountry({ ...country, ...model })
        navigate(generatePath(routes.countries.url), { replace: true })
        notification.success({ message: t('messagesCrud.countryUpdated') })
      } else {
        createCountry(model)
        navigate(generatePath(routes.countries.url), { replace: true })
        notification.success({ message: t('messagesCrud.countryCreated') })
      }
      navigate(routes.countries.url)
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.saveError') })
    }
  }

  const onFinish = (values: Country) => {
    onCountryDetailsSaved(values)
  }

  return (
    <section className={style['country-edit']}>
      <TopPage title={t('menu.countryDetails')} crumbs={crumbs()} />
      <section className="app-main-content">
        <Card>
          <Form initialValues={{ ...country }} onFinish={onFinish}>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <FloatingLabel value={formData?.name}>
                  <Form.Item
                    label={t('general.name')}
                    name="name"
                    requiredMark="optional"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input
                      type="text"
                      placeholder={country?.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col span={12}>
                <FloatingLabel value={formData?.iso}>
                  <Form.Item
                    label={t('countries.iso')}
                    name="iso"
                    requiredMark="optional"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input
                      type="text"
                      placeholder={country?.iso}
                      onChange={(e) => setFormData({ ...formData, iso: e.target.value })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col span={12}>
                <FloatingLabel value={formData?.vat}>
                  <Form.Item label={t('countries.iva')} name="vat">
                    <Input
                      type="number"
                      placeholder={country?.vat.toString()}
                      onChange={(e) => setFormData({ ...formData, vat: parseInt(e.target.value) })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col span={24}>
                <Form.Item className="btn" wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    <Link to={routes.countries.url}>{t('btn.cancel')}</Link>
                  </Button>
                  <Button type="default" htmlType="submit">
                    {t('btn.save')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </section>
    </section>
  )
}

CountryEdit.displayName = 'CountryEdit'

export default CountryEdit
