import React, { useState } from 'react'
import { Button, Col, Input, Card, Row, Select, Form } from 'antd'

import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { InstanceFilter } from 'types/app/filters'
import { Customer } from 'types/app/admin'
import FloatingLabel from 'components/FloatingLabel/FloatingLabel'

interface Props {
  customers: Customer[]
  initialFilter: InstanceFilter
  onFilter: (filter: InstanceFilter) => void
}

const FilterPanel: React.FC<Props> = ({ customers, initialFilter, onFilter }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(initialFilter)
  const onResetFilter = () => {
    setFilter(initialFilter)
    onFilter(initialFilter)
  }
  return (
    <Card className={style['card-panel']}>
      <Row gutter={[12, 12]} align="middle">
        <Col xs={24} lg={12}>
          <Row gutter={[12, 12]} align="middle">
            <Col xs={24} md={8}>
              <FloatingLabel value={filter.customerId}>
                <Form.Item label={t('general.businessName')}>
                  <Select
                    onChange={(value) => setFilter({ ...filter, customerId: value })}
                    value={filter.customerId}
                  >
                    {customers.map(({ id, businessName }) => (
                      <Select.Option key={id} value={id}>
                        {businessName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col xs={24} md={8}>
              <FloatingLabel value={filter.name}>
                <Form.Item label={t('general.name')}>
                  <Input
                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                    value={filter.name}
                    onPressEnter={() => onFilter(filter)}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col xs={24} md={8}>
              <FloatingLabel value={filter.apiUrl}>
                <Form.Item label="Api Url">
                  <Input
                    onChange={(e) => setFilter({ ...filter, apiUrl: e.target.value })}
                    value={filter.apiUrl}
                    onPressEnter={() => onFilter(filter)}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={8}>
          <Row gutter={[12, 12]} align="middle">
            <Col span={12}>
              <Button type="primary" block onClick={onResetFilter}>
                {t('btn.reset')}
              </Button>
            </Col>
            <Col span={12}>
              <Button type="default" block onClick={() => onFilter(filter)}>
                {t('btn.search')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

FilterPanel.displayName = 'FilterPanel'

export default FilterPanel
