import React, { useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { useTheme } from 'providers/ThemeProvider'
import { themes } from './themes'

interface Props {
  children: React.ReactElement
  value: any
}

const FloatingLabel: React.FC<Props> = ({ children, value }) => {
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)

  const [focus, setFocus] = useState(false)

  const wrapperClasses = classNames(
    styles.wrapper,
    focus || value || value === 0 ? styles['wrapper-float'] : '',
    focus ? styles['wrapper-focus'] : '',
    themeStyles.theme,
  )

  return (
    <div className={wrapperClasses} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {children}
    </div>
  )
}

export default FloatingLabel
