import { UserRole } from 'types/enums'

const rolesWithoutAffiliate = [UserRole.SuperAdmin, UserRole.Admin, UserRole.Agent, UserRole.Operator]
const routes: Routes = {
  home: {
    key: 'home',
    url: '/',
    private: true,
    menu: 'menu.home',
  },
  users: {
    key: 'users',
    url: '/users',
    private: true,
    menu: 'menu.users',
    authorize: rolesWithoutAffiliate,
  },
  userDetails: {
    key: 'userDetails',
    url: '/user/:id',
    private: true,
    menu: 'menu.usersDetails',
    authorize: rolesWithoutAffiliate,
  },
  languages: {
    key: 'languages',
    url: '/languages',
    private: true,
    menu: 'menu.languages',
    authorize: [UserRole.SuperAdmin],
  },
  languageDetails: {
    key: 'languageDetails',
    url: '/language/:id',
    private: true,
    menu: 'menu.languageDetails',
    authorize: [UserRole.SuperAdmin],
  },
  countries: {
    key: 'countries',
    url: '/countries',
    private: true,
    menu: 'menu.countries',
    authorize: [UserRole.SuperAdmin],
  },
  countryDetails: {
    key: 'countryDetails',
    url: '/country/:id',
    private: true,
    menu: 'menu.countryDetails',
    authorize: [UserRole.SuperAdmin],
  },
  currencies: {
    key: 'currencies',
    url: '/currencies',
    private: true,
    menu: 'menu.currencies',
    authorize: [UserRole.SuperAdmin],
  },
  currencyDetails: {
    key: 'currencyDetail',
    url: '/currency/:id',
    private: true,
    menu: 'menu.currencyDetails',
    authorize: [UserRole.SuperAdmin],
  },
  instances: {
    key: 'instances',
    url: '/instances',
    private: true,
    menu: 'menu.instances',
    authorize: [UserRole.SuperAdmin],
  },
  instanceDetails: {
    key: 'instanceDetails',
    url: '/instance/:id',
    private: true,
    menu: 'menu.instanceDetails',
    authorize: [UserRole.SuperAdmin],
  },
  customers: {
    key: 'customers',
    url: '/customers',
    private: true,
    menu: 'menu.customers',
    authorize: [UserRole.SuperAdmin],
  },
  customerDetails: {
    key: 'customerDetails',
    url: '/customer/:id',
    private: true,
    menu: 'menu.customerDetails',
    authorize: [UserRole.SuperAdmin],
  },
  charge: {
    key: 'charge',
    url: '/charge/:id',
    private: true,
    menu: 'menu.charge',
    authorize: [UserRole.Affiliate],
  },
  charges: {
    key: 'charges',
    url: '/charges',
    private: true,
    menu: 'menu.charges',
  },
  chargeDetails: {
    key: 'chargeDetails',
    url: '/charge/:id',
    private: true,
    menu: 'menu.chargeDetails',
    authorize: rolesWithoutAffiliate,
  },
  invoice: {
    key: 'invoice',
    url: '/invoice/:id',
    private: true,
    menu: 'menu.invoice',
    authorize: rolesWithoutAffiliate,
  },
  invoices: {
    key: 'invoices',
    url: '/invoices',
    private: true,
    menu: 'menu.invoices',
  },
  transactions: {
    key: 'transactions',
    url: '/transactions',
    private: true,
    menu: 'menu.transactions',
  },
  chargesReport: {
    key: 'chargesReport',
    url: '/charges-report',
    private: true,
    menu: 'menu.reportCharges',
    authorize: rolesWithoutAffiliate,
  },
  commissionsReport: {
    key: 'commissionsReport',
    url: '/commissions-report',
    private: true,
    menu: 'menu.reportCommissions',
    authorize: rolesWithoutAffiliate,
  },
  translations: {
    key: 'translations',
    url: '/translations',
    private: true,
    menu: 'menu.translations',
    authorize: [UserRole.SuperAdmin],
  },
  translationDetails: {
    key: 'translationDetails',
    url: '/translation/:id',
    private: true,
    menu: 'menu.translation',
    authorize: [UserRole.SuperAdmin],
  },
  messages: {
    key: 'messages',
    url: '/messages',
    private: true,
    menu: 'menu.messages',
  },
  messageDetails: {
    key: 'messageDetails',
    url: '/message/:id',
    private: true,
    menu: 'menu.messageDetails',
  },
  support: {
    key: 'support',
    url: '/support',
    private: true,
    menu: 'menu.support',
  },

  //Authentication Routes
  login: {
    key: 'login',
    url: '/login',
    private: false,
    menu: '',
  },
  forgotPassword: {
    key: 'forgotPassword',
    url: '/forgot-password',
    private: false,
    menu: '',
  },
  resetPassword: {
    key: 'resetPassword',
    url: '/reset-password',
    private: false,
    menu: '',
  },
  confirmEmail: {
    key: 'confirmEmail',
    url: '/confirm-email',
    private: false,
    menu: '',
  },
  //Error Routes
  notFoundPage: {
    key: 'not-found',
    url: '/page-not-found',
    private: false,
    menu: '',
  },
  errorPage: {
    key: 'error',
    url: '/error',
    private: false,
    menu: '',
  },
}

export default routes
