import CurrencyDetailsContainer from 'containers/CurrencyDetails'
import React from 'react'
import { useLoadCurrency } from 'services/query/admin'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

const CurrencyDetails: React.FC = () => {
  const { id } = useParams()
  const userDetailsQuery = useLoadCurrency(id)
  const { data: currency, isFetching } = userDetailsQuery

  return (
    <>
      {isFetching ? (
        <Skeleton active avatar paragraph={{ rows: 4 }} />
      ) : (
        <CurrencyDetailsContainer currency={currency} />
      )}
    </>
  )
}

CurrencyDetails.displayName = 'CurrencyDetails'

export default CurrencyDetails
