import React from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'

import { CountryListData } from 'types/api/admin'
import { generatePath, Link } from 'react-router-dom'
import TopPage from 'components/TopPage'
import TableComponent from 'components/Table'
import { Button, Card, Modal, Typography, Space, notification, Tooltip } from 'antd'
import { DeleteFilled, EditFilled, EyeFilled, PlusOutlined } from '@ant-design/icons'
import style from './styles.module.scss'
import { useDeleteCountry } from 'services/query/admin'

interface Props {
  countriesList: CountryListData[]
  isLoading: boolean
}

const Countries: React.FC<Props> = ({ countriesList, isLoading }) => {
  const { t } = useTranslation()
  const deleteCountry = useDeleteCountry()

  const crumbs = () => [{ route: routes.home }, { route: routes.countries }]

  const handleDelete = (countryId: string) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        try {
          deleteCountry(countryId)
          notification.success({ message: t('messagesCrud.countryDeleted') })
        } catch (error) {
          console.error(error)
          notification.error({ message: t('messagesCrud.saveError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      width: '10%',
    },
    {
      key: 'name',
      title: t('general.name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      key: 'iso',
      title: t('countries.iso'),
      dataIndex: 'iso',
      sorter: (a, b) => a.iso.localeCompare(b.iso),
    },
    {
      key: 'iva',
      title: t('countries.iva'),
      dataIndex: 'vat',
      sorter: (a, b) => a.vat - b.vat,
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      // eslint-disable-next-line react/display-name
      render: (id) => (
        <Space>
          <Link key={`action-details-${id}`} to={generatePath(routes.countryDetails.url, { id: id })}>
            <Tooltip title={t('tooltip.details')}>
              <EyeFilled />
            </Tooltip>
          </Link>
          <Link
            key={`action-edit-${id}`}
            to={generatePath(routes.countryDetails.url, { id: id }) + '?editmode=details'}
          >
            <Tooltip title={t('tooltip.edit')}>
              <EditFilled />
            </Tooltip>
          </Link>
          <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
            <DeleteFilled onClick={() => handleDelete(id)} />
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <section className={style['countries']}>
      <TopPage title={t('countries.title')} crumbs={crumbs()}>
        <Button type="primary">
          <Link key="action-create" to={generatePath(routes.countryDetails.url, { id: 'new' })}>
            {t('btn.createCountry')} <PlusOutlined />
          </Link>
        </Button>
      </TopPage>
      <section className="app-main-content">
        <Card>
          <TableComponent columns={columns} isLoading={isLoading} dataSource={countriesList} />
        </Card>
      </section>
    </section>
  )
}

Countries.displayName = 'Countries'

export default Countries
