/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux'

// App
import app from 'store/app/reducer'
import user from 'store/user/reducer'

// Data
import { STATE_KEY as APP_KEY } from 'store/app/constants'
import { STATE_KEY as USER_KEY } from 'store/user/constants'

const RootReducer = combineReducers({
  [APP_KEY]: app,
  [USER_KEY]: user,
})

export default RootReducer
