import { useQuery } from 'react-query'
import * as queryKeys from 'config/queryKeys'
import { SearchTransactionsRequest } from 'types/api/transanctions'
import { searchTransactions } from 'api/transactions'
import { mapTransactionsList } from 'services/map/transactions'

export function useSearchTransactions(queryParams: SearchTransactionsRequest) {
  const queryFunction = async () => {
    const response = await searchTransactions(queryParams)
    return { data: mapTransactionsList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.transactionsSearch(queryParams), queryFunction, {
    placeholderData: { data: [], total: 0 },
    keepPreviousData: true,
    enabled: !!queryParams.instanceId,
  })
}
