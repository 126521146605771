import React, { useState } from 'react'
import routes from 'config/routes'
import { generatePath, useNavigate } from 'react-router-dom'
import { useLoadLanguageList, useCreateTranslation, useEditTranslation } from 'services/query/admin'

import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import TranslationDetails from 'components/translations/TranslationDetails'
import TranslationDetailsNew from 'components/translations/TranslationDetailsNew'
import { Translation } from 'types/app/translations'

interface Props {
  translation?: Translation
}
const TranslationDetailsContainer: React.FC<Props> = ({ translation }) => {
  const { t } = useTranslation()
  const { data: languages } = useLoadLanguageList()
  const navigate = useNavigate()
  const createTranslation = useCreateTranslation()
  const editTranslation = useEditTranslation(translation?.labelTag)
  const [spin, setSpin] = useState(false)

  const onSaveTranslation = async (model: Translation) => {
    try {
      setSpin(true)
      await editTranslation(model)
      setSpin(false)
      navigate(generatePath(routes.translations.url), { replace: true })
      notification.success({ message: t('messagesCrud.translationUpdated') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.existingTag') })
    }
  }

  const onCreateTranslation = async (model: Translation) => {
    try {
      setSpin(true)
      await createTranslation(model)
      setSpin(false)
      navigate(generatePath(routes.translations.url), { replace: true })
      notification.success({ message: t('messagesCrud.translationCreated') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.existingTag') })
    }
  }
  if (translation) {
    return (
      <TranslationDetails
        onSaveTranslation={onSaveTranslation}
        translation={translation}
        languages={languages}
        spin={spin}
      />
    )
  }
  return <TranslationDetailsNew onCreateTranslation={onCreateTranslation} languages={languages} spin={spin} />
}

export default TranslationDetailsContainer
