import { config } from 'core'
import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api/admin'
import { ApiSearchResponse } from 'types/api/common'
import { UserDefaultCreditLimitsRequest } from 'types/api/admin'

const axios = getAxios()

export async function listCountries(): Promise<Api.CountryListData[]> {
  try {
    const response = await axios.get(config.api.countries(), await getAuthenticationHeader())
    return response.data as Api.CountryListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getCountryDetails(countryId: string): Promise<Api.CountryListData> {
  try {
    const response = await axios.get(config.api.country(countryId), await getAuthenticationHeader())
    return response.data as Api.CountryListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createCountry(model: Api.CountryEditRequest): Promise<Api.CountryListData[]> {
  try {
    const response = await axios.post(config.api.countries(), model, await getAuthenticationHeader())
    return response.data as Api.CountryListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteCountry(countryId: string): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.country(countryId), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editCountry(
  countryId: number,
  model: Api.CountryEditRequest,
): Promise<Api.CountryEditRequest> {
  try {
    const response = await axios.put(config.api.country(countryId), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

//cultures
export async function listCultures(): Promise<AxiosResponse[]> {
  try {
    const response = await axios.get(config.api.culture(), await getAuthenticationHeader())
    return response.data as any
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

// instances
export async function listInstances(): Promise<Api.InstanceListData[]> {
  try {
    const response = await axios.get(config.api.instances(), await getAuthenticationHeader())
    return response.data as Api.InstanceListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function searchInstances(
  queryParams?: Api.SearchInstancesRequest,
): Promise<ApiSearchResponse<Api.InstanceListData>> {
  try {
    const response = await axios.post(
      config.api.searchInstances(),
      queryParams || {},
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getInstanceDetails(instanceId: string): Promise<Api.InstanceDetailsData> {
  try {
    const response = await axios.get(config.api.instance(instanceId), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getUserDefaultsCreditLimits(
  model: UserDefaultCreditLimitsRequest,
): Promise<Api.CreditLimitsData> {
  try {
    const response = await axios.post(
      config.api.creditLimitsUserDefaults(),
      model,
      await getAuthenticationHeader(),
    )
    return response.data as Api.CreditLimitsData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createInstance(model: Api.InstanceCreateEditRequest): Promise<Api.InstanceDetailsData> {
  try {
    const response = await axios.post(config.api.instances(), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editInstance(
  instanceId: string,
  model: Api.InstanceCreateEditRequest,
): Promise<Api.InstanceDetailsData> {
  try {
    const response = await axios.put(config.api.instance(instanceId), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteInstance(instanceId: string): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.instance(instanceId), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

// customers
export async function listCustomers(): Promise<Api.CustomerListData[]> {
  try {
    const response = await axios.get(config.api.customers(), await getAuthenticationHeader())
    return response.data as Api.CustomerListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function searchCustomers(
  queryParams: Api.SearchCustomersRequest,
): Promise<ApiSearchResponse<Api.CustomerListData>> {
  try {
    const response = await axios.post(
      config.api.searchCustomers(),
      queryParams || {},
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getCustomerDetails(customerId: string): Promise<Api.CustomerDetailsData> {
  try {
    const response = await axios.get(config.api.customer(customerId), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createCustomer(model: Api.CustomerCreateEditRequest): Promise<Api.CustomerDetailsData> {
  try {
    const response = await axios.post(config.api.customers(), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editCustomer(
  CustomerId: string,
  model: Api.CustomerCreateEditRequest,
): Promise<Api.CustomerDetailsData> {
  try {
    const response = await axios.put(config.api.customer(CustomerId), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteCustomer(CustomerId: string): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.customer(CustomerId), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

// Currencies
export async function listCurrencies(): Promise<Api.CurrencyListData[]> {
  try {
    const response = await axios.get(config.api.currencies(), await getAuthenticationHeader())
    return response.data as Api.CurrencyListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getCurrencyDetails(id: string): Promise<Api.CurrencyListData> {
  try {
    const response = await axios.get(config.api.currency(id), await getAuthenticationHeader())
    return response.data as Api.CurrencyListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createCurrency(model: Api.CurrencyListData): Promise<Api.CurrencyListData> {
  try {
    const response = await axios.post(config.api.currencies(), model, await getAuthenticationHeader())
    return response.data as Api.CurrencyListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editCurrency(id: string, model: Api.CurrencyListData): Promise<Api.CurrencyListData> {
  try {
    const response = await axios.put(config.api.currency(id), model, await getAuthenticationHeader())
    return response.data as Api.CurrencyListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteCurrency(currencyId: string): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.currency(currencyId), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

//Languages
export async function listLanguages(): Promise<Api.LanguageListData[]> {
  try {
    const response = await axios.get(config.api.listLanguages(), await getAuthenticationHeader())
    return response.data as Api.LanguageListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getLanguageDetails(languageId: number): Promise<Api.LanguageListData> {
  try {
    const response = await axios.get(config.api.language(languageId), await getAuthenticationHeader())
    return response.data as Api.LanguageListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editLanguage(
  languageId: number,
  model: Api.LanguageCreateEditRequest,
): Promise<Api.LanguageCreateEditRequest> {
  try {
    const response = await axios.put(config.api.language(languageId), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteLanguage(languageId: number): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.language(languageId), await getAuthenticationHeader())
    return response as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createLanguage(
  model: Api.LanguageCreateEditRequest,
): Promise<Api.LanguageCreateEditRequest> {
  try {
    const response = await axios.post(config.api.newLanguage(), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createTranslation(
  model: Api.TranslationEditRequest,
): Promise<Api.TranslationListData[]> {
  try {
    const response = await axios.post(config.api.createTranslation(), model, await getAuthenticationHeader())
    return response.data as Api.TranslationListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editTranslation(
  translationTag: string,
  model: Api.TranslationEditRequest,
): Promise<Api.TranslationEditRequest> {
  try {
    const response = await axios.put(
      config.api.translation(translationTag),
      model,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
