import React, { useState } from 'react'
import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE } from 'core'

import TranslationsTable from 'components/translations'
import { TranslationsFilter } from 'types/app/filters'
import { ListTranslationsRequest } from 'types/api/translations'
import { useDeleteTranslations, useLoadTranslationsList } from 'services/query/translations'

const TranslationsTableContainer: React.FC = () => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(TABLE_PAGINATION_DEFAULT_PAGE_SIZE)
  const [sorterField, setSorterField] = useState('LabelTag')
  const [sorterDirection, setSorterDirection] = useState('ascend')

  const initialFilter: TranslationsFilter = {
    text: '',
  }
  const [filter, setFilter] = useState(initialFilter)

  const queryParams: ListTranslationsRequest = {
    page,
    pageSize,
    orderBy: sorterField || 'LabelTag',
    orderByDirection: sorterDirection === 'ascend' ? 1 : -1,
    text: filter.text,
  }
  const { data: translations, refetch, isFetched, isLoading } = useLoadTranslationsList(queryParams)
  const dataLoading: boolean = isLoading || !isFetched
  const deleteTranslation = useDeleteTranslations()

  const handleChange = (pagination, filters, sorter) => {
    if (pagination && pagination.current) {
      setPage(pagination.current - 1)
      setPageSize(pagination.pageSize)
    }

    setSorterField(sorter.field)

    if (sorter.hasOwnProperty('column')) {
      setSorterField(sorter.field)
      setSorterDirection(sorter.order)
    }
  }

  const handleFiltering = ({ text }) => {
    setPage(0)
    setSorterField('LabelTag')
    setSorterDirection('ascend')
    setFilter({ text })
  }
  const handleDelete = async (tag: string) => {
    await deleteTranslation(tag)
    await refetch()
  }

  return (
    <TranslationsTable
      initialFilter={initialFilter}
      isLoading={dataLoading}
      handleChange={handleChange}
      handleFiltering={handleFiltering}
      handleDelete={handleDelete}
      dataSource={translations}
    />
  )
}

TranslationsTableContainer.displayName = 'TranslationsTableContainer'

export default TranslationsTableContainer
