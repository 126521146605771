import React, { useContext } from 'react'

import { Button, Col, Form, InputNumber, Row, Select, Spin } from 'antd'

import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import routes from 'config/routes'
import { User } from 'types/app/users'

import { useLoadUserDefaultCreditLimits } from 'services/query/admin'
import { selectUserSettings } from 'store/user/selectors'

import { Operation, UserRole } from 'types/enums'
import { useSelector } from 'react-redux'
import { userContext } from 'core'
import { UserDefaultCreditLimitsRequest } from 'types/api/admin'
import { UserStatus } from 'types/enums'
import FloatingLabel from 'components/FloatingLabel'

interface Props {
  operationType: Operation
  user: User
  agentId?: string
  spin: boolean
  onFinish: (values: Json) => void
}

const ChangeUserCredits: React.FC<Props> = ({ user, agentId, operationType, spin, onFinish }) => {
  const { t } = useTranslation()
  const { role: currentUserRole } = useContext(userContext)
  const userSettings = useSelector(selectUserSettings)
  const defaultCreditLimitsRequest: UserDefaultCreditLimitsRequest = {
    instanceId: userSettings?.selectedInstance?.id.toString(),
    agentId,
  }

  const [form] = Form.useForm()
  const { data: defaultCreditLimits, isFetching: isCreditLimitsLoading } =
    useLoadUserDefaultCreditLimits(defaultCreditLimitsRequest)

  if (isCreditLimitsLoading) return null

  const userCreditLimit =
    operationType === Operation.Create
      ? defaultCreditLimits?.creditLimit || 0
      : user?.account?.creditLimit || 0

  const isSaveDisabled = operationType === Operation.Update && currentUserRole === UserRole.Agent

  const initialValues = {
    creditLimit: userCreditLimit,
    status: user?.account?.status || 0,
  }

  return (
    <section>
      {spin && (
        <Row align="middle" justify="center" style={{ height: '200px' }}>
          <Spin size="large" />
        </Row>
      )}
      <Form form={form} name="changeUserCredits" initialValues={initialValues} onFinish={onFinish}>
        {!spin && (
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <FloatingLabel value={initialValues.creditLimit}>
                <Form.Item
                  label={t('user.creditLimit')}
                  name="creditLimit"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <InputNumber type="number" disabled={currentUserRole === UserRole.Agent} />
                </Form.Item>
              </FloatingLabel>
            </Col>

            {user?.role === UserRole.Affiliate && (
              <Col span={12}>
                <FloatingLabel value={initialValues.status}>
                  <Form.Item
                    label={t('user.status')}
                    name="status"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Select
                      disabled={currentUserRole === UserRole.Agent}
                      onChange={(value) =>
                        form.setFieldsValue({
                          status: value,
                        })
                      }
                    >
                      {Object.keys(UserStatus).map(function (key) {
                        if (parseInt(key) >= 0) {
                          const value = UserStatus[key].charAt(0).toLowerCase() + UserStatus[key].slice(1)
                          return (
                            <Select.Option value={parseInt(key)} key={`status-${value}`}>
                              {t(`status.${value}`)}
                            </Select.Option>
                          )
                        }
                      })}
                    </Select>
                  </Form.Item>
                </FloatingLabel>
              </Col>
            )}
          </Row>
        )}
        <Form.Item className="btn" wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            <Link to={routes.users.url}>{t('btn.cancel')}</Link>
          </Button>
          <Button type="default" htmlType="submit" disabled={isSaveDisabled}>
            {t('btn.save')}
          </Button>
        </Form.Item>
      </Form>
    </section>
  )
}

ChangeUserCredits.displayName = 'ChangeUserCredits'

export default ChangeUserCredits
