import React, { lazy } from 'react'
import Debug from 'debug'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux'
import Seo from 'components/Seo'
import 'core/i18n'
import ErrorBoundary from 'components/errors/ErrorBoundary'
import { getCurrentRoute } from 'core/helpers'
import routes from 'config/routes'
// import PrivateRoute from 'containers/PrivateRoute'
import Header from 'components/layout/Header'
import SideMenu from 'components/layout/SideMenu'
import Footer from 'components/layout/Footer'
import { Layout } from 'antd'

/*** Most used pages; those are not lazy loaded ***/
import AuthProvider from 'containers/AuthProvider'
import Home from 'pages/HomePage'
import Users from 'pages/UsersPage'
import UsersDetails from 'pages/UserDetails'
import Languages from 'pages/LanguagesPage'
import LanguageDetailes from 'pages/LanguageDetails'
import CountryDetails from 'pages/CountryDetails'
import CountriesPage from 'pages/CountriesPage'
import CurrenciesPage from 'pages/currencies/CurrenciesPage'
import CurrencyDetailspage from 'pages/currencies/CurrencyDetails'
import Countries from 'pages/CountriesPage'
import InstancesPage from 'pages/InstancesPage'
import InstanceDetailsPage from 'pages/InstanceDetailsPage'
import ChargePage from 'pages/ChargePage'
import CustomersPage from 'pages/CustomersPage'
import CustomerDetailsPage from 'pages/CustomerDetailsPage'
import ChargesPage from 'pages/ChargesPage'
import InvoicePage from 'pages/InvoicePage'
import InvoicesPage from 'pages/InvoicesPage'
import TransactionsPage from 'pages/TransactionsPage'
import ChargesReportPage from 'pages/ChargesReportPage'
import CommissionsReportPage from 'pages/CommissionsReportPage'
import ScrollToTop from './ScrollToTop'
import TraslationsPage from 'pages/TranslationsPage'
import TranslationDetails from 'pages/TranslationDetails'
import MessagesPage from 'pages/MessagesPage'
import MessageDetails from 'pages/MessageDetails'
import SupportPage from 'pages/SupportPage'

/*** Lazy Pages ***/
// Error pages
const NotFoundPage = lazy(() => import('pages/NotFoundPage'))
const ErrorPage = lazy(() => import('pages/ErrorPage'))

// User pages
const LoginPage = lazy(() => import('pages/LoginPage'))
const ForgotPasswordPage = lazy(() => import('pages/ForgotPasswordPage'))
const ResetPasswordPage = lazy(() => import('pages/ResetPasswordPage'))
const ConfirmEmailPage = lazy(() => import('pages/ConfirmEmail'))

const debug = Debug('Frontend')

const App: React.FC = () => {
  const location = useLocation()

  const currentRoute = getCurrentRoute(location.pathname)
  const isPrivatePage = currentRoute?.private || false
  const isPublicPage = !isPrivatePage
  debug('App started')

  //All public pages, not logged users
  if (isPublicPage) {
    return (
      <ErrorBoundary>
        <Seo />
        <Layout className="app-layout">
          <Layout className="app-layout-container">
            <Layout.Content>
              <Routes>
                {/* Login Pages */}
                <Route path={routes.login.url} element={<LoginPage />} />
                <Route path={routes.forgotPassword.url} element={<ForgotPasswordPage />} />
                <Route path={routes.resetPassword.url} element={<ResetPasswordPage />} />
                <Route path={routes.confirmEmail.url} element={<ConfirmEmailPage />} />
                {/* Errors and Test Pages */}
                <Route path={routes.errorPage.url} element={<ErrorPage />} />
                <Route path={routes.notFoundPage.url} element={<NotFoundPage />} />
                {/* Fallback is login page */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Layout.Content>
          </Layout>
        </Layout>
      </ErrorBoundary>
    )
  }

  //All private pages
  return (
    <ErrorBoundary>
      <Seo />
      <Layout>
        <AuthProvider>
          <SideMenu />
          <Layout className="app-content-container">
            <Header />
            <Layout.Content className="app-content">
              <ScrollToTop />
              <Routes>
                <Route path={routes.home.url} element={<Home />} />
                <Route path={routes.users.url} element={<Users />} />
                <Route path={routes.userDetails.url} element={<UsersDetails />} />
                <Route path={routes.languages.url} element={<Languages />} />
                <Route path={routes.languageDetails.url} element={<LanguageDetailes />} />
                <Route path={routes.countries.url} element={<CountriesPage />} />
                <Route path={routes.currencies.url} element={<CurrenciesPage />} />
                <Route path={routes.currencyDetails.url} element={<CurrencyDetailspage />} />

                <Route path={routes.countries.url} element={<Countries />} />
                <Route path={routes.countryDetails.url} element={<CountryDetails />} />
                <Route path={routes.instances.url} element={<InstancesPage />} />
                <Route path={routes.instanceDetails.url} element={<InstanceDetailsPage />} />
                <Route path={routes.charges.url} element={<ChargesPage />} />
                <Route path={routes.charge.url} element={<ChargePage />} />
                <Route path={routes.customers.url} element={<CustomersPage />} />
                <Route path={routes.customerDetails.url} element={<CustomerDetailsPage />} />
                <Route path={routes.invoice.url} element={<InvoicePage />} />
                <Route path={routes.invoices.url} element={<InvoicesPage />} />
                <Route path={routes.transactions.url} element={<TransactionsPage />} />
                <Route path={routes.chargesReport.url} element={<ChargesReportPage />} />
                <Route path={routes.commissionsReport.url} element={<CommissionsReportPage />} />
                <Route path={routes.translations.url} element={<TraslationsPage />} />
                <Route path={routes.translationDetails.url} element={<TranslationDetails />} />
                <Route path={routes.messages.url} element={<MessagesPage />} />
                <Route path={routes.messageDetails.url} element={<MessageDetails />} />
                <Route path={routes.support.url} element={<SupportPage />} />

                {/* Errors and Test Pages */}
                <Route path={routes.errorPage.url} element={<ErrorPage />} />
                <Route path={routes.notFoundPage.url} element={<NotFoundPage />} />
                {/* Fallback is not found page */}
                <Route path="*" element={<Navigate to={routes.notFoundPage.url} />} />
              </Routes>
              <Footer />
            </Layout.Content>
          </Layout>
        </AuthProvider>
      </Layout>
    </ErrorBoundary>
  )
}

export default App
