import React from 'react'
import TopPage from 'components/TopPage'
import { Skeleton as AntSkeleton, Card } from 'antd'

const Skeleto: React.FC = () => {
  return (
    <>
      <TopPage title={null} crumbs={[]} />
      <section className="app-main-content" style={{ marginTop: -100 }}>
        <Card>
          <AntSkeleton active avatar paragraph={{ rows: 10 }} />
        </Card>
      </section>
    </>
  )
}

Skeleto.displayName = 'Skeleto'

export default Skeleto
