import React, { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import routes from 'config/routes'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import InvoiceView from 'components/invoices/InvoiceView'
import { useCreateInvoice, usePreviewInvoice } from 'services/query/invoices'

import { InvoiceViewMode } from 'types/enums'

const InvoiceNewContainer: React.FC = () => {
  const { t } = useTranslation()
  const { data: invoice, isFetching } = usePreviewInvoice()
  const createInvoice = useCreateInvoice()
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (isFetching) return null

  const onConfirm = async () => {
    setIsSubmitting(true)
    try {
      await createInvoice()
      notification.success({ message: t('messagesCrud.invoiceCreated') })
      navigate(generatePath(routes.invoices.url), { replace: true })
    } catch (error) {
      console.error(error)
      notification.error({ message: `${t('messagesCrud.saveError')} (${error.data})` })
      setIsSubmitting(false)
    }
    setIsSubmitting(false)
  }

  return (
    <InvoiceView
      invoice={invoice}
      onSubmit={onConfirm}
      mode={InvoiceViewMode.Create}
      isSubmitting={isSubmitting}
    />
  )
}
InvoiceNewContainer.displayName = 'InvoiceNewContainer'
export default InvoiceNewContainer
