import React, { useContext } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import { InvoiceStatus } from 'components/Statuses'

import routes from 'config/routes'
import { InvoicesFilter } from 'types/app/filters'
import { formatTwoDecimals, tableSorter } from 'core/helpers'
import TopPage from 'components/TopPage'
import TableComponent from 'components/Table'
import { Button, Card, Tooltip } from 'antd'
import { EditFilled, PlusOutlined, EyeFilled } from '@ant-design/icons'
import FilterPanel from './FilterPanel'
import style from './styles.module.scss'
import { UserRole, InvoiceStatus as EnumInvoiceStatus } from 'types/enums'
import { userContext } from 'core'
import { InvoiceListData } from 'types/api/invoices'

interface Props {
  dataSource?: any
  initialFilter?: InvoicesFilter
  isLoading: boolean
  handleChange?: (pagination, filters, sorter) => void
  handleFiltering?: (filter: InvoicesFilter) => void
}

const InvoicesListTable: React.FC<Props> = ({
  dataSource,
  initialFilter,
  isLoading,
  handleFiltering,
  handleChange,
}) => {
  const { t } = useTranslation()
  const { role: userRole } = useContext(userContext)
  const actions = ['details', 'edit', 'delete']

  const actionsPerInvoice = (invoice: InvoiceListData) => {
    switch (invoice.status) {
      case EnumInvoiceStatus.Created:
        return actions
      case EnumInvoiceStatus.Paid:
        return ['details', 'delete']
      case EnumInvoiceStatus.Deleted:
        return ['details']
    }
  }

  const columns = [
    {
      key: 'number',
      title: t('general.number'),
      dataIndex: 'nr',
      sorter: (a, b) => tableSorter(a.nr, b.nr),
    },
    {
      key: 'invoiceDate',
      title: t('invoice.invoiceDate'),
      dataIndex: 'invoiceDate',
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
      sorter: (a, b) => tableSorter(a.invoiceDate, b.invoiceDate),
    },
    {
      key: 'dueDate',
      title: t('invoice.dueDate'),
      dataIndex: 'dueDate',
      render: (date: Date) => {
        return date ? dayjs(date).format('DD.MM.YYYY') : ''
      },
      sorter: (a, b) => tableSorter(a.dueDate, b.dueDate),
    },
    {
      key: 'items.amount',
      title: t('general.amount'),
      dataIndex: 'totalAmount',
      render: (val) => formatTwoDecimals(val),
      sorter: (a, b) => tableSorter(a.totalAmount, b.totalAmount),
    },
    {
      key: 'net',
      title: t('general.net'),
      dataIndex: 'totalNet',
      render: (val) => formatTwoDecimals(val),
      sorter: (a, b) => tableSorter(a.totalNet, b.totalNet),
    },
    {
      key: 'status',
      title: t('general.status'),
      dataIndex: 'status',
      render: (status) => <InvoiceStatus value={status} />,
    },
    {
      key: 'items.registrationDate',
      title: t('general.registrationDate'),
      dataIndex: 'registrationDate',
      render: (date: Date) => {
        return date ? dayjs(date).format('DD.MM.YYYY') : ''
      },
      sorter: (a, b) => tableSorter(a.registrationDate, b.registrationDate),
    },
    {
      key: 'items.collectionDate',
      title: t('invoice.collectionDate'),
      dataIndex: 'collectionDate',
      render: (date: Date) => {
        return date ? dayjs(date).format('DD.MM.YYYY') : ''
      },
      sorter: (a, b) => tableSorter(a.paymentDate, b.paymentDate),
    },
    {
      key: 'recipient',
      title: t('user.userName'),
      dataIndex: 'recipient',
      hidden: userRole === UserRole.Affiliate,
      sorter: (a, b) => tableSorter(a.recipient, b.recipient),
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      hidden: userRole === UserRole.Affiliate || userRole === UserRole.Agent,
      render: (id, invoice) => (
        <>
          {actionsPerInvoice(invoice).map((action) => {
            switch (action) {
              case 'details':
                return (
                  <Link key={`action-details-${id}`} to={generatePath(routes.invoice.url, { id: id })}>
                    <Tooltip title={t('tooltip.details')}>
                      <EyeFilled title={t('tooltip.details')} />
                    </Tooltip>
                  </Link>
                )
              case 'edit':
                return (
                  <Link
                    key={`action-edit-${id}`}
                    to={generatePath(routes.invoice.url, { id: id }) + '?editmode=pay'}
                  >
                    <Tooltip title={t('tooltip.edit')}>
                      <EditFilled />
                    </Tooltip>
                  </Link>
                )
              // TBD - Farzad 19/07/2022 --- For now for invoices we don't have delete action
              // case 'delete':
              //   return (
              //     <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
              //       <DeleteFilled />
              //     </Tooltip>
              //   )

              default:
                return
            }
          })}
        </>
      ),
    },
  ].filter((item) => !item.hidden)

  const crumbs = () => [{ route: routes.home }, { route: routes.invoices }]
  return (
    <section className={style['invoices-table']}>
      <TopPage title={t('menu.invoices')} crumbs={crumbs()}>
        {userRole === UserRole.Affiliate && (
          <Button type="primary">
            <Link key="action-create" to={generatePath(routes.invoice.url, { id: 'new' })}>
              {t('btn.createInvoice')} <PlusOutlined />
            </Link>
          </Button>
        )}
      </TopPage>
      <section className="app-main-content">
        <FilterPanel initialFilter={initialFilter} onFilter={handleFiltering} />

        <Card className="mt-16">
          <TableComponent
            columns={columns}
            dataSource={dataSource?.data}
            onChange={handleChange}
            total={dataSource?.total}
            isLoading={isLoading}
          />
        </Card>
      </section>
    </section>
  )
}

export default InvoicesListTable
