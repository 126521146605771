import React from 'react'
import { useTranslation } from 'react-i18next'
import routes from 'config/routes'
import { Country } from 'types/app/admin'
import { generatePath, Link } from 'react-router-dom'

import TopPage from 'components/TopPage'

import { Button, Card, Col, Descriptions, Row, Spin } from 'antd'
import style from './styles.module.scss'
interface Props {
  country: Country
}

const CountryDetails: React.FC<Props> = ({ country }) => {
  const { t } = useTranslation()
  if (!country) {
    return <Spin size="large" className="pt-100" />
  }

  const crumbs = () => [
    { route: routes.home },
    { route: routes.countries },
    { route: routes.countryDetails, routeParam: { key: 'id', value: country.id } },
  ]

  return (
    <section className={style['country-details']}>
      <TopPage title={t('countries.countryDetail')} crumbs={crumbs()} />
      <section className="app-main-content">
        <section className="details">
          <Card>
            <Row justify="end">
              <Col>
                <Button size="large" type="default">
                  <Link
                    key={`action-edit-${country.id}`}
                    to={
                      generatePath(routes.countryDetails.url, { id: country.id.toString() }) +
                      '?editmode=details'
                    }
                  >
                    {t('btn.edit')}
                  </Link>
                </Button>
              </Col>
            </Row>
            <Descriptions column={1} bordered>
              <Descriptions.Item label={t('general.name')}>{country.name}</Descriptions.Item>
              <Descriptions.Item label={t('countries.iva')}>{country.vat}</Descriptions.Item>
              <Descriptions.Item label={t('countries.iso')}>{country.iso}</Descriptions.Item>
            </Descriptions>
          </Card>
        </section>
      </section>
    </section>
  )
}

CountryDetails.displayName = 'CountryDetails'

export default CountryDetails
