import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Api from 'types/api/widgets'

import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import { Card, Skeleton as AntSkeleton, Typography } from 'antd'
import { useTheme } from 'providers/ThemeProvider'
import { ThemeType } from 'types/enums'

interface Props {
  data: Api.CurrentBalanceWidgetData
  isLoading: boolean
}

const BalanceComponent: React.FC<Props> = ({ data, isLoading }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const [colors] = useState(theme === ThemeType.Epayrex ? ['#ff4081', '#3f51b5'] : ['#fc0', '#18192c'])
  const [text] = useState(theme === ThemeType.Epayrex ? '#000' : '#fff')
  const RADIAN = Math.PI / 180

  const renderActiveShape = (props) => {
    const { cx, cy, midAngle, outerRadius, fill, payload, value } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 30) * cos
    const my = cy + (outerRadius + 30) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'

    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={-3}
          textAnchor={textAnchor}
          fill={fill}
          fontSize={14}
        >
          {t(`general.${payload.name}`)}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={23}
          textAnchor={textAnchor}
          fill={fill}
          fontSize={16}
        >
          {`${value} ${t('general.currencySign')}`}
        </text>
      </g>
    )
  }

  const getData = () => [
    { name: 'creditAllowed', value: data.creditAllowed },
    { name: 'creditConsumed', value: data.creditConsumed },
  ]

  const getContent = () => {
    if (isLoading) return <AntSkeleton active paragraph={{ rows: 8 }} />

    return (
      <PieChart width={300} height={300}>
        <text x="50%" y="50%" dy={-10} textAnchor="middle" fill={text} fontSize={14}>
          {t('charts.currentBalance')}
        </text>
        <text x="50%" y="50%" dy={16} textAnchor="middle" fill={text} fontSize={24}>
          {`${data.availableBalance} ${t('general.currencySign')}`}
        </text>
        <Pie
          cx="50%"
          cy="50%"
          dataKey="value"
          innerRadius={100}
          outerRadius={130}
          paddingAngle={3}
          data={getData()}
          label={renderActiveShape}
        >
          {getData().map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index]} />
          ))}
        </Pie>
      </PieChart>
    )
  }

  return (
    <Card size="small">
      <Typography.Title level={3} className="m-0">
        {t('charts.balanceTitle')}
      </Typography.Title>
      <Typography.Paragraph>{t('charts.balanceSubtitle')}</Typography.Paragraph>
      <ResponsiveContainer width="100%" height={300}>
        {getContent()}
      </ResponsiveContainer>
    </Card>
  )
}

BalanceComponent.displayName = 'BalanceComponent'

export default BalanceComponent
