import React from 'react'
import TopPage from 'components/TopPage'
import DashboardContainer from 'containers/Dashboard'

const HomePage: React.FC = () => {
  return (
    <section>
      <TopPage title={null} crumbs={null} />
      <DashboardContainer />
    </section>
  )
}

export default HomePage
