import Debug from 'debug'
import { getApplicationError } from 'core'
import * as Api from 'types/api/transanctions'
import { ErrorCode } from 'types/enums'
import { mapDate, mapNumber, mapString } from './common'
import { Transaction } from 'types/app/transactions'

const debug = Debug('services:map:transactions')

export const mapTransaction = (data: Api.TransactionListData): Transaction => {
  try {
    return {
      id: mapNumber(data.id),
      idType: mapNumber(data.idType),
      idSender: mapString(data.idSender),
      idRecipient: mapString(data.idRecipient),
      idUpperLevel: mapString(data.idUpperLevel),
      registrationDate: mapDate(data.registrationDate),
      description: mapString(data.description),
      amount: mapNumber(data.amount),
      previousBalance: mapNumber(data.previousBalance),
      currentBalance: mapNumber(data.currentBalance),
      instanceId: mapNumber(data.instanceId),
      senderName: mapString(data.senderName),
      recipientname: mapString(data.recipientname),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapTransactionsList = (data: Api.TransactionListData[]): Transaction[] => {
  try {
    return data.map((item) => mapTransaction(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
