import { config } from 'core'
import { AxiosError } from 'axios'
import { getAxios } from './apiService'
import * as Api from 'types/api/widgets'
import { getApiError, getAuthenticationHeader } from './common'

const axios = getAxios()

export async function getCurrentBalanceWidget(
  queryParams: Api.GetWidgetRequest,
): Promise<Api.CurrentBalanceWidgetData> {
  try {
    const response = await axios.post(
      config.api.currentBalanceWidget(),
      queryParams,
      await getAuthenticationHeader(),
    )
    return response.data as Api.CurrentBalanceWidgetData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getMonthlyChargesWidget(
  queryParams: Api.GetWidgetRequest,
): Promise<Api.TotalChargesByPeriodWidgetData[]> {
  try {
    const response = await axios.post(
      config.api.monthlyChargesWidget(),
      queryParams,
      await getAuthenticationHeader(),
    )
    return response.data as Api.TotalChargesByPeriodWidgetData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getYearlyChargesWidget(
  queryParams: Api.GetWidgetRequest,
): Promise<Api.TotalChargesByPeriodWidgetData[]> {
  try {
    const response = await axios.post(
      config.api.yearlyChargesWidget(),
      queryParams,
      await getAuthenticationHeader(),
    )
    return response.data as Api.TotalChargesByPeriodWidgetData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getClientServedWidget(
  queryParams: Api.GetWidgetRequest,
): Promise<Api.ClientServedWidgetData> {
  try {
    const response = await axios.post(
      config.api.clientServedWidegt(),
      queryParams,
      await getAuthenticationHeader(),
    )
    return response.data as Api.ClientServedWidgetData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
