import { Dropdown, Menu } from 'antd'
import { acronymify } from 'core/helpers'
import React, { useState } from 'react'
import { Instance } from 'types/app/admin'

interface Props {
  initialInstance: Instance
  instances: Instance[]
  onChanged: (picked: Instance) => void
}

const SuperAdminInstancePicker: React.FC<Props> = ({ initialInstance, instances, onChanged }) => {
  const [selectedInstance, setSelectedInstance] = useState(initialInstance)
  const onChange = (e) => {
    const selected = instances.find((i) => i.id.toString() === e.key)
    if (selected) {
      onChanged(selected)
      setSelectedInstance(selected)
    }
  }

  const menu = (
    <Menu style={{ width: '300px' }} defaultValue={selectedInstance.id} onClick={onChange}>
      {instances.map(({ id, name }) => (
        <Menu.Item key={id}>{name}</Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <span className="ant-dropdown-link" title={selectedInstance.name}>
        {acronymify(selectedInstance.name, 4).toUpperCase()}
      </span>
    </Dropdown>
  )
}

SuperAdminInstancePicker.displayName = 'SuperAdminInstancePicker'

export default SuperAdminInstancePicker
