import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import routes from 'config/routes'
import { Customer } from 'types/app/admin'
import { InstanceFilter } from 'types/app/filters'
import { tableSorter } from 'core/helpers'
import TopPage from 'components/TopPage'
import FilterPanel from './FilterPanel'
import TableComponent from 'components/Table'
import { DeleteFilled, EditFilled, EyeFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Modal, notification, Space, Tooltip, Typography } from 'antd'
import style from './styles.module.scss'
interface Props {
  dataSource: any
  customers: Customer[]
  initialFilter: InstanceFilter
  isLoading: boolean
  handleChange: (pagination, filters, sorter) => void
  handleFiltering: (filter: InstanceFilter) => void
  handleDelete: (id: string) => void
}

const InstancesTable: React.FC<Props> = ({
  dataSource,
  initialFilter,
  handleFiltering,
  handleChange,
  handleDelete,
  customers,
  isLoading,
}) => {
  const { t } = useTranslation()
  const actions = ['details', 'edit', 'delete']

  const onDelete = (deleteId: string) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        try {
          handleDelete(deleteId)
          notification.success({ message: t('messagesCrud.instanceDeleted') })
        } catch (error) {
          console.error(error)
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: 100,
      fixed: true,
      sorter: (a, b) => tableSorter(a.id, b.id),
    },
    {
      key: 'name',
      title: t('general.name'),
      dataIndex: 'name',
      sorter: (a, b) => tableSorter(a.name, b.name),
    },
    {
      key: 'apiUrl',
      title: t('instance.apiUrl'),
      dataIndex: 'apiUrl',
      sorter: (a, b) => tableSorter(a.apiUrl, b.apiUrl),
    },
    {
      key: 'registrationDate',
      title: t('general.registrationDate'),
      dataIndex: 'registrationDate',
      // eslint-disable-next-line react/display-name
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
    },
    {
      key: 'customer',
      title: t('general.customer'),
      dataIndex: 'customer',
      sorter: (a, b) => tableSorter(a.customer, b.customer),
    },
    {
      key: 'language',
      title: t('general.language'),
      dataIndex: 'language',
      sorter: (a, b) => tableSorter(a.language, b.language),
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      className: 'cell-actions',
      // eslint-disable-next-line react/display-name
      render: (id) => (
        <Space>
          {actions.map((action) => {
            switch (action) {
              case 'edit':
                return (
                  <Link
                    key={`action-edit-${id}`}
                    to={generatePath(routes.instanceDetails.url, { id: id }) + '?editmode=details'}
                  >
                    <Tooltip title={t('tooltip.edit')}>
                      <EditFilled />
                    </Tooltip>
                  </Link>
                )
              case 'delete':
                return (
                  <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
                    <DeleteFilled onClick={() => onDelete(id)} />
                  </Tooltip>
                )
              case 'details':
                return (
                  <Link
                    key={`action-details-${id}`}
                    to={generatePath(routes.instanceDetails.url, { id: id })}
                  >
                    <Tooltip title={t('tooltip.details')}>
                      <EyeFilled />
                    </Tooltip>
                  </Link>
                )
              default:
                return
            }
          })}
        </Space>
      ),
    },
  ]

  const crumbs = () => [{ route: routes.home }, { route: routes.instanceDetails }]
  return (
    <section className={style['instances-table']}>
      <TopPage title={t('instance.detailInstance')} crumbs={crumbs()}>
        <Button type="primary">
          <Link
            key="action-create"
            to={generatePath(routes.instanceDetails.url, { id: 'new' }) + '?editmode=details'}
          >
            {t('btn.createInstance')} <PlusOutlined />
          </Link>
        </Button>
      </TopPage>
      <section className="app-main-content">
        <FilterPanel initialFilter={initialFilter} onFilter={handleFiltering} customers={customers} />

        <Card className="mt-16">
          <TableComponent
            columns={columns}
            dataSource={dataSource}
            isLoading={isLoading}
            onChange={handleChange}
          />
        </Card>
      </section>
    </section>
  )
}

export default InstancesTable
