export const mapBoolean = (value: boolean | null): boolean => {
  return value
}

export const mapDate = (value: string | null): Date => {
  const timestamp = Date.parse(value)
  if (isNaN(timestamp)) {
    return null
  }
  return new Date(timestamp)
}

export const mapString = (value: string | null): string => {
  if (!value) {
    return ''
  }
  return value
}

export const mapNumber = (value: number | null): number => {
  if (!value) {
    return 0
  }
  return Number(value)
}

export const mapFromNumber = (value: number | null): number => {
  if (!value) {
    return 0
  }
  return Number(value)
}
