import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Enums from 'types/enums'

interface Props {
  value: Enums.SmsAnswerStatus
}
const SmsAnswerStatus: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation()
  switch (value) {
    case Enums.SmsAnswerStatus.NotSet:
      return <Tag color="warning">{t('status.notset')}</Tag>
    case Enums.SmsAnswerStatus.SmsSuccess:
      return <Tag color="success">{t('status.smsSuccess')}</Tag>
    case Enums.SmsAnswerStatus.SmsError:
      return <Tag color="error">{t('status.smsError')}</Tag>
    default:
      return null
  }
}

export default SmsAnswerStatus
