import { config } from 'core'
import { AxiosError } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'

import * as Api from 'types/api/transanctions'
import { ApiSearchResponse } from 'types/api/common'

const axios = getAxios()

export async function searchTransactions(
  queryParams: Api.SearchTransactionsRequest,
): Promise<ApiSearchResponse<Api.TransactionListData>> {
  try {
    const response = await axios.post(
      config.api.searchTransactions(),
      queryParams || {},
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
