import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import routes from 'config/routes'

import { InstanceDetails } from 'types/app/admin'
import FloatingLabel from 'components/FloatingLabel'

import { Button, Col, Form, Input, Row, Space, Spin } from 'antd'
import style from './styles.module.scss'

interface Props {
  instance: InstanceDetails
  onSave: (values: Json) => void
  isFetching: boolean
}

const ChangeCredits: React.FC<Props> = ({ instance, onSave, isFetching }) => {
  const { t } = useTranslation()
  const [formData, setFormData] = React.useState(instance)

  const onFinish = (values: Json) => {
    onSave(values)
  }

  const initialValues = { ...instance, creditGranted: instance.creditGranted || '' }

  if (isFetching)
    return (
      <Row align="middle" justify="center" style={{ height: '200px' }}>
        <Spin size="large" />
      </Row>
    )

  return (
    <section className={style['change-details']}>
      <Form name="changeInstanceDetails" initialValues={initialValues} onFinish={onFinish}>
        <Row key={instance.id}>
          <Col span={12}>
            <FloatingLabel value={formData.creditGranted || ''}>
              <Form.Item
                label={t('instance.creditGranted')}
                name="creditGranted"
                requiredMark="optional"
                rules={[{ required: true, whitespace: true, message: t('validationForm.fieldRequired') }]}
                normalize={(value) => value.replace(/\D/g, '')}
              >
                <Input
                  type="text"
                  onChange={(e) => setFormData({ ...formData, creditGranted: Number(e.target.value) })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>

          {/* Comment them ut for the time being, they may be reintroduced at a later stage

          <Col span={12}>
            <Form.Item
              label={t('instance.dailyLimit')}
              name="dailyLimit"
              requiredMark="optional"
              rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
            >
              <Input type="number" placeholder={instance.dailyLimit.toString()} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t('instance.monthlyLimit')}
              name="monthlyLimit"
              requiredMark="optional"
              rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
            >
              <Input type="number" placeholder={instance.monthlyLimit.toString()} />
            </Form.Item>
          </Col> */}
        </Row>

        <Col span={24} className="align-right">
          <Form.Item className="btn mt-50" wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                <Link to={routes.instances.url}>{t('btn.cancel')}</Link>
              </Button>
              <Button type="default" htmlType="submit">
                {t('btn.save')}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Form>
    </section>
  )
}

ChangeCredits.displayName = 'ChangeCredits'

export default ChangeCredits
