import { useQuery } from 'react-query'
import * as queryKeys from 'config/queryKeys'

import * as Api from 'types/api/widgets'
import {
  getClientServedWidget,
  getCurrentBalanceWidget,
  getMonthlyChargesWidget,
  getYearlyChargesWidget,
} from 'api/widgets'

export function useGetCurrentBalanceWidget(queryParams: Api.GetWidgetRequest) {
  const queryFunction = async () => {
    const response = await getCurrentBalanceWidget(queryParams)
    return response
  }
  return useQuery(queryKeys.currentBalanceWidget(queryParams), queryFunction, {
    enabled: !!queryParams.instanceId,
  })
}

export function useGetMonthlyChargesWidget(queryParams: Api.GetWidgetRequest) {
  const queryFunction = async () => {
    const response = await getMonthlyChargesWidget(queryParams)
    return response
  }
  return useQuery(queryKeys.monthlyChargesWidget(queryParams), queryFunction, {
    enabled: !!queryParams.instanceId,
  })
}

export function useGetYearlyChargesWidget(queryParams: Api.GetWidgetRequest) {
  const queryFunction = async () => {
    const response = await getYearlyChargesWidget(queryParams)
    return response
  }
  return useQuery(queryKeys.yearlyChargesWidget(queryParams), queryFunction, {
    enabled: !!queryParams.instanceId,
  })
}

export function useGetClientServedWidget(queryParams: Api.GetWidgetRequest) {
  const queryFunction = async () => {
    const response = await getClientServedWidget(queryParams)
    return response
  }
  return useQuery(queryKeys.clientServedWidget(queryParams), queryFunction, {
    enabled: !!queryParams.instanceId,
  })
}
