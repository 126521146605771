import React from 'react'
import { Button, Card, Col, Descriptions, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import { Currency } from 'types/app/admin'
import TopPage from 'components/TopPage'
import style from './styles.module.scss'
import routes from 'config/routes'
import { generatePath, Link } from 'react-router-dom'
interface Props {
  item: Currency
}

const CurrencyDetails: React.FC<Props> = ({ item }) => {
  const crumbs = () => [
    { route: routes.home },
    { route: routes.currencies },
    { route: routes.currencyDetails, routeParam: { key: 'id', value: item.id } },
  ]

  const { t } = useTranslation()
  return (
    <section className={style['currency-details']}>
      <TopPage title={t('currency.currencyDetails')} crumbs={crumbs()} />
      <section className="app-main-content">
        <section className="details">
          <Card>
            <Row justify="end">
              <Col>
                <Button size="large" type="default">
                  <Link
                    key={`action-edit-${item.id}`}
                    to={generatePath(routes.currencyDetails.url, { id: item.id }) + '?editmode=details'}
                  >
                    {t('btn.edit')}
                  </Link>
                </Button>
              </Col>
            </Row>

            <Row key={item.id}>
              <Col span={16}>
                <Descriptions column={1} bordered>
                  <Descriptions.Item label={t('general.name')}>{item.name}</Descriptions.Item>
                  <Descriptions.Item label={t('general.code')}>{item.code}</Descriptions.Item>
                  <Descriptions.Item label={t('general.symbol')}>{item.symbol}</Descriptions.Item>
                  <Descriptions.Item label={t('currency.decimalSep')}>
                    {item.decimalSeparator}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('currency.thousandSep')}>
                    {item.thousandSeparator}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Card>
        </section>
      </section>
    </section>
  )
}

CurrencyDetails.displayName = 'CurrencyDetails'

export default CurrencyDetails
