import React from 'react'
import { Button, Card, Col, Descriptions, Row, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import TopPage from 'components/TopPage'
import style from './styles.module.scss'
import routes from 'config/routes'
import { CustomerDetails } from 'types/app/admin'
import { generatePath, Link, useParams } from 'react-router-dom'

interface Props {
  customer: CustomerDetails
}

const InstanceDetailsComponent: React.FC<Props> = ({ customer }) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const crumbs = () => [
    { route: routes.home },
    { route: routes.customers },
    { route: routes.customerDetails, routeParam: { key: 'id', value: customer.id } },
  ]
  return (
    <section className={style['customer-details']}>
      <TopPage title={t('menu.customerDetails')} crumbs={crumbs()} />
      <section className="app-main-content">
        <section className="details">
          <Card>
            <Row justify="end">
              <Col>
                <Button size="large" type="default">
                  <Link
                    key={`action-edit-${id}`}
                    to={generatePath(routes.customerDetails.url, { id: id }) + '?editmode=details'}
                  >
                    {t('btn.edit')}
                  </Link>
                </Button>
              </Col>
            </Row>
            <Row key={customer.id}>
              <Col span={16}>
                <Descriptions column={1} bordered>
                  <Descriptions.Item label={t('general.businessName')}>
                    {customer.businessName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('general.city')}>{customer.town}</Descriptions.Item>
                  <Descriptions.Item label={t('general.email')}>{customer.email}</Descriptions.Item>
                  <Descriptions.Item label={t('general.vatNumber')}>{customer.vatNumber}</Descriptions.Item>
                  <Descriptions.Item label={t('general.mobilePhone')}>
                    {customer.mobilePhone}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('general.country')}>{customer.country}</Descriptions.Item>
                  <Descriptions.Item label={t('general.address')}>{customer.address}</Descriptions.Item>
                  <Descriptions.Item label={t('general.phone')}>{customer.phone}</Descriptions.Item>
                  <Descriptions.Item label={t('general.fiscalCode')}>{customer.fiscalCode}</Descriptions.Item>
                  <Descriptions.Item label={t('general.active')}>
                    {customer.isActive ? (
                      <Tag color="green"> {t('user.active')}</Tag>
                    ) : (
                      <Tag color="red">{t('user.blocked')}</Tag>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
          </Card>
        </section>
      </section>
    </section>
  )
}

InstanceDetailsComponent.displayName = 'InstanceDetails'

export default InstanceDetailsComponent
