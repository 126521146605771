import React, { useContext, useState } from 'react'
import ChargesTable from 'components/charges/ChargesTable'

import { useSearchCharges, useDeleteCharge, useInvalidateCharge } from 'services/query/charges'
import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE, userContext } from 'core'
import { SearchChargesRequest } from 'types/api/charges'
import { ChargesFilter } from 'types/app/filters'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'
import { UserRole } from 'types/enums'
import { useSearchParams } from 'react-router-dom'

const ChargesTableContainer: React.FC = () => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(TABLE_PAGINATION_DEFAULT_PAGE_SIZE)
  const [sorterField, setSorterField] = useState('registrationDate')
  const [sorterDirection, setSorterDirection] = useState('descend')
  const userSettings = useSelector(selectUserSettings)
  const { role: userRole, instanceId: currentUserInstanceId } = useContext(userContext)
  const [searchParams] = useSearchParams()
  const [number, setNumber] = useState(searchParams.get('number'))
  const deleteCharge = useDeleteCharge()
  const invalidateCharge = useInvalidateCharge()

  const initialFilter: ChargesFilter = {
    dateFrom: dayjs().subtract(1, 'months').toDate(),
    dateTo: dayjs().toDate(),
    code: '',
    mobilePhone: number ? number : '',
    guid: '',
    status: -1,
    smsAnswerStatus: null,
    invoiceStatus: -1,
    invoiceId: null,
    amountMin: null,
    amountMax: null,
  }
  const [filter, setFilter] = useState<ChargesFilter>(initialFilter)

  const searchInstanceId = userRole === UserRole.SuperAdmin ? userSettings?.selectedInstance?.id || null : -1
  const queryParams: SearchChargesRequest = {
    page,
    pageSize,
    instanceId: searchInstanceId,
    dateFrom: filter.dateFrom.toISOString(),
    dateTo: filter.dateTo.toISOString(),
    code: filter.code,
    mobilePhone: filter.mobilePhone,
    guid: filter.guid,
    status: filter.status || null,
    smsAnswerStatus: filter.smsAnswerStatus || null,
    invoiceStatus: filter.invoiceStatus,
    invoiceId: filter.invoiceId || null,
    amountMin: filter.amountMin,
    amountMax: filter.amountMax,
    orderBy: sorterField || 'registrationDate',
    orderByDirection: sorterDirection === 'descend' ? -1 : 1,
  }

  const { data: charges, isFetched, isLoading, refetch } = useSearchCharges(queryParams)

  const dataLoading: boolean = isLoading || !isFetched

  const handleChange = (pagination, filters, sorter) => {
    if (pagination && pagination.current) {
      setPage(pagination.current - 1)
      setPageSize(pagination.pageSize)
    }

    setSorterField(sorter.field)

    if (sorter.hasOwnProperty('column')) {
      setSorterField(sorter.field)
      setSorterDirection(sorter.order)
      setSorterDirection('descend')
    }
  }

  const handleFiltering = (filter: ChargesFilter) => {
    //NB reset pages and sorting
    setPage(0)
    setSorterField('registrationDate')
    setSorterDirection('descend')
    setFilter(filter)
  }

  const handleDelete = async (chargeId: number) => {
    await deleteCharge(chargeId)
    await refetch()
  }

  const handleInvalidate = async (chargeId: number) => {
    if (userRole === UserRole.SuperAdmin) {
      await invalidateCharge({
        chargeId,
        instanceId: userSettings?.selectedInstance?.id || currentUserInstanceId,
      })
    } else {
      await invalidateCharge({ chargeId })
    }
    await refetch()
  }

  return (
    <ChargesTable
      initialFilter={initialFilter}
      data={charges.data}
      total={charges.total}
      isLoading={dataLoading}
      handleChange={handleChange}
      handleFiltering={handleFiltering}
      handleDelete={handleDelete}
      handleInvalidate={handleInvalidate}
      setNumber={setNumber}
    />
  )
}

ChargesTableContainer.displayName = 'ChargesTableContainer'

export default ChargesTableContainer
