import React from 'react'
import { useParams } from 'react-router-dom'
import MessageDetailsContainer from 'containers/messages/MessageDetails'
import { useLoadMessage } from 'services/query/messages'

const MessagesPage: React.FC = () => {
  const { id } = useParams()
  const { data: message } = useLoadMessage(parseInt(id))

  return <MessageDetailsContainer message={message} />
}

export default MessagesPage
