import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'

import routes from 'config/routes'
import { CustomerFilter } from 'types/app/filters'
import { tableSorter } from 'core/helpers'
import FilterPanel from './FilterPanel'
import TopPage from 'components/TopPage'
import TableComponent from 'components/Table'
import { Button, Card, Modal, Space, Tag, Tooltip, Typography } from 'antd'
import { DeleteFilled, EditFilled, EyeFilled, PlusOutlined } from '@ant-design/icons'
import { Country } from 'types/app/admin'
import style from './styles.module.scss'

interface Props {
  dataSource: any
  countries: Country[]
  initialFilter: CustomerFilter
  isLoading: boolean
  handleChange: (pagination, filters, sorter) => void
  handleFiltering: (filter: CustomerFilter) => void
  handleDelete: (id: string) => void
}

const CustomersTable: React.FC<Props> = ({
  dataSource,
  initialFilter,
  handleFiltering,
  handleChange,
  handleDelete,
  countries,
  isLoading,
}) => {
  const { t } = useTranslation()
  const actions = ['details', 'edit', 'delete']

  const onDelete = (deleteId: string) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        handleDelete(deleteId)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      width: 100,
      fixed: true,
      sorter: (a, b) => tableSorter(a.id, b.id),
    },
    {
      key: 'businessName',
      title: t('general.name'),
      dataIndex: 'businessName',
      sorter: (a, b) => tableSorter(a.businessName, b.businessName),
    },
    {
      key: 'email',
      title: t('general.email'),
      dataIndex: 'email',
      sorter: (a, b) => tableSorter(a.email, b.email),
    },
    {
      key: 'country',
      title: t('general.country'),
      dataIndex: 'country',
      sorter: (a, b) => tableSorter(a.country, b.country),
    },
    {
      key: 'isActive',
      title: t('general.active'),
      dataIndex: 'isActive',
      render: (isActive) =>
        isActive ? <Tag color="green"> {t('user.active')}</Tag> : <Tag color="red">{t('user.locked')}</Tag>,
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      className: 'cell-actions',
      // eslint-disable-next-line react/display-name
      render: (id) => (
        <Space>
          {actions.map((action) => {
            switch (action) {
              case 'details':
                return (
                  <Link
                    key={`action-details-${id}`}
                    to={generatePath(routes.customerDetails.url, { id: id })}
                  >
                    <Tooltip title={t('tooltip.details')}>
                      <EyeFilled />
                    </Tooltip>
                  </Link>
                )
              case 'edit':
                return (
                  <Link
                    key={`action-edit-${id}`}
                    to={generatePath(routes.customerDetails.url, { id: id }) + '?editmode=details'}
                  >
                    <Tooltip title={t('tooltip.edit')}>
                      <EditFilled />
                    </Tooltip>
                  </Link>
                )
              case 'delete':
                return (
                  <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
                    <DeleteFilled onClick={() => onDelete(id)} />
                  </Tooltip>
                )
              default:
                return
            }
          })}
        </Space>
      ),
    },
  ]

  const crumbs = () => [{ route: routes.home }, { route: routes.customerDetails }]
  return (
    <section className={style['customers-table']}>
      <TopPage title={t('menu.customers')} crumbs={crumbs()}>
        <Button type="primary">
          <Link
            key="action-create"
            to={generatePath(routes.customerDetails.url, { id: 'new' }) + '?editmode=details'}
          >
            {t('btn.createCustomer')} <PlusOutlined />
          </Link>
        </Button>
      </TopPage>
      <section className="app-main-content">
        <FilterPanel initialFilter={initialFilter} onFilter={handleFiltering} countries={countries} />

        <Card className="mt-16">
          <TableComponent
            columns={columns}
            dataSource={dataSource}
            isLoading={isLoading}
            onChange={handleChange}
          />
        </Card>
      </section>
    </section>
  )
}

export default CustomersTable
