import React from 'react'

import { Button, Card, Col, Form, Input, Row } from 'antd'

import { useTranslation } from 'react-i18next'

import style from './styles.module.scss'
import { Currency } from 'types/app/admin'
import TopPage from 'components/TopPage'
import routes from 'config/routes'
import { Link } from 'react-router-dom'
import FloatingLabel from 'components/FloatingLabel/FloatingLabel'

interface Props {
  item?: Currency
  onDetailsSaved: (values: Json) => void
}

const CurrencyEdit: React.FC<Props> = ({ item, onDetailsSaved }) => {
  const { t } = useTranslation()
  const [formData, setFormData] = React.useState(item)
  const crumbs = () => [
    { route: routes.home },
    { route: routes.currencies },
    { route: routes.currencyDetails, routeParam: { key: 'id', value: item ? item.id : 'new' } },
  ]

  const onFinish = (values: Json) => {
    onDetailsSaved(values)
  }

  return (
    <section className={style['currency-edit']}>
      <TopPage title={t('currency.currencyDetails')} crumbs={crumbs()} />
      <section className="app-main-content">
        <Card>
          <Form name="changeUserDetails" initialValues={{ ...item }} onFinish={onFinish}>
            <Row gutter={[24, 24]} key={item?.id}>
              <Col span={12}>
                <FloatingLabel value={formData?.name}>
                  <Form.Item
                    label={t('general.name')}
                    name="name"
                    requiredMark="optional"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input
                      type="text"
                      placeholder={item?.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col span={12}>
                <FloatingLabel value={formData?.decimalSeparator}>
                  <Form.Item
                    label={t('currency.decimalSep')}
                    name="decimalSeparator"
                    requiredMark="optional"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input
                      type="text"
                      placeholder={item?.decimalSeparator}
                      onChange={(e) => setFormData({ ...formData, decimalSeparator: e.target.value })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col span={12}>
                <FloatingLabel value={formData?.symbol}>
                  <Form.Item
                    label={t('general.symbol')}
                    name="symbol"
                    requiredMark="optional"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input
                      type="text"
                      placeholder={item?.symbol}
                      onChange={(e) => setFormData({ ...formData, symbol: e.target.value })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col span={12}>
                <FloatingLabel value={formData?.thousandSeparator}>
                  <Form.Item
                    label={t('currency.thousandSep')}
                    name="thousandSeparator"
                    requiredMark="optional"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input
                      type="text"
                      placeholder={item?.thousandSeparator}
                      onChange={(e) => setFormData({ ...formData, thousandSeparator: e.target.value })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col span={12}>
                <FloatingLabel value={formData?.code}>
                  <Form.Item
                    label={t('general.code')}
                    name="code"
                    requiredMark="optional"
                    rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                  >
                    <Input
                      type="text"
                      placeholder={item?.code}
                      onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
            </Row>
            <Form.Item className="btn" wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                <Link to={routes.currencies.url}>{t('btn.cancel')}</Link>
              </Button>
              <Button type="default" htmlType="submit">
                {t('btn.save')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </section>
    </section>
  )
}

CurrencyEdit.displayName = 'CurrencyEdit'

export default CurrencyEdit
