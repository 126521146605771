import React, { useState } from 'react'
import { Button, Col, Card, Row, Form, Input } from 'antd'
import { TranslationsFilter } from 'types/app/filters'
import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'

import FloatingLabel from 'components/FloatingLabel'
interface Props {
  initialFilter: TranslationsFilter
  onFilter: (filter: TranslationsFilter) => void
}

const FilterPanel: React.FC<Props> = ({ initialFilter, onFilter }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(initialFilter)
  const onResetFilter = () => {
    setFilter(initialFilter)
    onFilter(initialFilter)
  }
  return (
    <Card className={style['card-panel']}>
      <Form>
        <Row gutter={[12, 12]} align="middle">
          <Col xs={24} md={6}>
            <FloatingLabel value={filter.text}>
              <Form.Item label={t('general.key')}>
                <Input
                  className="i-panel"
                  onChange={(e) => setFilter({ ...filter, text: e.target.value })}
                  value={filter.text}
                  onPressEnter={() => onFilter(filter)}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col xs={24} lg={8}>
            <Row gutter={[12, 12]} align="middle">
              <Col span={12}>
                <Button type="primary" block onClick={onResetFilter}>
                  {t('btn.reset')}
                </Button>
              </Col>
              <Col span={12}>
                <Button type="default" block onClick={() => onFilter(filter)}>
                  {t('btn.search')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

FilterPanel.displayName = 'FilterPanel'

export default FilterPanel
