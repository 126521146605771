import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import routes from 'config/routes'
import { InstanceDetails, Country } from 'types/app/admin'
import { generatePath, Link, useParams } from 'react-router-dom'

import TopPage from 'components/TopPage'

import { Button, Card, Col, Descriptions, Row } from 'antd'
import style from './styles.module.scss'
interface Props {
  instance: InstanceDetails
  countries: Country[]
}

const InstanceDetailsComponent: React.FC<Props> = ({ instance, countries }) => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [countryName, setCountryName] = useState(null)

  useEffect(() => {
    countries.map((country) => {
      if (country.id === instance.countryId) {
        setCountryName(country.name)
      }
    })
  }, [countries])

  const crumbs = () => [
    { route: routes.home },
    { route: routes.instances },
    { route: routes.instanceDetails, routeParam: { key: 'id', value: instance.id } },
  ]
  return (
    <section className={style['instance-details']}>
      <TopPage title={t('menu.instanceDetails')} crumbs={crumbs()} />
      <section className="app-main-content">
        <section className="details">
          <Card>
            <Row justify="end">
              <Col>
                <Button size="large" type="default">
                  <Link
                    key={`action-edit-${id}`}
                    to={generatePath(routes.instanceDetails.url, { id: id }) + '?editmode=details'}
                  >
                    {t('btn.edit')}
                  </Link>
                </Button>
              </Col>
            </Row>
            <Row key={instance.id}>
              <Col span={16}>
                <Descriptions column={1} bordered>
                  <Descriptions.Item label={t('general.name')}>{instance.name}</Descriptions.Item>
                  <Descriptions.Item label={t('instance.apiUrl')}>{instance.apiUrl}</Descriptions.Item>
                  <Descriptions.Item label={t('instance.ipsWhitelist')}>
                    {instance.ipsWhitelist}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.cultureCode')}>
                    {instance.cultureCode}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.country')}>{countryName}</Descriptions.Item>
                  <Descriptions.Item label={t('instance.siteUrl')}>{instance.siteUrl}</Descriptions.Item>
                  <Descriptions.Item label={t('instance.apiKey')}>{instance.apiKey}</Descriptions.Item>
                  <Descriptions.Item label={t('instance.connectionString')}>
                    {instance.connectionString}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.smsAuthId')}>{instance.smsAuthId}</Descriptions.Item>
                  <Descriptions.Item label={t('instance.smsAuthToken')}>
                    {instance.smsAuthToken}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.smsNumber')}>{instance.smsNumber}</Descriptions.Item>
                  <Descriptions.Item label={t('instance.smsTemplate')}>
                    {instance.smsTemplate}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.chargeExpirationDays')}>
                    {instance.chargeExpirationDays > 0 ? (
                      <>
                        {instance.chargeExpirationDays} {t('general.days')}
                      </>
                    ) : (
                      <>{t('general.unlimited')}</>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.invoicePayTerm')}>
                    {instance.invoicePayTerm} {t('general.days')}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.smtpHost')}>{instance.smtpHost}</Descriptions.Item>
                  <Descriptions.Item label={t('instance.smtpUsername')}>
                    {instance.smtpUsername}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.smtpProtocol')}>
                    {instance.smtpProtocol}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.smtpPort')}>{instance.smtpPort}</Descriptions.Item>
                  <Descriptions.Item label={t('instance.smtpPassword')}>
                    {instance.smtpPassword}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.creditGranted')}>
                    {instance.creditGranted}
                  </Descriptions.Item>

                  {/* Comment them ut for the time being, they may be reintroduced at a later stage

                  <Descriptions.Item label={t('instance.dailyLimit')}>
                    {instance.dailyLimit}
                  </Descriptions.Item>
                  <Descriptions.Item label={t('instance.monthlyLimit')}>
                    {instance.monthlyLimit}
                  </Descriptions.Item> */}
                </Descriptions>
              </Col>
            </Row>
          </Card>
        </section>
      </section>
    </section>
  )
}

InstanceDetailsComponent.displayName = 'InstanceDetails'

export default InstanceDetailsComponent
