import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from './containers/App'
import { Provider } from 'react-redux'
import configureStore from 'store'
import ThemeProvider from 'providers/ThemeProvider'

import { ReactQueryDevtools } from 'react-query/devtools'

import './index.less'
import 'scss/index.scss'
import reportWebVitals from './reportWebVitals'
import { isAlive } from 'api/ping'
import { initLanguage } from 'core'
import ServerNotAvailablePage from 'pages/ServerNotAvailablePage'
import { BrowserRouter } from 'react-router-dom'
import { getLanguage } from 'core/localStore'

const settedLanguage = getLanguage()
const store = configureStore()
const labelSource = process.env.REACT_APP_LABELS_SOURCE
const locale = settedLanguage ? settedLanguage : process.env.REACT_APP_LOCALE

const reactQueryDevTools = localStorage.getItem('ReactQueryDevTools') === 'true'

const portal = process.env.REACT_APP_PORTAL

isAlive()
  .then(() => initLanguage(locale, labelSource))
  .then(() => {
    const queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: true,
          retry: false,
          // used when there are no more active instances of this query
          cacheTime: 5 * 60 * 1000,
        },
      },
    })

    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider initialTheme={portal}>
              <Suspense fallback="">
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </Suspense>
            </ThemeProvider>
            {reactQueryDevTools && <ReactQueryDevtools initialIsOpen={false} />}
          </QueryClientProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root'),
    )

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals()
  })
  .catch(() => {
    //API down, add a static error page
    ReactDOM.render(<ServerNotAvailablePage />, document.getElementById('root'))
  })
