import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import { generatePath, useNavigate } from 'react-router-dom'
import { Language } from 'types/app/admin'
import { useEditLanguage, useCreateLanguage } from 'services/query/admin'

import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import LanguageDetails from 'components/languages/LanguageDetails'
import LanguageDetailsNew from 'components/languages/LanguageDetailsNew'

interface Props {
  language: Language
}

const LanguageDetailsContainer: React.FC<Props> = ({ language }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const editLanguage = useEditLanguage()
  const createLanguage = useCreateLanguage()
  const [editMode, setEditMode] = useState(false)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.has('editMode')) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [])

  const onSaveLanguage = async (model: Language) => {
    try {
      await editLanguage({ ...language, ...model })
      navigate(generatePath(routes.languages.url), { replace: true })
      notification.success({ message: t('messagesCrud.languageUpdated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.saveError') })
    }
  }

  const onCreateLanguage = async (model: Language) => {
    try {
      await createLanguage({ ...language, ...model })
      navigate(generatePath(routes.languages.url), { replace: true })
      notification.success({ message: t('messagesCrud.languageCreated') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.saveError') })
    }
  }

  if (language) {
    return <LanguageDetails language={language} editMode={editMode} onSaveLanguage={onSaveLanguage} />
  }

  return <LanguageDetailsNew onCreateLanguage={onCreateLanguage} />
}

export default LanguageDetailsContainer
