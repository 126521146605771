import { apiProvider } from 'config/api'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
const platform = 'WEB'

/* eslint-disable no-useless-escape */
export const VALIDATE_PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,20}$/g
export const EMPTY_DATE = '0001-01-01T00:00:00.000Z'

export const TABLE_PAGINATION_DROPDOWN_VALUES = ['10', '20', '50', '100']
export const TABLE_PAGINATION_DEFAULT_PAGE_SIZE = 10

export const config = (function config() {
  const provider = apiProvider(apiBaseUrl)

  let language: string = null
  return {
    api: provider.api,
    getLanguage: () => language,
    getDateTimeFormat: () => 'DD/MM/YYYY',
    setLanguage: (lng: string) => {
      provider.setLanguage(lng)
      language = lng
    },
    apiBaseUrl,
    platform,
  }
})()

export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY
