import React, { useEffect, useState } from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import TopPage from 'components/TopPage'
import FloatingLabel from 'components/FloatingLabel/FloatingLabel'
import { Card, Row, Col, Button, Input, Form, Descriptions, Spin } from 'antd'
import style from './styles.module.scss'

import { Link } from 'react-router-dom'
import { Translation } from 'types/app/translations'

interface Props {
  onSaveTranslation?: (newTranslation, oldTag) => void
  translation: Translation
  languages: any
  spin: boolean
}

const TranslationDetails: React.FC<Props> = ({ onSaveTranslation, translation, languages, spin }) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const oldTag = translation.labelTag
  const [formData, setFormData] = useState({
    labelTag: translation.labelTag,
    defaultTranslation: translation.defaultTranslation,
    translations: translation.translations,
  })

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.has('editmode')) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [])
  const inputRefs = React.useRef({})

  const crumbs = () => [
    { route: routes.home },
    { route: routes.translations },
    {
      route: routes.translationDetails,
      routeParam: { key: 'id', value: translation.labelTag + '?editmode' },
    },
  ]
  const onFinish = () => {
    const translations = []
    languages.map((lan) => {
      translations.push({
        translation: inputRefs.current[lan.iso].input.value,
        languageId: lan.id,
        languageName: lan.name,
        languageIso: lan.iso,
      })
    })

    const newState = {
      ...formData,
      translations: translations,
    }

    onSaveTranslation(newState, oldTag)
  }

  const findTranslation = (lang) => {
    const translationFind = translation.translations.find((transl) => {
      return transl.languageId === lang.id
    })

    return translationFind
  }

  return (
    <section className={style['translation-details']}>
      <TopPage title={t('translations.translationsDetails')} crumbs={crumbs()}></TopPage>
      <section className="app-main-content">
        <Card>
          {spin && (
            <Row align="middle" justify="center" style={{ height: '200px' }}>
              <Spin size="large" />
            </Row>
          )}
          {!spin && (
            <>
              {editMode ? (
                <Form onFinish={onFinish}>
                  <Row gutter={[24, 24]}>
                    <Col xs={24} lg={8}>
                      <FloatingLabel value={translation.labelTag}>
                        <Form.Item label={t('general.key')}>
                          <Input
                            defaultValue={translation.labelTag}
                            onChange={(e) => setFormData({ ...formData, labelTag: e.target.value })}
                          />
                        </Form.Item>
                      </FloatingLabel>
                    </Col>
                    <Col xs={24} lg={24}>
                      {languages.map((lan) => {
                        return (
                          <FloatingLabel key={lan.id} value>
                            <Form.Item
                              label={lan.iso}
                              className="mt-30"
                              rules={[
                                { required: true, message: t('validationForm.fieldRequired') },
                                {
                                  pattern: /^[a-zA-Z_.]+$/,
                                  message: t('translations.validationTag'),
                                },
                              ]}
                            >
                              <Input
                                ref={(element) => (inputRefs.current[lan.iso] = element)}
                                defaultValue={findTranslation(lan) ? findTranslation(lan).translation : ''}
                              />
                            </Form.Item>
                          </FloatingLabel>
                        )
                      })}
                    </Col>
                  </Row>

                  <Row className="mt-12">
                    <Col xs={24} lg={{ span: 12, offset: 12 }}>
                      <Row justify="end" gutter={[12, 12]}>
                        <Col xs={12} lg={6}>
                          <Button block type="primary">
                            <Link to={routes.translations.url}>{t('btn.cancel')}</Link>
                          </Button>
                        </Col>
                        <Col xs={12} lg={6}>
                          <Button block htmlType="submit">
                            {t('btn.save')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              ) : (
                <>
                  <Row justify="end" className="mb-12">
                    <Col span={3}>
                      <Button block onClick={() => setEditMode(true)}>
                        {t('btn.edit')}
                      </Button>
                    </Col>
                  </Row>
                  <Descriptions column={1} bordered>
                    <Descriptions.Item label={t('general.key')}>{translation.labelTag}</Descriptions.Item>
                    {translation.translations.map((tr) => {
                      return (
                        <Descriptions.Item key={tr.languageIso} label={tr.languageName}>
                          <>
                            <img
                              style={{ width: '25px', marginRight: '15px' }}
                              src={`/images/flags/${tr.languageIso.toLowerCase()}.png`}
                              alt={tr.languageIso}
                            />
                            {tr.translation}
                          </>
                        </Descriptions.Item>
                      )
                    })}
                  </Descriptions>
                </>
              )}
            </>
          )}
        </Card>
      </section>
    </section>
  )
}
TranslationDetails.displayName = 'TranslationDetails'

export default TranslationDetails
