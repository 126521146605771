import { createContext } from 'react'
import { ThemeType } from 'themes/types'

interface Theme {
  theme: ThemeType
  setTheme: (type: ThemeType) => void
  getStyles: (themes: Json[]) => Json
}

export const ThemeContext = createContext<Theme>({
  theme: null,
  setTheme: null,
  getStyles: () => null,
})
