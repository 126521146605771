import { useMutation, useQuery, useQueryClient } from 'react-query'
import * as queryKeys from 'config/queryKeys'
import { createInvoice, getInvoiceDetails, payInvoice, previewInvoice, searchInvoices } from 'api/invoices'
import { mapInvoice, mapInvoiceList } from 'services/map/invoices'
import { SearchInvoicesRequest } from 'types/api/invoices'

export function usePreviewInvoice() {
  const queryFunction = async () => {
    const response = await previewInvoice()
    return mapInvoice(response)
  }

  return useQuery(queryKeys.previewInvoice(), queryFunction, {
    placeholderData: null,
    staleTime: 0,
  })
}

export function useGetInvoiceDetails(invoiceId: string, instanceId?: number) {
  const queryFunction = async () => {
    const response = await getInvoiceDetails(invoiceId, instanceId)
    return mapInvoice(response)
  }

  return useQuery(queryKeys.invoiceDetails(invoiceId, instanceId), queryFunction, {
    placeholderData: null,
    staleTime: 0,
  })
}

export function useCreateInvoice() {
  const mutateFunction = async () => {
    const response = await createInvoice()
    return mapInvoice(response)
  }

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (inserted) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.invoiceDetails(inserted.id.toString())),
        queryClient.invalidateQueries(queryKeys.invoiceList()),
      ])
    },
  })
  return mutateAsync
}

export function usePayInvoice(invoiceId: string, instanceId?: number) {
  const mutateFunction = async () => {
    const response = await payInvoice(invoiceId, instanceId)
    return mapInvoice(response)
  }

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (modified) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.invoiceDetails(modified.id.toString(), modified.instanceId)),
        queryClient.invalidateQueries(queryKeys.invoiceList()),
      ])
    },
  })
  return mutateAsync
}

export function useSearchInvoices(queryParams: SearchInvoicesRequest) {
  const queryFunction = async () => {
    const response = await searchInvoices(queryParams)
    return { data: mapInvoiceList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.invoicesSearch(queryParams), queryFunction, {
    placeholderData: { data: [], total: 0 },
    keepPreviousData: true,
    enabled: !!queryParams.instanceId,
  })
}
