import React from 'react'
import { Table } from 'antd'

import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE, TABLE_PAGINATION_DROPDOWN_VALUES } from 'core'

interface Props {
  tableRef?: any
  columns: any
  dataSource: any
  isLoading?: boolean
  onChange?: (pagination, filters, sorter) => void
  total?: number
  expandable?: any
  rowClassName?: any
}

const TableComponent: React.FC<Props> = ({
  tableRef,
  columns,
  dataSource,
  isLoading,
  total,
  onChange,
  expandable,
  rowClassName,
}) => {
  const dataLength = total || dataSource?.length
  const getTablePagination = () => {
    if (dataLength >= 10) {
      return {
        total: dataLength,
        defaultPageSize: TABLE_PAGINATION_DEFAULT_PAGE_SIZE,
        showSizeChanger: dataSource.length > 30,
        pageSizeOptions: TABLE_PAGINATION_DROPDOWN_VALUES,
      }
    }
    return false
  }

  return (
    <>
      <Table
        rowClassName={rowClassName}
        ref={tableRef}
        rowKey={(record) => record.id}
        scroll={{ x: 768 }}
        pagination={getTablePagination()}
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        onChange={onChange}
        expandable={expandable}
      />
    </>
  )
}

TableComponent.displayName = 'TableComponent'

export default TableComponent
