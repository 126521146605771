import React from 'react'

import { Button, Col, Form, Input, Row, Space, Spin } from 'antd'

import { useTranslation } from 'react-i18next'

import style from './styles.module.scss'

import { Link } from 'react-router-dom'
import routes from 'config/routes'
import { InstanceDetails } from 'types/app/admin'
import FloatingLabel from 'components/FloatingLabel'

interface Props {
  instance: InstanceDetails
  onSave: (values: Json) => void
  isFetching: boolean
}

const ChangeSmtp: React.FC<Props> = ({ instance, onSave, isFetching }) => {
  const { t } = useTranslation()

  const onFinish = (values: Json) => {
    onSave(values)
  }

  if (isFetching)
    return (
      <Row align="middle" justify="center" style={{ height: '200px' }}>
        <Spin size="large" />
      </Row>
    )

  return (
    <section className={style['change-smtp']}>
      <Form name="changeInstanceSmtp" initialValues={{ ...instance }} onFinish={onFinish}>
        <>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <FloatingLabel value={instance?.smtpHost}>
                <Form.Item label={t('instance.smtpHost')} name="smtpHost">
                  <Input type="text" placeholder={instance.smtpHost} />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={12}>
              <FloatingLabel value={instance?.smtpPort}>
                <Form.Item label={t('instance.smtpPort')} name="smtpPort">
                  <Input type="number" placeholder={instance.smtpPort.toString()} />
                </Form.Item>
              </FloatingLabel>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={12}>
              <FloatingLabel value={instance?.smtpUsername}>
                <Form.Item label={t('instance.smtpUsername')} name="smtpUsername">
                  <Input type="text" placeholder={instance.smtpUsername} />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={12}>
              <FloatingLabel value={instance?.smtpPassword}>
                <Form.Item label={t('instance.smtpPassword')} name="smtpPassword">
                  <Input type="text" placeholder={instance.smtpPassword} />
                </Form.Item>
              </FloatingLabel>
            </Col>
          </Row>

          <Row gutter={[24, 24]}>
            <Col span={12}>
              <FloatingLabel value={instance?.smtpProtocol}>
                <Form.Item label={t('instance.smtpProtocol')} name="smtpProtocol">
                  <Input type="text" maxLength={10} placeholder={instance.smtpProtocol} />
                </Form.Item>
              </FloatingLabel>
            </Col>
          </Row>
        </>

        <Col span={24} className="align-right">
          <Form.Item className="btn mt-50" wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                <Link to={routes.instances.url}>{t('btn.cancel')}</Link>
              </Button>
              <Button type="default" htmlType="submit">
                {t('btn.save')}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Form>
    </section>
  )
}

ChangeSmtp.displayName = 'ChangeSmtp'

export default ChangeSmtp
