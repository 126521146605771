import * as Api from 'types/api/translations'
import { config } from 'core'
import { getApiError, getAuthenticationHeader } from './common'
import { AxiosError, AxiosResponse } from 'axios'
import { ApiSearchResponse } from 'types/api/common'
import { getAxios } from './apiService'
const axios = getAxios()

export async function listTranslations(
  queryParams?: Api.ListTranslationsRequest,
): Promise<ApiSearchResponse<Api.TranslationListData>> {
  try {
    const response = await axios.post(
      config.api.searchTranslations(),
      queryParams || {},
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteTranslation(tag: string): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.translation(tag), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getTranslationDetails(tag: string): Promise<Api.TranslationListData> {
  try {
    const response = await axios.get(config.api.translation(tag), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
