import React from 'react'

import CountryDetailsContainer from 'containers/CountryDetails'

const CountryDetails: React.FC = () => {
  return <CountryDetailsContainer />
}

CountryDetails.displayName = 'CountryDetails'

export default CountryDetails
