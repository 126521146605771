import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Api from 'types/api/widgets'

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Card, Typography, Skeleton } from 'antd'
import { mapDate } from 'services/map/common'
import dayjs from 'dayjs'
import { useTheme } from 'providers/ThemeProvider'
import { ThemeType } from 'types/enums'

interface Props {
  data: Api.TotalChargesByPeriodWidgetData[]
  isLoading: boolean
}

const MonthlyChargesComponent: React.FC<Props> = ({ data, isLoading }) => {
  const { t } = useTranslation()

  const { theme } = useTheme()
  const [colors] = useState(theme === ThemeType.Epayrex ? '#8133FF' : '#fc0')
  const [text] = useState(theme === ThemeType.Epayrex ? '#ccc' : '#fff')

  const renderCustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
      return (
        <Card size="small">
          <Typography.Title level={5} className="m-0">{`${t('general.day')} ${label} `}</Typography.Title>
          <Typography.Paragraph className="m-0">
            {`${t('general.charge')}: ${payload[0]?.value}`}
          </Typography.Paragraph>
        </Card>
      )
    }
    return null
  }

  const getContent = () => {
    if (isLoading) return <Skeleton active paragraph={{ rows: 8 }} />

    const widgetData = data.map((x) => {
      return {
        day: dayjs(mapDate(x.periodDate)).format('DD MMM'),
        totalAmount: x.totalAmount,
      }
    })

    return (
      <BarChart data={widgetData} margin={{ bottom: 20 }}>
        <XAxis
          dataKey="day"
          label={{
            value: t('general.day'),
            position: 'insideBottom',
            offset: -15,
            fill: theme === ThemeType.Epayrex ? '#000' : '#fff',
          }}
        />
        <YAxis
          label={{
            value: t('general.charges'),
            angle: -90,
            position: 'insideLeft',
            offset: 10,
            fill: theme === ThemeType.Epayrex ? '#000' : '#fff',
          }}
        />

        {data.length > 0 ? (
          <>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip content={renderCustomTooltip} cursor={{ fill: 'transparent' }} />
            <Bar dataKey="totalAmount" fill={colors} barSize={12} />
          </>
        ) : (
          <g>
            <text x="50%" dx={-50} y="50%" dy={-30} fill={text} fontSize={24}>
              {t('charts.noData')}
            </text>
          </g>
        )}
      </BarChart>
    )
  }

  return (
    <Card size="small">
      <Typography.Title level={3} className="m-0">
        {t('charts.totalCharges')}
      </Typography.Title>
      <Typography.Paragraph>{t('charts.monthCharges')}</Typography.Paragraph>
      <ResponsiveContainer width="100%" height={300}>
        {getContent()}
      </ResponsiveContainer>
    </Card>
  )
}

MonthlyChargesComponent.displayName = 'MonthlyChargesComponent'

export default MonthlyChargesComponent
