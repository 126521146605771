import React from 'react'
import { useLoadUser, useUserNetwork } from 'services/query/users'
import { useParams } from 'react-router-dom'
import UserDetailsContainer from 'containers/UserDetails'
import Skeleton from 'components/Skeleton'

const UserDetails: React.FC = () => {
  const { id } = useParams()
  const userDetailsQuery = useLoadUser(id)
  const userNetworkQuery = useUserNetwork(id)
  const { data: user, isFetching } = userDetailsQuery
  const { data: network } = userNetworkQuery
  return <>{isFetching ? <Skeleton /> : <UserDetailsContainer user={user} network={network?.data} />}</>
}

UserDetails.displayName = 'UserDetails'

export default UserDetails
