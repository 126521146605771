import React, { useContext, useState } from 'react'

import { userContext } from 'core'

import { ReportFilter } from 'types/app/filters'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'
import { UserRole } from 'types/enums'
import CommissionsReportTable from 'components/reports/CommissionsReport'
import { CreateReportRequest } from 'types/api/reports'
import { useCreateCommissionsReport } from 'services/query/reports'

const CommissionsReportContainer: React.FC = () => {
  const userSettings = useSelector(selectUserSettings)
  const { role: userRole } = useContext(userContext)

  const initialFilter: ReportFilter = {
    dateFrom: dayjs().subtract(1, 'months').toDate(),
    dateTo: dayjs().toDate(),
    agentId: null,
  }

  const [filter, setFilter] = useState<ReportFilter>(initialFilter)

  const searchInstanceId = userRole === UserRole.SuperAdmin ? userSettings?.selectedInstance?.id || null : -1

  const queryParams: CreateReportRequest = {
    instanceId: searchInstanceId,
    dateFrom: filter?.dateFrom.toISOString(),
    dateTo: filter?.dateTo.toISOString(),
    agentId: filter?.agentId,
  }

  const { data: report, isFetched, isLoading } = useCreateCommissionsReport(queryParams)
  const dataLoading: boolean = isLoading || !isFetched

  const handleFiltering = (filter: ReportFilter) => {
    setFilter(filter)
  }

  return (
    <>
      <CommissionsReportTable
        initialFilter={initialFilter}
        dataSource={report}
        isLoading={dataLoading}
        handleFiltering={handleFiltering}
      />
    </>
  )
}

CommissionsReportContainer.displayName = 'CommissionsReportContainer'

export default CommissionsReportContainer
