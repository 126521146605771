import React from 'react'
import dayjs from 'dayjs'
import { generatePath, Link } from 'react-router-dom'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import { UserRole } from 'types/enums'
import { UserStatus } from 'components/Statuses'

import FilterPanel from './FilterPanel'
import TopPage from 'components/TopPage'
import TableComponent from 'components/Table'
import { Button, Checkbox, Card, Modal, Typography, notification, Tooltip } from 'antd'
import { DeleteFilled, EditFilled, EyeFilled, LockFilled, PlusOutlined } from '@ant-design/icons'
import style from './styles.module.scss'
import { UsersFilter } from 'types/app/filters'

interface Props {
  dataSource: any
  initialFilter: UsersFilter
  isLoading: boolean
  handleChange: (pagination, filters, sorter) => void
  handleFiltering: (filter: UsersFilter) => void
  handleDelete: (id: string) => void
}

const UsersTable: React.FC<Props> = ({
  dataSource,
  initialFilter,
  handleFiltering,
  handleChange,
  handleDelete,
  isLoading,
}) => {
  const { t } = useTranslation()
  const actions = ['details', 'edit', 'password', 'delete']

  const onDelete = (userId: string, role: string) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        try {
          if (role === UserRole.SuperAdmin) {
            notification.error({ message: t('messagesCrud.deleteSuperAdmin') })
          } else {
            handleDelete(userId)
            notification.success({ message: t('messagesCrud.userDeleted') })
          }
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns = [
    {
      key: 'name',
      title: t('user.name'),
      dataIndex: 'name',
      sorter: true,
      width: 100,
      fixed: true,
    },
    {
      key: 'surname',
      title: t('user.surname'),
      dataIndex: 'surname',
      sorter: true,
      width: 100,
      fixed: true,
    },
    {
      key: 'city',
      title: t('user.city'),
      dataIndex: 'city',
      sorter: true,
    },
    {
      key: 'email',
      title: t('user.email'),
      dataIndex: 'email',
      sorter: true,
    },
    {
      key: 'emailConfirmed',
      title: t('user.emailConfirmed'),
      dataIndex: 'emailConfirmed',
      sorter: true,
      // eslint-disable-next-line react/display-name
      render: (emailConfirm) => {
        if (emailConfirm) {
          return <Checkbox checked={true} disabled></Checkbox>
        } else {
          return <Checkbox disabled></Checkbox>
        }
      },
    },
    {
      key: 'role',
      title: t('user.role'),
      dataIndex: 'role',
      sorter: true,
    },
    {
      key: 'status',
      title: t('user.status'),
      dataIndex: 'status',
      sorter: true,
      // eslint-disable-next-line react/display-name
      render: (status) => <UserStatus value={status} />,
    },
    {
      key: 'instance',
      title: t('user.istance'),
      sorter: true,
      dataIndex: 'instance',
    },
    {
      key: 'country',
      title: t('user.country'),
      sorter: true,
      dataIndex: 'country',
    },
    {
      key: 'registrationDate',
      title: t('user.registrationDate'),
      dataIndex: 'registrationDate',
      sorter: true,
      // eslint-disable-next-line react/display-name
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
    },
    {
      key: 'updatedDate',
      title: t('user.updatedDate'),
      dataIndex: 'lastUpdate',
      sorter: true,
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      className: 'cell-actions',
      width: 80,
      // eslint-disable-next-line react/display-name
      render: (id, user) => (
        <>
          {actions.map((action) => {
            switch (action) {
              case 'edit':
                return (
                  <Link
                    key={`action-edit-${id}`}
                    to={generatePath(routes.userDetails.url, { id: id }) + '?editmode=details'}
                  >
                    <Tooltip title={t('tooltip.edit')}>
                      <EditFilled />
                    </Tooltip>
                  </Link>
                )
              case 'delete':
                return (
                  <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
                    <DeleteFilled onClick={() => onDelete(id, user.role)} />
                  </Tooltip>
                )
              case 'password':
                return (
                  <Link
                    key={`action-password-${id}`}
                    to={generatePath(routes.userDetails.url, { id: id }) + '?editmode=changePass'}
                  >
                    <Tooltip title={t('tooltip.pass')}>
                      <LockFilled />
                    </Tooltip>
                  </Link>
                )
              case 'details':
                return (
                  <Link key={`action-details-${id}`} to={generatePath(routes.userDetails.url, { id: id })}>
                    <Tooltip title={t('tooltip.details')}>
                      <EyeFilled title={t('tooltip.details')} />
                    </Tooltip>
                  </Link>
                )
              default:
                return
            }
          })}
        </>
      ),
    },
  ]

  const crumbs = () => [{ route: routes.home }, { route: routes.users }]

  return (
    <section className={style['users']}>
      <TopPage title={t('user.detailUser')} crumbs={crumbs()}>
        <Button type="primary">
          <Link
            key="action-create"
            to={generatePath(routes.userDetails.url, { id: 'new' }) + '?editmode=details'}
          >
            {t('user.newUser')} <PlusOutlined />
          </Link>
        </Button>
      </TopPage>
      <section className="app-main-content">
        <FilterPanel initialFilter={initialFilter} onFilter={handleFiltering} />

        <Card className="mt-16">
          <TableComponent
            columns={columns}
            dataSource={dataSource.data}
            onChange={handleChange}
            total={dataSource.total}
            isLoading={isLoading}
          />
        </Card>
      </section>
    </section>
  )
}
UsersTable.displayName = 'UsersTable'

export default UsersTable
