import * as queryKeys from 'config/queryKeys'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { mapChargeList, mapUserActiveBalance } from 'services//map/charges'
import { ChargeCreateRequest, SearchChargesRequest } from 'types/api/charges'
import {
  createCharge,
  deleteCharge,
  invalidateCharge,
  getCurrentUserActiveBalance,
  searchCharges,
} from 'api/charges'

export function useSearchCharges(queryParams: SearchChargesRequest) {
  const queryFunction = async () => {
    const response = await searchCharges(queryParams)
    return { data: mapChargeList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.chargesSearch(queryParams), queryFunction, {
    placeholderData: { data: [], total: 0 },
    keepPreviousData: true,
    enabled: !!queryParams.instanceId,
  })
}

export function useCreateCharge() {
  const mutateFunction = async (data: ChargeCreateRequest) => {
    const newItem = await createCharge(data)
    return newItem
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (inserted) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.chargeDetails(inserted.id.toString())),
        queryClient.invalidateQueries(queryKeys.chargesList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteCharge() {
  const deleteFunction = async (id: number) => {
    const result = await deleteCharge(id)
    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.chargesList())
    },
  })
  return mutateAsync
}

export function useInvalidateCharge() {
  const invalidateFunction = async (data: { chargeId: number; instanceId?: number }) => {
    await invalidateCharge(data.chargeId, data.instanceId)
    return data
  }

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(invalidateFunction, {
    onSuccess: (data) => {
      return queryClient.invalidateQueries(queryKeys.chargeDetails(data.chargeId.toString(), data.instanceId))
    },
  })
  return mutateAsync
}

export function useLoadCurrentUserActiveBalance() {
  const queryFunction = async () => {
    const response = await getCurrentUserActiveBalance()
    return mapUserActiveBalance(response)
  }

  return useQuery(queryKeys.currentUserActiveBalanceInfo(), queryFunction, {
    placeholderData: null,
    staleTime: 0,
  })
}
