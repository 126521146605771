import React from 'react'

import style from './styles.module.scss'
import UsersTableContainer from 'containers/users/UsersTable'

const UserPage: React.FC = () => {
  return (
    <section className={style['users-page']}>
      <UsersTableContainer />
    </section>
  )
}

UserPage.displayName = 'UserPage'

export default UserPage
