import React from 'react'

import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { User } from 'types/app/users'
import routes from 'config/routes'
import { UserRole } from 'types/enums'
import classNames from 'classnames'
import UserDetailsHeader from './UserDetailsHeader'
import TopPage from 'components/TopPage'

import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'

import { CheckOutlined, CloseOutlined, UserOutlined, DownOutlined } from '@ant-design/icons'
import { Col, Descriptions, Row, Tag, Typography, Tree, Space } from 'antd'

import style from './styles.module.scss'
import { generatePath, Link } from 'react-router-dom'
interface Props {
  user: User
  network: User[]
  handleDelete: (id: string) => void
}

const UserDetails: React.FC<Props> = ({ user, network, handleDelete }) => {
  const { t } = useTranslation()
  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const userDetailsPageClass = classNames(style['user-details'], themeStyles.theme)
  const crumbs = () => [
    { route: routes.home },
    { route: routes.users },
    { route: routes.userDetails, routeParam: { key: 'id', value: user.id } },
  ]

  const getTreeNodeTitle = (nodeItem) => {
    return (
      <Space>
        <UserOutlined />
        <Link to={generatePath(routes.userDetails.url, { id: nodeItem.id })}>
          <Typography.Text>
            {nodeItem.name} {nodeItem.surname}
          </Typography.Text>
        </Link>
        <Typography.Text> - </Typography.Text>
        <a href={`mailto:${nodeItem.email}`}>
          <Typography.Text>{nodeItem.email}</Typography.Text>
        </a>
      </Space>
    )
  }

  const getAdminTree = () => {
    if (network && network.find((n) => n.role === UserRole.Admin)) {
      return (
        <Tree.TreeNode key="admin" title={t('role.admin')}>
          {network.map((item) => {
            if (item.role === UserRole.Admin) {
              return (
                <Tree.TreeNode
                  title={getTreeNodeTitle(item)}
                  key={item.id === user.id ? 'current-user' : item.id}
                  className={item.id === user.id ? 'current-user' : ''}
                />
              )
            }
          })}
        </Tree.TreeNode>
      )
    }

    return null
  }

  const getOperationTree = () => {
    if (network && network.find((n) => n.role === UserRole.Operator)) {
      return (
        <Tree.TreeNode key="operation" title={t('role.operator')}>
          {network.map((item) => {
            if (item.role === UserRole.Operator) {
              return (
                <Tree.TreeNode
                  title={getTreeNodeTitle(item)}
                  key={item.id === user.id ? 'current-user' : item.id}
                  className={item.id === user.id ? 'current-user' : ''}
                />
              )
            }
          })}
        </Tree.TreeNode>
      )
    }

    return null
  }

  const getAgentTree = () => {
    if (network && network.find((n) => n.role === UserRole.Agent)) {
      return (
        <Tree.TreeNode key="agent" title={t('role.agent')}>
          {network.map((item) => {
            if (item.role === UserRole.Agent) {
              return (
                <Tree.TreeNode
                  title={getTreeNodeTitle(item)}
                  key={item.id === user.id ? 'current-user' : item.id}
                  className={item.id === user.id ? 'current-user' : ''}
                >
                  {network.map((child) => {
                    if (child.role === UserRole.Affiliate) {
                      return (
                        <Tree.TreeNode
                          title={getTreeNodeTitle(child)}
                          key={child.id === user.id ? 'current-user' : child.id}
                          className={child.id === user.id ? 'current-user' : ''}
                        />
                      )
                    }
                  })}
                </Tree.TreeNode>
              )
            }
          })}
        </Tree.TreeNode>
      )
    }

    return null
  }

  return (
    <section className={userDetailsPageClass}>
      <TopPage title={t('user.detailUser')} crumbs={crumbs()} />
      <section className="main">
        <UserDetailsHeader user={user} handleDelete={handleDelete} />
        <section className="details">
          <Row key={user.id} gutter={[24, 24]}>
            <Col span={24}>
              <Typography.Title level={3} className="m-0">
                {t('user.details')}
              </Typography.Title>
            </Col>
            <Col span={8}>
              <Descriptions column={1} bordered>
                <Descriptions.Item label={t('user.registrationDate')}>
                  {dayjs(user.registrationDate).format('DD.MM.YYYY')}
                </Descriptions.Item>
                <Descriptions.Item label={t('user.lastEdit')}>
                  {dayjs(user.lastUpdate).format('DD.MM.YYYY')}
                </Descriptions.Item>
                <Descriptions.Item label={t('user.verified')}>
                  {user.emailConfirmed ? (
                    <Tag color="success" icon={<CheckOutlined />}>
                      {t('yes')}
                    </Tag>
                  ) : (
                    <Tag color="error" icon={<CloseOutlined />}>
                      {t('no')}
                    </Tag>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={t('user.role')}>{user.role}</Descriptions.Item>
                {user.emailUpperLevel && (
                  <Descriptions.Item label={t('user.upperLevel')}>{user.emailUpperLevel}</Descriptions.Item>
                )}
                <Descriptions.Item label={t('user.commission')}>{user.commission}</Descriptions.Item>
                {user.account && (
                  <>
                    <Descriptions.Item label={t('user.availableBalance')}>
                      {user.account?.availableBalance}
                    </Descriptions.Item>
                    <Descriptions.Item label={t('user.creditLimit')}>
                      {user.account?.creditLimit}
                    </Descriptions.Item>
                  </>
                )}
                <Descriptions.Item label={t('user.status')}>
                  {user.locked ? (
                    <Tag color="error" icon={<CloseOutlined />}>
                      {t('user.blocked')}
                    </Tag>
                  ) : (
                    <Tag color="success" icon={<CheckOutlined />}>
                      {t('user.active')}
                    </Tag>
                  )}
                </Descriptions.Item>

                {user.role === UserRole.Agent && (
                  <Descriptions.Item label={t('user.invalidateChargePermission')}>
                    {user.canInvalidateCharge ? (
                      <Tag color="success" icon={<CheckOutlined />}>
                        {t('general.true')}
                      </Tag>
                    ) : (
                      <Tag color="error" icon={<CloseOutlined />}>
                        {t('general.false')}
                      </Tag>
                    )}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Col>
            <Col span={16}>
              <Descriptions column={1} bordered>
                <Descriptions.Item label={t('user.name')}>{user.name}</Descriptions.Item>
                <Descriptions.Item label={t('user.surname')}>{user.surname}</Descriptions.Item>
                <Descriptions.Item label={t('user.businessName')}>{user.businessName}</Descriptions.Item>
                <Descriptions.Item label={t('user.email')}>{user.email}</Descriptions.Item>
                <Descriptions.Item label={t('user.city')}>{user.city}</Descriptions.Item>
                <Descriptions.Item label={t('user.country')}>{user.country}</Descriptions.Item>
                <Descriptions.Item label={t('user.cap')}>{user.postalCode}</Descriptions.Item>
                <Descriptions.Item label={t('user.address')}>{user.address}</Descriptions.Item>
                <Descriptions.Item label={t('user.phone')}>{user.phoneNumber}</Descriptions.Item>
              </Descriptions>
            </Col>

            <Col span={24}>
              <Typography.Title level={3}>{t('user.networkTitle')}</Typography.Title>
              <Tree
                selectable={false}
                className="tree-network"
                switcherIcon={<DownOutlined />}
                defaultExpandedKeys={['current-user']}
                defaultExpandAll={user.role === UserRole.SuperAdmin ? true : false}
              >
                {getAdminTree()}
                {getOperationTree()}
                {getAgentTree()}
              </Tree>
            </Col>
          </Row>
        </section>
      </section>
    </section>
  )
}

UserDetails.displayName = 'UserDetails'

export default UserDetails
