import React from 'react'
import { useLoadInstanceDetails } from 'services/query/admin'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'
import InstanceDetailsContainer from 'containers/instances/InstanceDetails/InstanceDetailsContainer'

const InstanceDetails: React.FC = () => {
  const { id } = useParams()
  const detailsQuery = useLoadInstanceDetails(id)
  const { data: instance, isFetching } = detailsQuery

  return (
    <>
      {isFetching ? (
        <Skeleton className="mt-100 px-25" active avatar paragraph={{ rows: 12 }} />
      ) : (
        <InstanceDetailsContainer instance={instance} />
      )}
    </>
  )
}

InstanceDetails.displayName = 'InstanceDetails'

export default InstanceDetails
