import React from 'react'
import { User } from 'types/app/users'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import routes from 'config/routes'
import { Avatar, Col, Modal, notification, Row, Typography } from 'antd'
import { DeleteFilled, EditFilled, LockFilled, MailFilled } from '@ant-design/icons'

interface Props {
  user: User
  handleDelete: (id: string) => void
}

const UserDetailsHeader: React.FC<Props> = ({ user, handleDelete }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onDelete = (userId: string) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        try {
          handleDelete(userId)
          notification.success({ message: t('messagesCrud.userDeleted') })
          navigate(generatePath(routes.users.url), { replace: true })
        } catch (error) {
          console.error(error)
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }
  return (
    <section className="card-panel">
      <Row key={user.id} align="middle">
        <Col className="avatar" span={3}>
          <Avatar size={64}>
            {user.name.charAt(0).toUpperCase()}
            {user.surname.charAt(0).toUpperCase()}
          </Avatar>
        </Col>
        <Col className="info" span={12}>
          <span>{user.email}</span>
          <span>Istanza: {`${user.instance}(${user.instanceId})`}</span>
          <span>ID: {user.id}</span>
        </Col>
        <Col span={8} className="icons">
          <span>
            <Link to={generatePath(routes.userDetails.url, { id: user.id }) + '?editmode=details'}>
              <EditFilled />
            </Link>
          </span>
          <span className="delete">
            <DeleteFilled onClick={() => onDelete(user.id)} />
          </span>
          <span>
            <Link to={generatePath(routes.userDetails.url, { id: user.id }) + '?editmode=changePass'}>
              <LockFilled />
            </Link>
          </span>
          <span>
            <MailFilled />
          </span>
        </Col>
      </Row>
    </section>
  )
}

export default UserDetailsHeader
