import ChargeNewContainer from 'containers/charges/ChargeNew'
import React from 'react'
import { useParams } from 'react-router-dom'

const ChargePage: React.FC = () => {
  const { id } = useParams()
  if (id === 'new') {
    return <ChargeNewContainer />
  }
}

ChargePage.displayName = 'ChargePage'

export default ChargePage
