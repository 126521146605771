import React from 'react'
import routes from 'config/routes'

import { Currency } from 'types/app/admin'
import { useLoadCurrencyList, useDeleteCurrency } from 'services/query/admin'

import { useTranslation } from 'react-i18next'
import TopPage from 'components/TopPage'
import TableComponent from 'components/Table'

import { generatePath, Link } from 'react-router-dom'
import { Button, Card, Modal, notification, Space, Tooltip, Typography } from 'antd'
import { DeleteFilled, EditFilled, EyeFilled, PlusOutlined } from '@ant-design/icons'
import style from './styles.module.scss'

const CurrencyList: React.FC = () => {
  const { t } = useTranslation()
  const crumbs = () => [{ route: routes.home }, { route: routes.currencies }]
  const deleteCurrency = useDeleteCurrency()
  const { data: currencies, isFetched, isLoading } = useLoadCurrencyList()
  const dataLoading: boolean = isLoading || !isFetched

  const onDeleteCurrency = async (currency: Currency) => {
    try {
      await deleteCurrency(currency)
      notification.success({ message: t('messagesCrud.currencyDeleted') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  const handleDelete = (currency: Currency) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDeleteCurrency(currency)
      },
    })
  }

  const columns = [
    {
      key: 'id',
      title: t('general.id'),
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      width: '10%',
    },
    {
      key: 'name',
      title: t('general.name'),
      dataIndex: 'name',
      sorter: (a, b) => (a.id >= b.id ? 0 : 1),
    },
    {
      key: 'code',
      title: t('general.symbol'),
      dataIndex: 'code',
      sorter: (a, b) => (a.id >= b.id ? 0 : 1),
      render: (text, record) => `${record.code} (${record.symbol})`,
    },
    {
      key: 'actions',
      title: t('general.actions'),
      className: 'cell-actions',
      dataIndex: 'id',
      // eslint-disable-next-line react/display-name
      render: (text, record) => (
        <Space>
          <Link
            key={`action-details-${record.id}`}
            to={generatePath(routes.currencyDetails.url, { id: record.id })}
          >
            <Tooltip title={t('tooltip.details')}>
              <EyeFilled />
            </Tooltip>
          </Link>
          <Link
            key={`action-edit-${record.id}`}
            to={generatePath(routes.currencyDetails.url, { id: record.id }) + '?editmode=details'}
          >
            <Tooltip title={t('tooltip.edit')}>
              <EditFilled />
            </Tooltip>
          </Link>
          <Tooltip key={`action-delete-${record.id}`} title={t('tooltip.delete')}>
            <DeleteFilled onClick={() => handleDelete(record)} />
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <section className={style['currencies']}>
      <TopPage title={t('menu.currencies')} crumbs={crumbs()}>
        <Button type="primary">
          <Link key="action-create" to={generatePath(routes.currencyDetails.url, { id: 'new' })}>
            {t('btn.createCurrency')} <PlusOutlined />
          </Link>
        </Button>
      </TopPage>
      <section className="app-main-content">
        <Card>
          <TableComponent columns={columns} isLoading={dataLoading} dataSource={currencies} />
        </Card>
      </section>
    </section>
  )
}

CurrencyList.displayName = 'CurrencyList'

export default CurrencyList
