import React from 'react'

import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import MessageDetails from 'components/messages/MessageDetails'
import { Message } from 'types/app/messages'
import { useDeleteMessage } from 'services/query/messages'

interface Props {
  message: Message
}

const MessageDetailsContainer: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation()
  const deleteMessage = useDeleteMessage()

  const onDeleteMessage = async (message: Message) => {
    try {
      await deleteMessage(message)
      notification.success({ message: t('messagesCrud.messageDeleted') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }
  return <MessageDetails message={message} onDeleteMessage={onDeleteMessage} />
}

export default MessageDetailsContainer
