import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from 'store/user/actions'
import { clearUser } from 'core/localStore'
import { useQueryClient } from 'react-query'
import routes from 'config/routes'

const UserLogout: React.FC = () => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const onLogout = () => {
    clearUser()
    queryClient.clear()
    dispatch(logout())
  }
  return (
    <Link to={routes.login.url} className="ant-dropdown-link" onClick={onLogout}>
      LOGOUT
    </Link>
  )
}

export default UserLogout
