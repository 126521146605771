import { deleteTranslation, getTranslationDetails, listTranslations } from 'api/translations'
import { mapTranslation, mapTranslationList } from 'services/map/translations'
import { ListTranslationsRequest } from 'types/api/translations'
import * as queryKeys from 'config/queryKeys'
import { useMutation, useQuery, useQueryClient } from 'react-query'

export function useLoadTranslationsList(queryParams: ListTranslationsRequest) {
  const queryFunction = async () => {
    const response = await listTranslations(queryParams)
    return { data: mapTranslationList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.translationList(queryParams), queryFunction, {
    placeholderData: { data: [], total: 0 },
    keepPreviousData: true,
  })
}

export function useDeleteTranslations() {
  const deleteFunction = async (tag: string) => {
    const result = await deleteTranslation(tag)

    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.translationList())
    },
  })
  return mutateAsync
}

export function useLoadTranslation(tag: string) {
  const queryFunction = async () => {
    const response = await getTranslationDetails(tag)
    return mapTranslation(response)
  }

  return useQuery(queryKeys.userDetails(tag), queryFunction, {
    placeholderData: null,
  })
}
