import React from 'react'
import TranslationsTableContainer from 'containers/translations'

const TraslationsPage: React.FC = () => {
  return <TranslationsTableContainer />
}

TraslationsPage.displayName = 'TraslationsPage'

export default TraslationsPage
