import Debug from 'debug'
import * as Api from 'types/api/translations'
import { mapNumber, mapString } from './common'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'
import { Translation, Translations } from 'types/app/translations'

const debug = Debug('services:map:translations')

export const mapTranslations = (data: Api.TranslationsData[]): Translations[] => {
  try {
    return data.map((data) => ({
      translation: mapString(data.translation),
      languageId: mapNumber(data.languageId),
      languageName: mapString(data.languageName),
      languageIso: mapString(data.languageIso),
    }))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapTranslation = (data: Api.TranslationListData): Translation => {
  try {
    return {
      labelTag: mapString(data.labelTag),
      defaultTranslation: mapString(data.defaultTranslation),
      translations: data.translations && mapTranslations(data.translations),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapTranslationList = (data: Api.TranslationListData[]): Translation[] => {
  try {
    return data.map((item) => mapTranslation(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
