import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import routes from 'config/routes'
import { User } from 'types/app/users'

import { useLoadCountryList } from 'services/query/admin'
import { selectUserSettings } from 'store/user/selectors'

import { Operation, UserRole } from 'types/enums'
import { useSelector } from 'react-redux'
import { checkSubmitButtonBeDisabled } from 'core/helpers'
import FloatingLabel from 'components/FloatingLabel'

import { Button, Col, Form, Input, Row, Select } from 'antd'

interface Props {
  operationType: Operation
  user: User
  onFinish: (values: Json) => void
}

const ChangeAccountDetails: React.FC<Props> = ({ user, operationType, onFinish }) => {
  const { t } = useTranslation()
  const userSettings = useSelector(selectUserSettings)
  const { data: countries, isFetching: isCountriesFetching } = useLoadCountryList()

  const [form] = Form.useForm()
  const [formData, setFormData] = React.useState({
    ...user,
    role: user?.role || UserRole.Affiliate,
    countryId: user?.countryId || countries[0]?.id,
    idUpperLevel: user?.idUpperLevel,
    instance: userSettings?.selectedInstance?.name,
    instanceId: userSettings?.selectedInstance?.id,
  })

  if (isCountriesFetching) return null

  const requiredFields = [
    'name',
    'surname',
    'fiscalCode',
    'businessName',
    'email',
    'city',
    'countryId',
    'postalCode',
    'phoneNumber',
  ]

  return (
    <section>
      <Form form={form} name="changeUserDetails" initialValues={formData} onFinish={onFinish}>
        <>
          <Row key={user.id} gutter={[24, 24]}>
            <Col span={8}>
              <FloatingLabel value={formData?.name}>
                <Form.Item
                  label={t('user.name')}
                  name="name"
                  rules={[
                    { min: 3, message: t('validationForm.nameCharacters') },
                    { required: true, message: t('validationForm.fieldRequired') },
                  ]}
                >
                  <Input type="text" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={8}>
              <FloatingLabel value={formData?.surname}>
                <Form.Item
                  label={t('user.surname')}
                  name="surname"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input
                    type="text"
                    onChange={(e) => setFormData({ ...formData, surname: e.target.value })}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={8}>
              <FloatingLabel value={formData?.businessName}>
                <Form.Item
                  label={t('user.businessName')}
                  name="businessName"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input
                    type="text"
                    onChange={(e) => setFormData({ ...formData, businessName: e.target.value })}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={8}>
              <FloatingLabel value={formData?.email}>
                <Form.Item
                  label={t('user.email')}
                  name="email"
                  rules={[
                    { type: 'email', message: t('validationForm.emailNotValid') },
                    { required: true, message: t('validationForm.fieldRequired') },
                  ]}
                >
                  <Input type="text" onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={8}>
              <FloatingLabel value={formData?.city}>
                <Form.Item
                  label={t('user.city')}
                  name="city"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input type="text" onChange={(e) => setFormData({ ...formData, city: e.target.value })} />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={8}>
              <FloatingLabel value={formData?.countryId}>
                <Form.Item
                  label={t('user.country')}
                  name="countryId"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Select
                    placeholder={t('user.country')}
                    className="user-country s-panel"
                    onSelect={(value) => setFormData({ ...formData, countryId: value })}
                  >
                    {countries.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={8}>
              <FloatingLabel value={formData?.postalCode}>
                <Form.Item
                  label={t('user.cap')}
                  name="postalCode"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input
                    type="text"
                    onChange={(e) => setFormData({ ...formData, postalCode: e.target.value })}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={8}>
              <FloatingLabel value={formData?.address}>
                <Form.Item
                  label={t('user.address')}
                  name="address"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input
                    type="text"
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col span={8}>
              <FloatingLabel value={formData?.phoneNumber}>
                <Form.Item
                  label={t('user.phone')}
                  name="phoneNumber"
                  rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                >
                  <Input
                    type="text"
                    onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
          </Row>
        </>

        <Form.Item shouldUpdate className="btn mt-15" wrapperCol={{ offset: 8, span: 16 }}>
          {() => (
            <>
              <Button type="primary" htmlType="submit">
                <Link to={routes.users.url}>{t('btn.cancel')}</Link>
              </Button>
              {operationType === Operation.Create && (
                <Button
                  type="default"
                  htmlType="submit"
                  disabled={checkSubmitButtonBeDisabled(form, requiredFields)}
                >
                  {t('btn.next')}
                </Button>
              )}
              {operationType === Operation.Update && (
                <Button
                  type="default"
                  htmlType="submit"
                  disabled={checkSubmitButtonBeDisabled(form, requiredFields)}
                >
                  {t('btn.next')}
                </Button>
              )}
            </>
          )}
        </Form.Item>
      </Form>
    </section>
  )
}

ChangeAccountDetails.displayName = 'ChangeAccountDetails'

export default ChangeAccountDetails
