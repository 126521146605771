import React from 'react'
import BreadCrumbs from 'components/layout/BreadCrumbs/BreadCrumbs'
import { Col, Row, Typography } from 'antd'
import style from './styles.module.scss'
import { themes } from './themes'
import { useTheme } from 'providers/ThemeProvider'
import classNames from 'classnames'

interface Props {
  title: string
  crumbs: any
}
const TopPage: React.FC<Props> = ({ title, crumbs, children }) => {
  const { getStyles } = useTheme()

  const themeStyles = getStyles(themes)
  const topPageClass = classNames(style['top-page'], themeStyles.theme, 'printable')
  return (
    <section className={topPageClass}>
      {title && (
        <Typography.Title className="printable" level={3}>
          {title}
        </Typography.Title>
      )}
      {crumbs && (
        <Row className="not-printable">
          <Col flex="1 0 auto">
            <BreadCrumbs crumbs={crumbs} />
          </Col>
          <Col flex="0 0 200px" className="align-right">
            {children}
          </Col>
        </Row>
      )}
    </section>
  )
}

TopPage.displayName = 'TopPage'

export default TopPage
