import React from 'react'
import { useTranslation } from 'react-i18next'

import routes from 'config/routes'
import { tableSorter } from 'core/helpers'
import TopPage from 'components/TopPage'
import TableComponent from 'components/Table'
import { Button, Card, Modal, notification, Space, Tooltip, Typography } from 'antd'
import FilterPanel from './FilterPanel'
import style from './styles.module.scss'
import { useLoadLanguageList } from 'services/query/admin'
import { generatePath, Link, useNavigate } from 'react-router-dom'
import { DeleteFilled, EditFilled, EyeFilled, PlusOutlined } from '@ant-design/icons'
import { TranslationsFilter } from 'types/app/filters'

interface Props {
  dataSource: any
  initialFilter: TranslationsFilter
  isLoading: boolean
  handleChange: (pagination, filters, sorter) => void
  handleFiltering: (filter: TranslationsFilter) => void
  handleDelete: (id: string) => void
}

const TranslationsTable: React.FC<Props> = ({
  dataSource,
  initialFilter,
  handleFiltering,
  handleChange,
  handleDelete,
  isLoading,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const actions = ['details', 'edit', 'delete']

  const { data: languages } = useLoadLanguageList()

  const tableData = []
  if (dataSource?.data.length > 0) {
    dataSource.data.map((item) => {
      const temp = {
        labelTag: item.labelTag,
        defaultTranslation: item.defaultTranslation,
      }

      item.translations.map((tr) => {
        temp[tr.languageIso] = tr
      })
      tableData.push(temp)
    })
  }

  const onDelete = (tag: string) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        try {
          handleDelete(tag)
          notification.success({ message: t('messagesCrud.translationDeleted') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns: any = [
    {
      key: 'key',
      title: t('general.key'),
      dataIndex: 'labelTag',
      width: '10%',
      sorter: (a, b) => tableSorter(a.key, b.key),
    },
  ]
  languages.map((language) => {
    columns.push({
      key: language.id,
      title: (
        <>
          <img
            style={{ width: '25px', marginRight: '10px' }}
            src={`/images/flags/${language.iso.toLowerCase()}.png`}
            alt={language.iso}
          />
          {language.iso}
        </>
      ),
      dataIndex: language.iso,
      width: '20%',
      render: (text) => text?.translation,
    })
  })
  columns.push({
    key: 'actions',
    title: t('general.actions'),
    dataIndex: 'actions',
    className: 'cell-actions',
    width: '30%',
    // eslint-disable-next-line react/display-name
    render: (text, record) => (
      <Space>
        {actions.map((action) => {
          switch (action) {
            case 'edit':
              return (
                <Link
                  key={`action-edit-${record.labelTag}`}
                  to={generatePath(routes.translationDetails.url, { id: record.labelTag }) + '?editmode'}
                >
                  <Tooltip title={t('tooltip.edit')}>
                    <EditFilled />
                  </Tooltip>
                </Link>
              )
            case 'delete':
              return (
                <Tooltip key={`action-delete-${record.labelTag}`} title={t('tooltip.delete')}>
                  <DeleteFilled onClick={() => onDelete(record.labelTag)} />
                </Tooltip>
              )
            case 'details':
              return (
                <Link
                  key={`action-detail-${record.labelTag}`}
                  to={generatePath(routes.translationDetails.url, { id: record.labelTag })}
                >
                  <Tooltip title={t('tooltip.details')}>
                    <EyeFilled />
                  </Tooltip>
                </Link>
              )
            default:
              return
          }
        })}
      </Space>
    ),
  })

  const crumbs = () => [{ route: routes.home }, { route: routes.translations }]
  return (
    <section className={style['transactions-table']}>
      <TopPage title={t('menu.translations')} crumbs={crumbs()}>
        <Button
          type="primary"
          onClick={() => navigate(generatePath(routes.translationDetails.url, { id: 'new' }))}
        >
          {t('btn.createTranslation')} <PlusOutlined />
        </Button>
      </TopPage>
      <section className="app-main-content">
        <FilterPanel initialFilter={initialFilter} onFilter={handleFiltering} />
        <Card className="mt-16">
          <TableComponent
            columns={columns}
            dataSource={tableData}
            onChange={handleChange}
            total={dataSource?.total}
            isLoading={isLoading}
          />
        </Card>
      </section>
    </section>
  )
}

export default TranslationsTable
