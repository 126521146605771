import React, { useContext, useState } from 'react'
import routes from 'config/routes'
import { userContext } from 'core'
import { useTranslation } from 'react-i18next'
import { UserStatus } from 'types/enums'

import TopPage from 'components/TopPage'

import { UserStatus as UserStatusComponent } from 'components/Statuses'
import PhoneNumber from './PhoneNumber'
import AmountCharge from './Amount'
import CompleteCharge from './CompleteCharge'

import { ActiveBalance } from 'types/app/charges'

import { Button, Card, Col, Row, Steps, Tag, Alert, Spin, Space } from 'antd'
import style from './styles.module.scss'

interface Props {
  activeBalance: ActiveBalance
  accountStatus: number
  onConfirm: (mobile: string, amount: number) => void
  isSubmitting: boolean
}

const ChargeNew: React.FC<Props> = ({ activeBalance, accountStatus, onConfirm, isSubmitting }) => {
  const { t } = useTranslation()
  const { user } = useContext(userContext)
  const [current, setCurrent] = useState(0)
  const [prefix, setPrefix] = useState('')
  const [number, setNumber] = useState(null)
  const [amount, setAmount] = useState(10)

  const reset = () => {
    setPrefix('')
    setNumber(null)
    setAmount(null)
    setCurrent(0)
  }

  const isCreditZero = activeBalance?.activeBalance <= 0

  const currentValuesValidated =
    (current == 0 && prefix !== '' && number !== null && number !== '') ||
    (current == 1 && amount !== null && amount > 0)

  const next = () => {
    if (currentValuesValidated) {
      setCurrent(current + 1)
    }
  }

  const prev = () => {
    if (current !== 0) {
      setCurrent(current - 1)
    }
  }

  const crumbs = () => [
    { route: routes.home },
    { route: routes.charge, routeParam: { key: 'id', value: 'new' } },
  ]
  const { Step } = Steps
  const steps = [
    {
      title: t('charge.phoneNumber'),
      content: (
        <PhoneNumber
          prefix={prefix}
          number={number}
          setPrefix={setPrefix}
          setNumber={setNumber}
          disabled={isCreditZero}
        />
      ),
    },
    {
      title: t('charge.amount'),
      content: <AmountCharge setAmount={setAmount} amount={amount} balance={activeBalance} />,
    },
    {
      title: t('charge.finish'),
      content: (
        <CompleteCharge
          prefix={prefix}
          number={number}
          amount={amount}
          currencySymbol={activeBalance.currencySymbol}
        />
      ),
    },
  ]

  const onConfirmClicked = () => {
    if (amount && prefix && number) {
      onConfirm(`${prefix}${number}`, amount)
    }
  }

  const getTagStyle = () => {
    return isCreditZero ? 'red' : 'green'
  }

  const getStepsStatus = () => {
    return isCreditZero ? 'error' : 'process'
  }

  return (
    <section className={style['charge-new']}>
      <TopPage title={t('menu.charges')} crumbs={crumbs()} />
      <section className="app-main-content">
        <Card className="mb-30">
          <Row gutter={[12, 12]}>
            <Col xl={8} xs={24}>
              {user}
            </Col>
            <Col xl={8} xs={24}>
              <Space size="small">
                {t('user.availableBalance')}
                <Tag color={getTagStyle()}>
                  {activeBalance.activeBalance}
                  {activeBalance.currencySymbol}
                </Tag>
              </Space>
            </Col>
            <Col xl={8} xs={24}>
              <Space size="small">
                {t('user.status')}
                <UserStatusComponent value={accountStatus} />
              </Space>
            </Col>
          </Row>
        </Card>

        {accountStatus === UserStatus.Active ? (
          <Card>
            {isSubmitting ? (
              <Row align="middle" justify="center" style={{ height: '200px' }}>
                <Spin size="large" />
              </Row>
            ) : (
              <>
                {isCreditZero && <Alert className="mb-30" message={t('user.notEnoughCredit')} type="error" />}
                <Steps current={current} status={getStepsStatus()}>
                  {steps.map((item) => (
                    <Step
                      key={item.title}
                      title={item.title}
                      disabled={isCreditZero}
                      status={getStepsStatus()}
                    />
                  ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
                <Row justify="end" className="steps-action mt-30">
                  <Button type="primary" onClick={() => reset()} disabled={isCreditZero}>
                    {t('btn.reset')}
                  </Button>
                  <Button
                    type="ghost"
                    disabled={current == 0}
                    style={{ margin: '0 8px' }}
                    onClick={() => prev()}
                  >
                    {t('btn.back')}
                  </Button>
                  {current < steps.length - 1 && (
                    <Button
                      type="default"
                      disabled={!currentValuesValidated || isCreditZero}
                      onClick={() => next()}
                    >
                      {t('btn.next')}
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button type="default" onClick={onConfirmClicked}>
                      {t('btn.confirm')}
                    </Button>
                  )}
                </Row>
              </>
            )}
          </Card>
        ) : (
          <Card>
            <Row align="middle" justify="center">
              <Col span={12}>
                <Alert
                  showIcon
                  type="error"
                  message={t('message.blockedAccount.title')}
                  description={t('message.blockedAccount.description')}
                />
              </Col>
            </Row>
          </Card>
        )}
      </section>
    </section>
  )
}

ChargeNew.displayName = 'ChargeNew'

export default ChargeNew
