import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatTwoDecimals, tableSorter } from 'core/helpers'
import TableComponent from 'components/Table'

interface Props {
  dataSource?: any
}

const AffiliatesTable: React.FC<Props> = ({ dataSource }) => {
  const { t } = useTranslation()
  const columns = [
    {
      key: 'affiliateName',
      title: t('role.affiliate'),
      dataIndex: 'affiliateName',
      sorter: (a, b) => tableSorter(a.affiliateName, b.affiliateName),
    },
    {
      key: 'items.totalChargesNumber',
      title: t('reports.totalChargesNumber'),
      dataIndex: 'totalChargesNumber',
      sorter: (a, b) => tableSorter(a.totalChargesNumber, b.totalChargesNumber),
    },
    {
      key: 'items.totalChargesAmount',
      title: t('reports.totalChargesAmount'),
      dataIndex: 'totalChargesAmount',
      render: (val) => formatTwoDecimals(val),
      sorter: (a, b) => tableSorter(a.totalChargesAmount, b.totalChargesAmount),
    },
    {
      key: 'items.totalCommissionsAmount',
      title: t('reports.totalCommissionsAmount'),
      dataIndex: 'totalCommissionsAmount',
      render: (val) => formatTwoDecimals(val),
      sorter: (a, b) => tableSorter(a.totalCommissionsAmount, b.totalCommissionsAmount),
    },
  ]

  return <TableComponent columns={columns} dataSource={dataSource} />
}

export default AffiliatesTable
