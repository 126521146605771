import React, { useState } from 'react'
import { Button, Col, Input, Card, Row, Select, Form } from 'antd'

import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { CustomerFilter } from 'types/app/filters'
import { Country } from 'types/app/admin'
import FloatingLabel from 'components/FloatingLabel/FloatingLabel'

interface Props {
  countries: Country[]
  initialFilter: CustomerFilter
  onFilter: (filter: CustomerFilter) => void
}

const FilterPanel: React.FC<Props> = ({ countries, initialFilter, onFilter }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(initialFilter)
  const onResetFilter = () => {
    setFilter(initialFilter)
    onFilter(initialFilter)
  }
  return (
    <Card className={style['card-panel']}>
      <Row gutter={[12, 12]} align="middle">
        <Col xs={24} lg={12}>
          <Row gutter={[12, 12]} align="middle">
            <Col xs={24} md={8}>
              <FloatingLabel value={filter.countryId}>
                <Form.Item label={t('general.any')}>
                  <Select
                    onChange={(value) => setFilter({ ...filter, countryId: value })}
                    value={filter.countryId}
                  >
                    {countries.map(({ id, name }) => (
                      <Select.Option key={id} value={id}>
                        {name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col xs={24} md={8}>
              <FloatingLabel value={filter.businessName}>
                <Form.Item label={t('general.name')}>
                  <Input
                    onChange={(e) => setFilter({ ...filter, businessName: e.target.value })}
                    value={filter.businessName}
                    onPressEnter={() => onFilter(filter)}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={8}>
          <Row gutter={[12, 12]} align="middle">
            <Col span={12}>
              <Button type="primary" block onClick={onResetFilter}>
                {t('btn.reset')}
              </Button>
            </Col>
            <Col span={12}>
              <Button type="default" block onClick={() => onFilter(filter)}>
                {t('btn.search')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

FilterPanel.displayName = 'CardPanel'

export default FilterPanel
