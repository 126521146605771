import React from 'react'
import { Invoice } from 'types/app/invoices'
import { useTranslation } from 'react-i18next'
import routes from 'config/routes'

import TopPage from 'components/TopPage'
import InvoiceItemsTable from 'components/invoices/InvoiceItemsTable/InvoiceItemsTable'

import { formatTwoDecimals } from 'core/helpers'
import { generatePath, Link } from 'react-router-dom'

import { ThemeType, InvoiceViewMode } from 'types/enums'
import { useTheme } from 'providers/ThemeProvider'
import { PlusOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
import { Button, Card, Col, Row, Divider, Typography, Descriptions, Empty, Spin, Space } from 'antd'
import style from './styles.module.scss'
interface Props {
  mode: InvoiceViewMode
  invoice: Invoice
  onSubmit?: () => void
  isSubmitting?: boolean
}

const InvoiceView: React.FC<Props> = ({ invoice, onSubmit, mode, isSubmitting = false }) => {
  const { theme } = useTheme()
  const { t } = useTranslation()
  const isEditMode = mode === InvoiceViewMode.Create
  const isPayMode = mode === InvoiceViewMode.Pay
  const isDetailsMode = mode === InvoiceViewMode.Details

  const crumbs = () => [
    { route: routes.home },
    { route: routes.invoices },
    { route: routes.invoice, routeParam: { key: 'id', value: invoice.id } },
  ]
  const invoiceItems = invoice?.items || []

  return (
    <section className={style['invoice-new']}>
      <TopPage title={t('menu.invoices')} crumbs={crumbs()}>
        <Space>
          <Button>
            <Link key="action-create" to={routes.invoices.url}>
              {t('menu.invoices')}
            </Link>
          </Button>

          {isDetailsMode && (
            <Button type="primary">
              <Link to={generatePath(routes.invoice.url, { id: invoice.id.toString() }) + '?editmode=pay'}>
                {t('btn.pay')}
              </Link>
            </Button>
          )}

          {isPayMode && (
            <Button type="primary">
              <Link to={generatePath(routes.invoice.url, { id: invoice.id.toString() })}>
                {t('btn.detail')}
              </Link>
            </Button>
          )}
        </Space>
      </TopPage>
      <section className="app-main-content">
        <Card className="mb-30 p-30">
          {isSubmitting && (
            <Row align="middle" justify="center" style={{ height: '200px' }}>
              <Spin size="large" />
            </Row>
          )}
          {!isSubmitting && (
            <>
              <Row>
                <Col span={12}>
                  {isEditMode && (
                    <Typography.Title
                      level={2}
                      className={theme === ThemeType.Epayrex ? 'text-gray' : 'text-white'}
                    >
                      {t('invoice.newInvoice')}
                    </Typography.Title>
                  )}
                  {isPayMode && (
                    <>
                      <Typography.Title level={2} className="text-gray">
                        {t('invoice.payInvoice')}
                      </Typography.Title>
                      <Typography.Title level={5} className="text-gray">
                        {t('invoice.invoice')}
                        {invoice?.id || ''}
                      </Typography.Title>
                    </>
                  )}
                  {isDetailsMode && (
                    <>
                      <Typography.Title level={2} className="text-gray">
                        {t('invoice.invoiceDetails')}
                      </Typography.Title>
                      <Typography.Title level={5} className="text-gray">
                        {t('invoice.invoice')}
                        {invoice?.id || ''}
                      </Typography.Title>
                    </>
                  )}
                </Col>
                {theme === ThemeType.Epayrex && (
                  <Col style={{ textAlign: 'right' }} span={12}>
                    <img src="/images/epayrex-start.png" alt="logo" />
                    <img src="/images/epayrex-end.png" alt="logo" />
                  </Col>
                )}
                {theme === ThemeType.Timerpay && (
                  <Col style={{ textAlign: 'right' }} span={12}>
                    <img src="/images/timer-pay.png" alt="logo" />
                  </Col>
                )}
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              <Row gutter={24} className="mt-30">
                <Col span={8}>
                  <Typography.Title style={{ color: '#3f51b5', textTransform: 'uppercase' }} level={5}>
                    {t('invoice.affiliateDetails')}
                  </Typography.Title>
                  <Row>
                    <Typography.Text>
                      {t('user.name')}: {invoice?.recipientName}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text>
                      {t('user.businessName')}: {invoice?.recipientBusinessName}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text>
                      {t('user.email')}: {invoice?.recipientEmail}
                    </Typography.Text>
                  </Row>
                  <Row>
                    <Typography.Text>
                      {t('user.address')}: {invoice?.recipientAddress}
                    </Typography.Text>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              <Row className="mt-40">
                {!invoiceItems.length && !invoice?.id && (
                  <Col span={24}>
                    <Empty description={<span>{t('invoice.noInvoice')}</span>}>
                      <Link key="action-create" to={generatePath(routes.charge.url, { id: 'new' })}>
                        <Button type="primary" className="mt-20">
                          {t('menu.newCharge')}
                          <PlusOutlined />
                        </Button>
                      </Link>
                    </Empty>
                  </Col>
                )}
                {invoiceItems.length > 0 && (
                  <Col span={24}>
                    <InvoiceItemsTable dataSource={invoiceItems} />
                  </Col>
                )}
              </Row>

              {invoice && (
                <Row justify="end" align="bottom" className="mt-80">
                  <Col span={6}>
                    <Descriptions className="emit" column={1} bordered size="small">
                      <Descriptions.Item
                        label={
                          <Typography.Title level={5} className="text-gray">
                            {t('invoice.totalAmount')}
                          </Typography.Title>
                        }
                      >
                        {formatTwoDecimals(invoice?.totalAmount)}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Title level={5} className="text-gray">
                            {t('invoice.totalTaxes')}
                          </Typography.Title>
                        }
                      >
                        {formatTwoDecimals(invoice?.totalVat)}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Title level={5} className="text-gray">
                            {t('invoice.totalTaxableAmount')}
                          </Typography.Title>
                        }
                      >
                        {formatTwoDecimals(invoice?.totalTaxableAmount)}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Title level={5} className="text-gray">
                            {t('invoice.totalCommissions')}
                          </Typography.Title>
                        }
                      >
                        {formatTwoDecimals(invoice?.totalCommissions)}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={<Typography.Title level={5}>{t('invoice.netDue')}</Typography.Title>}
                      >
                        <Typography.Title level={5}>{formatTwoDecimals(invoice?.totalNet)}</Typography.Title>
                      </Descriptions.Item>
                    </Descriptions>
                    <Row justify="center">
                      {isEditMode && (
                        <Button
                          icon={<SafetyCertificateOutlined />}
                          className="mt-30 btn-emit"
                          type="primary"
                          onClick={onSubmit}
                        >
                          {t('btn.emit')}
                        </Button>
                      )}
                      {isPayMode && (
                        <Button
                          icon={<SafetyCertificateOutlined />}
                          className="mt-30 btn-emit"
                          type="primary"
                          onClick={onSubmit}
                        >
                          {t('btn.pay')}
                        </Button>
                      )}
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Card>
      </section>
    </section>
  )
}

InvoiceView.displayName = 'InvoiceView'
export default InvoiceView
