import React from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { RightOutlined } from '@ant-design/icons'

interface Props {
  crumbs: any
}
const BreadCrumbs: React.FC<Props> = ({ crumbs }) => {
  const { t } = useTranslation()

  const getRouteParam = (params) => {
    if (params.key === 'id') {
      return { id: params.value }
    }
    return { id: params.value }
  }

  return (
    <Breadcrumb separator={<RightOutlined />}>
      {crumbs.map((crumb, index) =>
        crumb.hasOwnProperty('routeParam') ? (
          <Breadcrumb.Item key={index}>
            <Link to={generatePath(crumb.route.url, getRouteParam(crumb.routeParam))}>
              {t(crumb.route.menu)}
            </Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={index}>
            <Link to={crumb.route.url}>{t(crumb.route.menu)}</Link>
          </Breadcrumb.Item>
        ),
      )}
    </Breadcrumb>
  )
}

BreadCrumbs.displayName = 'BreadCrumbs'

export default BreadCrumbs
