import axios, { AxiosError, AxiosResponse } from 'axios'
import { config } from 'core'
import * as Api from 'types/api/auth'
import { getApiError } from './common'

export async function login(data: Api.LoginRequest): Promise<Api.LoginResponse | ApiError> {
  return axios
    .post(config.api.login(), data)
    .then((response) => response.data as Api.LoginResponse)
    .catch((error) => getApiError(error))
}

export async function forgotPassword(data: Api.ForgotPasswordRequest): Promise<AxiosResponse | ApiError> {
  return axios
    .post(config.api.forgotPassword(), data)
    .then((response) => response.data as AxiosResponse)
    .catch((error) => getApiError(error))
}

export async function resetPassword(data: Api.ResetPasswordRequest): Promise<AxiosResponse | ApiError> {
  return axios
    .post(config.api.resetPassword(), data)
    .then((response) => response.data as AxiosResponse)
    .catch((error) => getApiError(error))
}

export async function confirmUserEmail(data: Api.ConfirmEmailRequest): Promise<AxiosResponse> {
  try {
    const response = await axios.post(config.api.confirmEmail(), data)
    return response
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
