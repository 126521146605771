import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as queryKeys from 'config/queryKeys'

import {
  listUsers,
  getUserDetails,
  getUserNetwork,
  editUser,
  listRoles,
  changeUserPassword,
  createUser,
  deleteUser,
  editUserCreditLimits,
  listAgents,
} from 'api/users'
import {
  ListUsersRequest,
  UserChangePasswordRequest,
  UserCreateEditRequest,
  UserEditCreditLimitsRequest,
} from 'types/api/users'
import {
  mapUserList,
  mapUser,
  mapCreateEditFromUser,
  mapRoleList,
  mapEditCreditLimits,
} from 'services/map/users'
import { User } from 'types/app/users'
import { UserRoleLevel } from 'types/enums'

export function useLoadRoleList() {
  const queryFunction = async () => {
    const response = await listRoles()
    return mapRoleList(response)
  }
  return useQuery(queryKeys.roleList(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadUserList(queryParams: ListUsersRequest) {
  const queryFunction = async () => {
    const response = await listUsers(queryParams)
    return { data: mapUserList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.userList(queryParams), queryFunction, {
    placeholderData: { data: [], total: 0 },
    keepPreviousData: true,
  })
}

export function useLoadAgents(params: ListUsersRequest) {
  const queryFunction = async () => {
    const response = await listAgents(params)
    return { data: mapUserList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.userList(params), queryFunction, {
    placeholderData: { data: [], total: 0 },
    enabled: !!params?.instanceId,
  })
}

export function useLoadUsers(roleLevel: UserRoleLevel, instanceId: number) {
  const queryParams = {
    instanceId,
    roleLevel,
    pageSize: 100,
    page: 0,
    email: '',
    surnameName: '',
    orderBy: 'Name',
    orderByDirection: 1,
  }

  const queryFunction = async () => {
    const response = await listUsers(queryParams)
    return { data: mapUserList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.userList(queryParams), queryFunction, {
    placeholderData: { data: [], total: 0 },
    enabled: !!queryParams.instanceId,
  })
}

export function useLoadAffiliates(queryParams: ListUsersRequest) {
  const queryFunction = async () => {
    const response = await listUsers(queryParams)
    return { data: mapUserList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.userList(queryParams), queryFunction, {
    placeholderData: { data: [], total: 0 },
    enabled: !!queryParams.instanceId,
  })
}

export function useLoadUser(userId: string) {
  const queryFunction = async () => {
    if (userId.toUpperCase() === 'NEW') return mapUser({})
    const response = await getUserDetails(userId)
    return mapUser(response)
  }

  return useQuery(queryKeys.userDetails(userId), queryFunction, {
    placeholderData: null,
  })
}

export function useUserNetwork(userId: string, instanceId?: number) {
  const queryFunction = async () => {
    if (userId.toUpperCase() === 'NEW') return { data: mapUserList([]), total: 0 }

    const response = await getUserNetwork(userId, instanceId)
    return { data: mapUserList(response.data), total: response.totalRecords }
  }

  return useQuery(queryKeys.userNetwork(userId, instanceId), queryFunction, {
    placeholderData: null,
  })
}

export function useCreateUser() {
  const mutateFunction = async ({ user, creditLimits }) => {
    const mappedData: UserCreateEditRequest = mapCreateEditFromUser(user, creditLimits)
    const newUser = await createUser(mappedData)
    return newUser
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (insertedUser) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.userDetails(insertedUser.id)),
        queryClient.invalidateQueries(queryKeys.userList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditUser() {
  const mutateFunction = async (user: User) => {
    const mappedData: UserCreateEditRequest = mapCreateEditFromUser(user)
    await editUser(user.id, mappedData)
    return user as User
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (user) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.userDetails(user.id)),
        queryClient.invalidateQueries(queryKeys.userList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditUserCreditLimits(userId: string) {
  const mutateFunction = async (creditLimits) => {
    const requestData: UserEditCreditLimitsRequest = mapEditCreditLimits(creditLimits)
    await editUserCreditLimits(userId, requestData)
    return creditLimits
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.userDetails(userId)),
        queryClient.invalidateQueries(queryKeys.userList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteUser() {
  const deleteFunction = async (userId: string) => {
    const result = await deleteUser(userId)

    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.userList())
    },
  })
  return mutateAsync
}

export function useChangeUserPassword(userId: string) {
  const mutateFunction = async ({ password, confirmPassword }) => {
    const model: UserChangePasswordRequest = {
      id: userId,
      password,
      confirmPassword,
    }
    const res = await changeUserPassword(model)
    return res
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: () => {
      return Promise.all([queryClient.invalidateQueries(queryKeys.userDetails(userId))])
    },
  })
  return mutateAsync
}
