import { useQuery } from 'react-query'
import * as queryKeys from 'config/queryKeys'
import { mapChargesReport, mapCommissionsReport } from 'services/map/reports'
import { createChargesReport, createCommissionsReport } from 'api/reports'
import { CreateReportRequest } from 'types/api/reports'

export function useCreateChargesReport(queryParams: CreateReportRequest) {
  const queryFunction = async () => {
    const response = await createChargesReport(queryParams)
    return { data: mapChargesReport(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.chargesReport(queryParams), queryFunction, {
    enabled: !!queryParams.instanceId,
  })
}

export function useCreateCommissionsReport(queryParams: CreateReportRequest) {
  const queryFunction = async () => {
    const response = await createCommissionsReport(queryParams)
    return { data: mapCommissionsReport(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.commissionsReport(queryParams), queryFunction, {
    enabled: !!queryParams.instanceId,
  })
}
