import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Card, Row, Select } from 'antd'

import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { InvoicesFilter } from 'types/app/filters'
import * as Enums from 'types/enums'
import format from 'dayjs'
import { DatePicker } from 'components/antd-dayjs'
import FloatingLabel from 'components/FloatingLabel/FloatingLabel'
import { useLoadAffiliates } from 'services/query/users'
import { useSelector } from 'react-redux'
import { UserRole, UserRoleLevel } from 'types/enums'
import { userContext } from 'core'
import { selectUserSettings } from 'store/user/selectors'

interface Props {
  initialFilter: InvoicesFilter
  onFilter: (filter: InvoicesFilter) => void
}

const FilterPanel: React.FC<Props> = ({ initialFilter, onFilter }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(initialFilter)
  const userSettings = useSelector(selectUserSettings)
  const { role: userRole, instanceId: currentUserInstanceId } = useContext(userContext)
  const { data: affiliates } = useLoadAffiliates({
    instanceId: userSettings?.selectedInstance?.id || currentUserInstanceId,
    pageSize: 100,
    page: 0,
    roleLevel: UserRoleLevel.Affiliate,
    email: '',
    surnameName: '',
    orderBy: 'Name',
    orderByDirection: 1,
  })
  const [affiliateOptions, setAgentOptions] = useState(affiliates?.data)

  useEffect(() => {
    setAgentOptions(affiliates.data)
  }, [affiliates.data])
  const onResetFilter = () => {
    setFilter(initialFilter)
    onFilter(initialFilter)
  }

  const status = [
    {
      key: Enums.InvoiceStatus.Created,
      value: t('invoice.created'),
    },
    {
      key: Enums.InvoiceStatus.Paid,
      value: t('invoice.paid'),
    },
    {
      key: Enums.InvoiceStatus.Deleted,
      value: t('invoice.deleted'),
    },
  ]

  return (
    <Card className={style['card-panel']}>
      <Row gutter={[12, 12]} align="middle">
        <Col xs={24} md={4}>
          <FloatingLabel value={filter?.dateFrom && format(filter?.dateFrom)}>
            <Form.Item label={t('general.dateFromPlaceholder')}>
              <DatePicker
                placeholder={null}
                format="DD MM YYYY"
                value={filter?.dateFrom && format(filter?.dateFrom)}
                onChange={(value) => setFilter({ ...filter, dateFrom: value?.toDate() })}
              />
            </Form.Item>
          </FloatingLabel>
        </Col>
        <Col xs={24} md={4}>
          <FloatingLabel value={filter?.dateTo && format(filter?.dateTo)}>
            <Form.Item label={t('general.dateToPlaceholder')}>
              <DatePicker
                placeholder={null}
                format="DD MM YYYY"
                value={filter?.dateTo && format(filter?.dateTo)}
                onChange={(value) => setFilter({ ...filter, dateTo: value?.toDate() })}
              />
            </Form.Item>
          </FloatingLabel>
        </Col>
        <Col xs={24} md={4}>
          <FloatingLabel value={filter?.status}>
            <Form.Item label={t('general.status')}>
              <Select onChange={(value) => setFilter({ ...filter, status: value })} value={filter?.status}>
                {status.map(({ key, value }) => (
                  <Select.Option key={key} value={key}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </FloatingLabel>
        </Col>
        {userRole !== UserRole.Affiliate && (
          <Col xs={24} md={4}>
            <FloatingLabel value={filter?.userId}>
              <Form.Item label={t('role.affiliate')}>
                <Select onChange={(value) => setFilter({ ...filter, userId: value })} value={filter?.userId}>
                  {affiliateOptions.map((affiliate) => (
                    <Select.Option value={affiliate.id} key={affiliate.id}>
                      {affiliate.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FloatingLabel>
          </Col>
        )}
        <Col xs={24} lg={8}>
          <Row gutter={[12, 12]} align="middle">
            <Col span={12}>
              <Button type="primary" block onClick={onResetFilter}>
                {t('btn.reset')}
              </Button>
            </Col>
            <Col span={12}>
              <Button type="default" block onClick={() => onFilter(filter)}>
                {t('btn.search')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

FilterPanel.displayName = 'FilterPanel'

export default FilterPanel
