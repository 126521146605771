import React from 'react'
import TableComponent from 'components/Table'
import TopPage from 'components/TopPage'
import routes from 'config/routes'
import { tableSorter } from 'core/helpers'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { generatePath, Link } from 'react-router-dom'
import { useLoadMessageList } from 'services/query/messages'
import { Message } from 'types/app/messages'

import { MessageType } from 'components/Statuses'
import { DeleteFilled, EyeFilled } from '@ant-design/icons'
import { Card, Modal, Space, Tooltip, Typography } from 'antd'

import style from './styles.module.scss'
interface Props {
  onDeleteMessage: (message) => void
}

const MessagesTable: React.FC<Props> = ({ onDeleteMessage }) => {
  const { t } = useTranslation()
  const crumbs = () => [{ route: routes.home }, { route: routes.messages }]
  const actions = ['details', 'delete']
  const { data: messages, isFetched, isLoading } = useLoadMessageList()

  const dataLoading: boolean = isLoading || !isFetched

  const handleDelete = (message: Message) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      onOk() {
        onDeleteMessage(message)
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns = [
    {
      key: 'typeId',
      title: t('message.typeId'),
      dataIndex: 'typeId',
      width: '10%',
      sorter: (a, b) => tableSorter(a.typeId, b.typeId),
      render: (typeId) => <MessageType value={typeId} />,
    },
    {
      key: 'sendDate',
      title: t('message.date'),
      dataIndex: 'sendDate',
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
      sorter: (a, b) => tableSorter(a.sendDate, b.sendDate),
    },
    {
      key: 'senderName',
      title: t('message.senderName'),
      dataIndex: 'senderName',
      sorter: (a, b) => tableSorter(a.senderName, b.senderName),
    },
    {
      key: 'subject',
      title: t('message.subject'),
      dataIndex: 'subject',
      width: '30%',
      sorter: (a, b) => tableSorter(a.subject, b.subject),
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'actions',
      className: 'cell-actions',
      // eslint-disable-next-line react/display-name
      render: (action, record) => (
        <Space>
          {actions.map((action) => {
            switch (action) {
              case 'delete':
                return (
                  <Tooltip key={`action-delete-${record.id}`} title={t('tooltip.delete')}>
                    <DeleteFilled onClick={() => handleDelete(record.id)} />
                  </Tooltip>
                )
              case 'details':
                return (
                  <Link
                    key={`action-details-${record.id}`}
                    to={generatePath(routes.messageDetails.url, { id: record.id })}
                  >
                    <Tooltip title={t('tooltip.details')}>
                      <EyeFilled />
                    </Tooltip>
                  </Link>
                )
              default:
                return
            }
          })}
        </Space>
      ),
    },
  ]

  return (
    <section className={style['messages']}>
      <TopPage title={t('menu.messages')} crumbs={crumbs()}></TopPage>
      <section className="app-main-content">
        <Card>
          <TableComponent
            rowClassName={(record) => (record.readOnDate === null ? 'bold' : 'normal')}
            isLoading={dataLoading}
            columns={columns}
            dataSource={messages.data}
          />
        </Card>
      </section>
    </section>
  )
}

MessagesTable.displayName = 'MessagesTable'

export default MessagesTable
