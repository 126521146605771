import { config } from 'core'
import { AxiosError } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'

import * as Api from 'types/api/reports'
import { ApiSearchResponse } from 'types/api/common'

const axios = getAxios()

export async function createChargesReport(
  queryParams: Api.CreateReportRequest,
): Promise<ApiSearchResponse<Api.ChargesReportListData>> {
  try {
    const response = await axios.post(
      config.api.chargesReport(),
      queryParams,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createCommissionsReport(
  queryParams: Api.CreateReportRequest,
): Promise<ApiSearchResponse<Api.CommissionsReportListData>> {
  try {
    const response = await axios.post(
      config.api.commissionsReport(),
      queryParams,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
