import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import routes from 'config/routes'
import { VALIDATE_PASSWORD_REGEX } from 'core'
import { LockOutlined } from '@ant-design/icons'
import FloatingLabel from 'components/FloatingLabel'

import { Button, Form, Input, Row, Spin } from 'antd'
import style from './styles.module.scss'
interface Props {
  onSave: (values: Json) => void
  spin: boolean
}

const ChangePassword: React.FC<Props> = ({ onSave, spin }) => {
  const { t } = useTranslation()
  const [formDate, setFormData] = useState({
    password: null,
    confirmPassword: null,
  })

  const onFinish = (values: Json) => {
    onSave(values)
  }

  return (
    <section className={style['change-password']}>
      {spin && (
        <Row align="middle" justify="center" style={{ height: '200px' }}>
          <Spin size="large" />
        </Row>
      )}
      <Form name="changePassword" onFinish={onFinish}>
        {!spin && (
          <div className="mt-50 pl-30">
            <FloatingLabel value={formDate.password}>
              <Form.Item
                className="mb-50"
                label={t('user.password')}
                name="password"
                rules={[
                  { required: true, message: t('login.errors.password') },
                  {
                    pattern: new RegExp(VALIDATE_PASSWORD_REGEX),
                    message: t('login.errors.passwordRules'),
                  },
                ]}
              >
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  onChange={(e) => setFormData({ ...formDate, password: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>

            <FloatingLabel value={formDate.confirmPassword}>
              <Form.Item
                label={t('user.confirmPass')}
                name="confirmPassword"
                rules={[
                  { required: true, message: t('login.errors.password') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'))
                    },
                  }),
                ]}
                dependencies={['password']}
              >
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  onChange={(e) => setFormData({ ...formDate, confirmPassword: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </div>
        )}

        <Form.Item className="btn mt-40" wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            <Link to={routes.users.url}>{t('btn.cancel')}</Link>
          </Button>
          <Button type="default" htmlType="submit" disabled={spin}>
            {t('btn.change')}
          </Button>
        </Form.Item>
      </Form>
    </section>
  )
}

ChangePassword.displayName = 'ChangePassword'

export default ChangePassword
