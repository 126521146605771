import React, { useContext, useState } from 'react'
import DashboardComponent from 'components/Dashboard'
import { useSearchCharges } from 'services/query/charges'
import { userContext } from 'core'
import { SearchChargesRequest } from 'types/api/charges'
import { ChargesFilter } from 'types/app/filters'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'
import { UserRole } from 'types/enums'
import { useSearchParams } from 'react-router-dom'

const DashboardContainer: React.FC = () => {
  const [page] = useState(0)
  const [pageSize] = useState(5)
  const [sorterField] = useState('registrationDate')
  const [sorterDirection] = useState('descend')
  const userSettings = useSelector(selectUserSettings)
  const { role: userRole } = useContext(userContext)
  const [searchParams] = useSearchParams()
  const number = searchParams.get('number')

  const initialFilter: ChargesFilter = {
    dateFrom: dayjs().subtract(1, 'months').toDate(),
    dateTo: dayjs().toDate(),
    code: '',
    mobilePhone: number ? number : '',
    guid: '',
    status: null,
    smsAnswerStatus: null,
    invoiceStatus: null,
    invoiceId: null,
    amountMin: null,
    amountMax: null,
  }
  const [filter] = useState<ChargesFilter>(initialFilter)

  const searchInstanceId = userRole === UserRole.SuperAdmin ? userSettings?.selectedInstance?.id || null : -1
  const queryParams: SearchChargesRequest = {
    page,
    pageSize,
    instanceId: searchInstanceId,
    dateFrom: filter.dateFrom.toISOString(),
    dateTo: filter.dateTo.toISOString(),
    code: filter.code,
    mobilePhone: filter.mobilePhone,
    guid: filter.guid,
    status: filter.status || null,
    smsAnswerStatus: filter.smsAnswerStatus || null,
    invoiceStatus: filter.invoiceStatus || null,
    invoiceId: filter.invoiceId || null,
    amountMin: filter.amountMin,
    amountMax: filter.amountMax,
    orderBy: sorterField || 'registrationDate',
    orderByDirection: sorterDirection === 'descend' ? -1 : 1,
  }

  const { data: charges, isFetched, isLoading } = useSearchCharges(queryParams)

  const dataLoading: boolean = isLoading || !isFetched

  return <DashboardComponent data={charges.data} isLoading={dataLoading} />
}

DashboardContainer.displayName = 'DashboardContainer'

export default DashboardContainer
