import React, { useEffect, useState } from 'react'
import { getAuthToken, getUserClaims } from 'core/localStore'
import { Navigate } from 'react-router'
import routes from 'config/routes'
import { userContext } from 'core'
import { useLocation } from 'react-router-dom'
import { getCurrentRoute, isRouteAuthorizedByUser } from 'core/helpers'

const AuthProvider: React.FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(null)
  const [user, setUser] = useState({ user: '', role: null, userId: null, instanceId: null })

  useEffect(() => {
    const getData = async () => {
      try {
        const token = await getAuthToken()
        const authenticated = token != null ? true : false
        const userClaims = getUserClaims()
        if (userClaims) {
          setUser({
            user: userClaims.userName,
            role: userClaims.userRole,
            userId: userClaims.userId,
            instanceId: userClaims.instanceId,
          })
        }
        setIsAuthenticated(authenticated)
      } catch (e) {
        if (e.name === 'InvalidTokenError') setIsAuthenticated(false)
      }
    }
    getData()
  }, [])

  const location = useLocation()
  const currentRoute = getCurrentRoute(location.pathname)
  if (user && !isRouteAuthorizedByUser(user.role, currentRoute)) {
    return <Navigate to={routes.error.url} />
  }

  if (isAuthenticated === null) {
    return null
  }

  if (isAuthenticated === false) {
    return <Navigate to={routes.login.url} />
  }

  return (
    <>
      <userContext.Provider value={user}>{children}</userContext.Provider>
    </>
  )
}

export default AuthProvider
