import React, { useState } from 'react'
import Support from 'components/support/Support/Support'
import { useTranslation } from 'react-i18next'
import routes from 'config/routes'
import { notification } from 'antd'
import { generatePath, useNavigate } from 'react-router-dom'

import { useCreateMessage } from 'services/query/messages'
import { Message } from 'types/app/messages'

const SupportContainer: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [spin, setSpin] = useState(false)
  const createMessage = useCreateMessage()

  const onSendMessage = async (model: Message) => {
    setSpin(true)
    try {
      await createMessage(model)
      navigate(generatePath(routes.support.url), { replace: true })
      notification.success({ message: t('messagesCrud.messageSent') })
    } catch (error) {
      console.error(error)
      setSpin(false)
      notification.error({ message: t('messagesCrud.existingTag') })
    }
    setSpin(false)
  }
  return <Support onSendMessage={onSendMessage} spin={spin} />
}

SupportContainer.displayName = 'SupportContainer'

export default SupportContainer
