import React from 'react'
import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InstanceDetails } from 'types/app/admin'
import {
  useLoadCountryList,
  useLoadCurrencyList,
  useLoadCustomerList,
  useLoadLanguageList,
} from 'services/query/admin'

import FloatingLabel from 'components/FloatingLabel/FloatingLabel'

import { Button, Col, Form, Input, Row, Select, Space, Spin, InputNumber } from 'antd'
import style from './styles.module.scss'
interface Props {
  instance: InstanceDetails
  cultures: any
  onSave: (values: Json) => void
  isFetching: boolean
}

const ChangeDetails: React.FC<Props> = ({ instance, cultures, onSave, isFetching }) => {
  const { t } = useTranslation()

  const { data: countries, isFetching: isCountriesFetching } = useLoadCountryList()
  const { data: customers, isFetching: isCustomersFetching } = useLoadCustomerList()
  const { data: languages, isFetching: isLanguagesFetching } = useLoadLanguageList()
  const { data: currencies, isFetching: isCurrenciesFetching } = useLoadCurrencyList()

  const [form] = Form.useForm()
  const [formData, setFormData] = React.useState(instance)
  form.setFieldsValue({
    invoicePayTerm: instance.invoicePayTerm,
  })

  const onFinish = (values: Json) => {
    onSave(values)
  }

  if (isCountriesFetching || isCustomersFetching || isLanguagesFetching || isCurrenciesFetching || isFetching)
    return (
      <Row align="middle" justify="center" style={{ height: '200px' }}>
        <Spin size="large" />
      </Row>
    )

  const initialValues = {
    ...instance,
    customerId: instance.customerId || '',
    languageId: instance.languageId || '',
    countryId: instance.countryId || '',
    currencyId: instance.currencyId || '',
  }

  return (
    <section className={style['change-details']}>
      <Form name="changeInstanceDetails" initialValues={initialValues} onFinish={onFinish}>
        <Row key={instance.id} gutter={[24, 24]}>
          <Col span={12}>
            <FloatingLabel value={formData?.name}>
              <Form.Item label={t('general.name')} name="name">
                <Input type="text" onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.siteUrl}>
              <Form.Item label={t('instance.siteUrl')} name="siteUrl">
                <Input type="text" onChange={(e) => setFormData({ ...formData, siteUrl: e.target.value })} />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.apiUrl}>
              <Form.Item label={t('instance.apiUrl')} name="apiUrl">
                <Input type="text" onChange={(e) => setFormData({ ...formData, apiUrl: e.target.value })} />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.apiKey}>
              <Form.Item label={t('instance.apiKey')} name="apiKey">
                <Input type="text" onChange={(e) => setFormData({ ...formData, apiKey: e.target.value })} />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.languageId}>
              <Form.Item label={t('general.language')} name="languageId">
                <Select onChange={(value) => setFormData({ ...formData, languageId: value })}>
                  {languages.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.connectionString}>
              <Form.Item label={t('instance.connectionString')} name="connectionString">
                <Input
                  type="text"
                  onChange={(e) => setFormData({ ...formData, connectionString: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.ipsWhitelist}>
              <Form.Item label={t('instance.ipsWhitelist')} name="ipsWhitelist">
                <Input
                  type="text"
                  onChange={(e) => setFormData({ ...formData, ipsWhitelist: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.customerId}>
              <Form.Item label={t('general.customer')} name="customerId">
                <Select onChange={(value) => setFormData({ ...formData, customerId: value })}>
                  {customers.map(({ id, businessName }) => (
                    <Select.Option key={id} value={id} label={businessName}>
                      {businessName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.cultureCode}>
              <Form.Item label={t('instance.cultureCode')} name="cultureCode">
                <Select
                  className="s-panel"
                  showSearch
                  placeholder={null}
                  onChange={(value) => setFormData({ ...formData, cultureCode: value })}
                  filterOption={(input, option) =>
                    option.label.toString().toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {cultures.map((culture) => (
                    <Select.Option
                      key={culture.cultureCode}
                      value={culture.cultureCode}
                      label={`${culture.regionInfo.englishName} ${culture.name} - ${culture.regionInfo.threeLetterISORegionName}`}
                    >
                      {`${culture.name} - (${culture.cultureCode})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.currencyId}>
              <Form.Item label={t('general.currency')} name="currencyId">
                <Select onChange={(value) => setFormData({ ...formData, currencyId: value })}>
                  {currencies.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.countryId}>
              <Form.Item label={t('general.country')} name="countryId">
                <Select onChange={(value) => setFormData({ ...formData, countryId: value })}>
                  {countries.map(({ id, name }) => (
                    <Select.Option key={id} value={id}>
                      {name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.invoicePayTerm}>
              <Form.Item
                label={`${t('instance.invoicePayTerm')} (${t('general.days')})`}
                name="invoicePayTerm"
                rules={[
                  {
                    required: true,
                    message: <span>{t('validationForm.fieldRequired')}</span>,
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  keyboard={false}
                  onChange={(value) => {
                    form.setFieldsValue({
                      invoicePayTerm: value,
                    })
                    setFormData({ ...formData, invoicePayTerm: value })
                  }}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={24} className="align-right pb-40">
            <Form.Item className="m-0">
              <Space>
                <Button type="primary" htmlType="submit">
                  <Link to={routes.instances.url}>{t('btn.cancel')}</Link>
                </Button>
                <Button type="default" htmlType="submit">
                  {t('btn.save')}
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </section>
  )
}

ChangeDetails.displayName = 'ChangeDetails'

export default ChangeDetails
