import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import InvoiceNewContainer from 'containers/invoices/InvoiceNew'
import InvoicePayContainer from 'containers/invoices/InvoicePay'
import InvoiceDetailsContainer from 'containers/invoices/InvoiceDetails'

const InvoicePage: React.FC = () => {
  const { id } = useParams()
  const [searchParams] = useSearchParams()
  const editMode = searchParams.get('editmode')

  if (id === 'new') {
    return <InvoiceNewContainer />
  }

  if (editMode === 'pay') {
    return <InvoicePayContainer />
  }

  return <InvoiceDetailsContainer />
}

InvoicePage.displayName = 'InvoicePage'

export default InvoicePage
