import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatTwoDecimals, tableSorter } from 'core/helpers'
import TableComponent from 'components/Table'
import AffiliateRowTable from 'components/reports/CommissionsReport/AffiliateRowTable/AffiliateRowTable'

interface Props {
  dataSource?: any
}

const CommissionRowTable: React.FC<Props> = ({ dataSource }) => {
  const { t } = useTranslation()

  const affiliateExpandable = (record) => {
    return <AffiliateRowTable dataSource={record.affiliates} />
  }

  const columns = [
    {
      key: 'commissionPerc',
      title: t('general.commissionPerc'),
      dataIndex: 'commissionPerc',
      sorter: (a, b) => tableSorter(a.comissionPerc, b.comissionPerc),
    },
    {
      key: 'items.totalChargesNumber',
      title: t('reports.totalChargesNumber'),
      dataIndex: 'totalChargesNumber',
      sorter: (a, b) => tableSorter(a.totalChargesNumber, b.totalChargesNumber),
    },
    {
      key: 'items.totalChargesAmount',
      title: t('reports.totalChargesAmount'),
      dataIndex: 'totalChargesAmount',
      render: (val) => formatTwoDecimals(val),
      sorter: (a, b) => tableSorter(a.totalChargesAmount, b.totalChargesAmount),
    },
    {
      key: 'items.totalCommissionsAmount',
      title: t('reports.totalCommissionsAmount'),
      dataIndex: 'totalCommissionsAmount',
      render: (val) => formatTwoDecimals(val),
      sorter: (a, b) => tableSorter(a.totalChargesAmount, b.totalChargesAmount),
    },
  ]

  return (
    <TableComponent
      columns={columns}
      dataSource={dataSource}
      expandable={{ expandedRowRender: affiliateExpandable }}
    />
  )
}

export default CommissionRowTable
