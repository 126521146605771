import CustomersTable from 'components/customers/CustomersTable'
import React, { useState } from 'react'

import { useDeleteCustomer, useSearchCustomers, useLoadCountryList } from 'services/query/admin'
import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE } from 'core'
import { SearchCustomersRequest } from 'types/api/admin'
import { CustomerFilter } from 'types/app/filters'

const CustomersTableContainer: React.FC = () => {
  const [sorterField, setSorterField] = useState('businessName')
  const [sorterDirection, setSorterDirection] = useState('ascend')
  const initialFilter: CustomerFilter = { countryId: null, businessName: '' }
  const [filter, setFilter] = useState<CustomerFilter>(initialFilter)

  const queryParams: SearchCustomersRequest = {
    page: 0,
    pageSize: TABLE_PAGINATION_DEFAULT_PAGE_SIZE,
    businessName: filter.businessName,
    countryId: filter.countryId || null,
    orderBy: sorterField || 'businessName',
    orderByDirection: sorterDirection === 'ascend' ? 1 : -1,
  }

  const { data: customers, refetch, isFetched, isLoading } = useSearchCustomers(queryParams)
  const dataLoading: boolean = isLoading || !isFetched
  const { data: countries } = useLoadCountryList()
  const deleteCustomer = useDeleteCustomer()

  const handleChange = (pagination, filters, sorter) => {
    setSorterField(sorter.field)

    if (sorter.hasOwnProperty('column')) {
      setSorterField(sorter.field)
      setSorterDirection(sorter.order)
    }
  }

  const handleFiltering = (filter: CustomerFilter) => {
    setSorterField('businessName')
    setSorterDirection('ascend')
    setFilter(filter)
  }

  const handleDelete = async (id: string) => {
    await deleteCustomer(id)
    await refetch()
  }

  return (
    <CustomersTable
      countries={countries}
      initialFilter={initialFilter}
      dataSource={customers.data}
      handleChange={handleChange}
      isLoading={dataLoading}
      handleFiltering={handleFiltering}
      handleDelete={handleDelete}
    />
  )
}

CustomersTableContainer.displayName = 'CustomersTableContainer'

export default CustomersTableContainer
