import React from 'react'
import { useParams } from 'react-router-dom'

import Skeleton from 'components/Skeleton'
import TranslationDetailsContainer from 'containers/translations/TranslationDetails'
import { useLoadTranslation } from 'services/query/translations'

const LanguageDetails: React.FC = () => {
  const { id } = useParams()
  if (id === 'new') return <TranslationDetailsContainer translation={null} />
  const { data: translation, isFetching: istranslationFetching } = useLoadTranslation(id)
  return (
    <>{istranslationFetching ? <Skeleton /> : <TranslationDetailsContainer translation={translation} />}</>
  )
}

LanguageDetails.displayName = 'LanguageDetails'

export default LanguageDetails
