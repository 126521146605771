import React, { useEffect, useState } from 'react'
import Debug from 'debug'

import CustomerEdit from 'components/customers/CustomerEdit'
import CustomerDetailsComponent from 'components/customers/CustomerDetails'

import { CustomerDetails } from 'types/app/admin'
import { useCreateCustomer, useEditCustomer } from 'services/query/admin'
import { generatePath, useNavigate } from 'react-router-dom'
import routes from 'config/routes'
import { notification } from 'antd'
import { useRouter } from 'core/useRouter'

interface Props {
  customer: CustomerDetails
}

const debug = Debug('Frontend:CustomerDetailsContainer')

const CustomerDetailsContainer: React.FC<Props> = ({ customer }) => {
  const [editMode, setEditMode] = useState(false)
  const createCustomer = useCreateCustomer()
  const editCustomer = useEditCustomer()
  const router = useRouter()
  const navigate = useNavigate()

  useEffect(() => {
    if (router.query.editmode) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [router.query])

  const onCustomerDetailsSaved = async (model: CustomerDetails) => {
    debug('onCustomerDetailsSaved', model)
    try {
      if (customer?.id) {
        await editCustomer({ ...customer, ...model })
        navigate(generatePath(routes.customerDetails.url, { id: customer.id.toString() }), { replace: true })
      } else {
        const newCustomer = await createCustomer(model)
        navigate(generatePath(routes.customerDetails.url, { id: newCustomer.id.toString() }), {
          replace: true,
        })
      }
    } catch (error) {
      console.error(error)
      notification.error({ message: `Customer save error: ${error || ''}` })
    }
  }
  if (editMode) {
    return (
      <CustomerEdit
        customer={customer}
        mode={router.query.editmode}
        onSave={onCustomerDetailsSaved}
      ></CustomerEdit>
    )
  }
  return (
    <>
      <CustomerDetailsComponent customer={customer}></CustomerDetailsComponent>
    </>
  )
}

export default CustomerDetailsContainer
