import Currencies from 'components/currencies/CurrencyList'
import React from 'react'

const CurrenciesPage: React.FC = () => {
  return <Currencies />
}

CurrenciesPage.displayName = 'CurrenciesPage'

export default CurrenciesPage
