import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Api from 'types/api/widgets'

import { Card, Typography, Row, Skeleton as AntSkeleton, Col, Statistic } from 'antd'
import { useTheme } from 'providers/ThemeProvider'
import { ThemeType } from 'types/enums'

interface Props {
  data: Api.ClientServedWidgetData
  isLoading: boolean
}

const ClientServedComponent: React.FC<Props> = ({ data, isLoading }) => {
  const { theme } = useTheme()
  const [colors] = useState(theme === ThemeType.Epayrex ? '#ececec' : '#131620')

  const { t } = useTranslation()
  if (isLoading)
    return (
      <Card size="small" className="clients-statistic">
        <AntSkeleton active paragraph={{ rows: 8 }} />
      </Card>
    )
  return (
    <Card size="small" className="clients-statistic">
      <Row gutter={[12, 12]} justify="center">
        <Col span={24}>
          <Typography.Title level={3} className="m-0">
            {t('charts.clientTitle')}
          </Typography.Title>
          <Typography.Paragraph>{t('charts.clientSubtitle')}</Typography.Paragraph>
        </Col>
        <Col span={16} className="align-center">
          <Statistic
            style={{ background: colors }}
            title={t('general.totalCharges')}
            value={data.totalClients}
          />
        </Col>
        <Col span={16} className="align-center">
          <Statistic
            style={{ background: colors }}
            title={t('general.totalUniqueNumbers')}
            value={data.uniqueTotalClients}
          />
        </Col>
      </Row>
    </Card>
  )
}

ClientServedComponent.displayName = 'ClientServedComponent'

export default ClientServedComponent
