import React from 'react'
import CountriesNumber from './countryNumber.json'
import { useTranslation } from 'react-i18next'

import { Col, Input, Row, Select, Form } from 'antd'
import style from './styles.module.scss'
import FloatingLabel from 'components/FloatingLabel'

interface Props {
  prefix: string
  number: number
  setPrefix: (value: string) => void
  setNumber: (value: number) => void
  disabled: boolean
}

const PhoneNumber: React.FC<Props> = ({ number, prefix, setPrefix, setNumber, disabled }) => {
  const { t } = useTranslation()

  function handlePrefix(value) {
    setPrefix(value)
  }
  function handleNumber(value) {
    setNumber(value.target.value)
  }
  return (
    <section className={style['phone-number']}>
      <Row gutter={[24, 0]}>
        <Col span={5}>
          <FloatingLabel value={prefix}>
            <Form.Item label={t('charge.countryCode')}>
              <Select
                value={prefix}
                onChange={handlePrefix}
                disabled={disabled}
                showSearch={true}
                filterOption={(input, option) =>
                  option.label.toString().toLowerCase().includes(input.toLowerCase())
                }
              >
                {CountriesNumber.map((number) => (
                  <Select.Option
                    key={number.code}
                    value={number.dial_code}
                    label={`${number.name} ${number.dial_code}`}
                  >
                    {`${number.name} ${number.dial_code}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </FloatingLabel>
        </Col>
        <Col span={19}>
          <FloatingLabel value={number}>
            <Form.Item label={t('charge.number')}>
              <Input type="text" onChange={handleNumber} value={number} disabled={disabled} />
            </Form.Item>
          </FloatingLabel>
        </Col>
      </Row>
    </section>
  )
}

PhoneNumber.displayName = 'PhoneNumber'

export default PhoneNumber
