import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import routes from 'config/routes'
import { ChargesFilter } from 'types/app/filters'
import { tableSorter } from 'core/helpers'
import FilterPanel from './FilterPanel'
import TopPage from 'components/TopPage'
import TableComponent from 'components/Table'
import { SmsAnswerStatus, ChargeStatus } from 'components/Statuses'
import * as Enums from 'types/enums'
import dayjs from 'dayjs'
import { userContext } from 'core'
import { UserRole } from 'types/enums'
import style from './styles.module.scss'
import { Charge } from 'types/app/charges'
import { useLoadUser } from 'services/query/users'

import { CloseCircleOutlined, CheckCircleOutlined, DeleteFilled, FileExcelOutlined } from '@ant-design/icons'
import { Card, Modal, notification, Space, Tooltip, Typography } from 'antd'
interface Props {
  data: Charge[]
  total: number
  initialFilter: ChargesFilter
  isLoading: boolean
  handleChange: (pagination, filters, sorter) => void
  handleFiltering: (filter: ChargesFilter) => void
  handleDelete: (chargeId: number) => void
  handleInvalidate: (chargeId: number) => void
  setNumber: (number: string) => void
}

const ChargesTable: React.FC<Props> = ({
  data,
  total,
  isLoading,
  initialFilter,
  handleFiltering,
  handleChange,
  handleDelete,
  handleInvalidate,
  setNumber,
}) => {
  const { t } = useTranslation()
  const { role: userRole, userId } = useContext(userContext)
  const userDetailsQuery = useLoadUser(userId)
  const { data: userDetail } = userDetailsQuery

  const onDelete = async (id: number) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmDelete')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      async onOk() {
        try {
          await handleDelete(id)
          notification.success({ message: t('messagesCrud.chargeDeleted') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.deleteError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const onInvalidate = async (id: number) => {
    Modal.confirm({
      icon: null,
      content: (
        <>
          <Typography.Title level={4}>{t('general.confirmInvalidate')}</Typography.Title>
          <Typography.Text>{t('general.reconfirmDelete')}</Typography.Text>
        </>
      ),
      async onOk() {
        try {
          await handleInvalidate(id)
          notification.success({ message: t('messagesCrud.chargeInvalidated') })
        } catch (error) {
          notification.error({ message: t('messagesCrud.invalidateError') })
        }
      },
      okText: t('btn.confirm'),
      cancelText: t('btn.cancel'),
    })
  }

  const columns = [
    {
      key: 'guid',
      title: t('general.code'),
      dataIndex: 'guid',
      width: 170,
      fixed: 'left',
      sorter: (a, b) => tableSorter(a.code, b.code),
    },
    {
      key: 'registrationDate',
      title: t('general.registrationDate'),
      dataIndex: 'registrationDate',
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
      sorter: (a, b) => tableSorter(a.registrationDate, b.registrationDate),
    },

    {
      key: 'mobilePhone',
      title: t('general.mobilePhone'),
      dataIndex: 'mobilePhone',
      sorter: (a, b) => tableSorter(a.mobileNumber, b.mobileNumber),
    },
    {
      key: 'amount',
      title: t('charge.amount'),
      dataIndex: 'amount',
      sorter: (a, b) => tableSorter(a.amount, b.amount),
    },
    {
      key: 'expirationDate',
      title: t('charge.expirationDate'),
      dataIndex: 'expirationDate',
      render: (date: Date) => {
        if (date) {
          return dayjs(date).format('DD.MM.YYYY')
        }

        return <span>{t('general.unlimited')}</span>
      },
      sorter: (a, b) => tableSorter(a.amount, b.amount),
    },
    {
      key: 'smsAnswerStatus',
      title: t('charge.smsAnswerStatus'),
      dataIndex: 'smsAnswerStatus',
      render: (status) => <SmsAnswerStatus value={status} />,
    },
    {
      key: 'smsSentDate',
      title: t('charge.smsSentDate'),
      dataIndex: 'smsSentDate',
      render: (date: Date) => {
        return dayjs(date).format('DD.MM.YYYY')
      },
      sorter: (a, b) => tableSorter(a.smsSentDate, b.smsSentDate),
    },
    {
      key: 'status',
      title: t('general.status'),
      dataIndex: 'status',
      render: (status) => <ChargeStatus value={status} />,
    },
    {
      key: 'userName',
      title: t('user.userName'),
      dataIndex: 'userName',
      hidden: userRole === UserRole.Affiliate,
      sorter: (a, b) => tableSorter(a.userName, b.userName),
    },
    {
      key: 'invoiceId',
      title: t('general.invoiceId'),
      dataIndex: 'invoiceId',
      sorter: (a, b) => tableSorter(a.invoiceId, b.invoiceId),
      // eslint-disable-next-line react/display-name
      render: (invoiceId: number) => (
        <Space>
          {invoiceId > 0 && (
            <>
              <CheckCircleOutlined style={{ color: 'green' }} /> {invoiceId}
            </>
          )}
          {invoiceId == 0 && <CloseCircleOutlined style={{ color: 'red' }} />}
        </Space>
      ),
    },
    {
      key: 'actions',
      title: t('general.actions'),
      dataIndex: 'id',
      width: 100,
      render: (id, record) => {
        const actionsDom = []

        if (record.status === Enums.ChargeStatus.Available) {
          switch (userRole) {
            case UserRole.SuperAdmin:
            case UserRole.Admin:
            case UserRole.Operator:
              actionsDom.push(
                <Tooltip key={`action-invalidate-${id}`} title={t('tooltip.invalidate')}>
                  <FileExcelOutlined onClick={() => onInvalidate(record.id)} />
                </Tooltip>,
              )
              break
            case UserRole.Agent:
              if (userDetail?.canInvalidateCharge) {
                actionsDom.push(
                  <Tooltip key={`action-invalidate-${id}`} title={t('tooltip.invalidate')}>
                    <FileExcelOutlined onClick={() => onInvalidate(record.id)} />
                  </Tooltip>,
                )
              }
              break
          }
        }

        if (record.status === Enums.ChargeStatus.NotSet || record.status === Enums.ChargeStatus.Canceled) {
          actionsDom.push(
            <Tooltip key={`action-delete-${id}`} title={t('tooltip.delete')}>
              <DeleteFilled onClick={() => onDelete(record.id)} />
            </Tooltip>,
          )
        }

        return actionsDom
      },
    },
  ].filter((item) => !item.hidden)

  const crumbs = () => [{ route: routes.home }, { route: routes.charges }]
  return (
    <section className={style['charge-table']}>
      <TopPage title={t('general.charges')} crumbs={crumbs()} />
      <section className="app-main-content">
        <FilterPanel initialFilter={initialFilter} onFilter={handleFiltering} setNumber={setNumber} />
        <Card className="mt-16">
          <TableComponent
            columns={columns}
            dataSource={data}
            onChange={handleChange}
            total={total}
            isLoading={isLoading}
          />
        </Card>
      </section>
    </section>
  )
}

export default ChargesTable
