import React, { useContext, useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import routes from 'config/routes'
import { getCurrentRoute } from 'core/helpers'
import { ThemeType, UserRole } from 'types/enums'
import { Layout, Menu, Affix } from 'antd'
import {
  CopyOutlined,
  DashboardOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
  EllipsisOutlined,
  MessageOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  StockOutlined,
  TableOutlined,
  TeamOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import SubMenu from 'antd/lib/menu/SubMenu'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'
import { userContext } from 'core'
import { useTheme } from 'providers/ThemeProvider'
import classNames from 'classnames'
import { themes } from './themes'

const SideMenu: React.FC = () => {
  const [collapsed, setCollapse] = useState(false)
  const { t } = useTranslation()
  const { theme } = useTheme()

  const { role: userRole } = useContext(userContext)

  const onCollapse = () => {
    setCollapse(!collapsed)
  }

  const getSelectItem = () => {
    const route = getCurrentRoute(location.pathname)
    if (routes.hasOwnProperty(route.key)) {
      return routes[route.key].key
    }
    return routes.home.key
  }

  const REPORT_ROLES = [UserRole.SuperAdmin, UserRole.Admin, UserRole.Operator, UserRole.Agent]

  const userInAnyRole = (roles: Array<UserRole>) => {
    return roles.indexOf(userRole) >= 0
  }

  getSelectItem()

  const { getStyles } = useTheme()
  const themeStyles = getStyles(themes)
  const siderPageClass = classNames(style.sider, themeStyles.theme)

  return (
    <Layout.Sider
      width={260}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      className={siderPageClass}
      trigger={null}
      breakpoint="lg"
    >
      <section className="sider-content">
        <Affix offsetTop={0}>
          <div className="sider-header">
            {collapsed ? (
              <DoubleRightOutlined className="trigger2" onClick={onCollapse} />
            ) : (
              <DoubleLeftOutlined className="trigger" onClick={onCollapse} />
            )}
            {theme === ThemeType.Timerpay && (
              <Link to={routes.home.url}>
                {collapsed && (
                  <img src="/images/pay.png" alt="logo" style={{ width: '50%', marginLeft: '5px' }} />
                )}
                {!collapsed && <img src="/images/timer-pay.png" alt="logo" style={{ width: '44%' }} />}
              </Link>
            )}
            {theme === ThemeType.Epayrex && (
              <Link to={routes.home.url}>
                <img src="/images/epayrex-start.png" alt="logo" />
                {!collapsed && <img src="/images/epayrex-end.png" alt="logo" style={{ width: '44%' }} />}
              </Link>
            )}
          </div>
        </Affix>
        <Menu mode="inline" defaultSelectedKeys={[getSelectItem()]}>
          <Menu.Item className="sider" key={routes.home.key} icon={<DashboardOutlined />}>
            <Link to={routes.home.url}>{t('menu.dashboard')}</Link>
          </Menu.Item>
          <Menu.ItemGroup key="item-group-1" title={collapsed ? <EllipsisOutlined /> : t('menu.charges')}>
            {userRole === UserRole.Affiliate && (
              <Menu.Item className="sider" key="2" icon={<ShoppingCartOutlined />}>
                <Link to={generatePath(routes.charge.url, { id: 'new' })}>{t('menu.newCharge')}</Link>
              </Menu.Item>
            )}
            <Menu.Item key={routes.charges.key} icon={<UnorderedListOutlined />}>
              <Link to={routes.charges.url}>{t('menu.chargesList')}</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup key="item-group-2" title={collapsed ? <EllipsisOutlined /> : t('menu.invoices')}>
            {userRole === UserRole.Affiliate && (
              <Menu.Item key="4" icon={<CopyOutlined />}>
                <Link to={generatePath(routes.invoice.url, { id: 'new' })}>{t('menu.newInvoice')}</Link>
              </Menu.Item>
            )}
            <Menu.Item key={routes.invoices.key} icon={<ProfileOutlined />}>
              <Link to={routes.invoices.url}>{t('menu.invoicesList')}</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup key="item-group-3" title={collapsed ? <EllipsisOutlined /> : t('menu.application')}>
            {userRole !== UserRole.Affiliate && (
              <Menu.Item className="sider" key={routes.users.key} icon={<TeamOutlined />}>
                <Link to={routes.users.url}>{t('menu.users')}</Link>
              </Menu.Item>
            )}
            <Menu.Item key={routes.transactions.key} icon={<ProfileOutlined />}>
              <Link to={routes.transactions.url}>{t('menu.transactions')}</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          {userInAnyRole(REPORT_ROLES) && (
            <Menu.ItemGroup key="item-group-4" title={collapsed ? <EllipsisOutlined /> : t('menu.reports')}>
              <Menu.Item className="sider" key="8" icon={<StockOutlined />}>
                <Link to={routes.chargesReport.url}>{t('menu.reportCharges')}</Link>
              </Menu.Item>

              {/* <Menu.Item className="sider" key="9" icon={<StockOutlined />}>
              <Link to="/">{t('menu.reportInvoices')}</Link>
            </Menu.Item> */}

              <Menu.Item className="sider" key="10" icon={<StockOutlined />}>
                <Link to={routes.commissionsReport.url}>{t('menu.reportCommissions')}</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          {userRole === UserRole.SuperAdmin && (
            <Menu.ItemGroup key="item-group-5" title={collapsed ? <EllipsisOutlined /> : t('menu.settings')}>
              <SubMenu key="sub-menu-1" icon={<SettingOutlined />} title={t('menu.settingsAdvanced')}>
                <Menu.Item className="sider" key={routes.customers.key}>
                  <Link to={routes.customers.url}>{t('menu.customers')}</Link>
                </Menu.Item>
                <Menu.Item className="sider" key={routes.instances.key}>
                  <Link to={routes.instances.url}>{t('menu.instances')}</Link>{' '}
                </Menu.Item>
              </SubMenu>
              <SubMenu key="sub-menu-2" icon={<TableOutlined />} title={t('menu.systemSettings')}>
                <Menu.Item className="sider" key={routes.languages.key}>
                  <Link to={routes.languages.url}>{t('menu.languagesMenu')}</Link>
                </Menu.Item>
                <Menu.Item className="sider" key={routes.countries.key}>
                  <Link to={routes.countries.url}>{t('menu.countries')}</Link>
                </Menu.Item>
                <Menu.Item className="sider" key={routes.currencies.key}>
                  <Link to={routes.currencies.url}>{t('menu.currencies')}</Link>
                </Menu.Item>
                <Menu.Item className="sider" key={routes.translations.key}>
                  <Link to={routes.translations.url}> {t('menu.translations')}</Link>
                </Menu.Item>
              </SubMenu>
            </Menu.ItemGroup>
          )}
          {userRole !== UserRole.SuperAdmin && (
            <Menu.ItemGroup key="item-group-6" title={collapsed ? <EllipsisOutlined /> : t('menu.messages')}>
              <Menu.Item className="sider" key={routes.messages.key} icon={<MessageOutlined />}>
                <Link to={routes.messages.url}>{t('menu.messages')}</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
          {userRole === (UserRole.Affiliate || UserRole.Agent) && (
            <Menu.ItemGroup key="item-group-7" title={collapsed ? <EllipsisOutlined /> : t('menu.info')}>
              <Menu.Item className="sider" key={routes.support.key} icon={<QuestionCircleOutlined />}>
                <Link to={routes.support.url}>{t('menu.support')}</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          )}
        </Menu>
      </section>
    </Layout.Sider>
  )
}

SideMenu.displayName = 'SideMenu'

export default SideMenu
