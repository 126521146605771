import CustomerDetailsContainer from 'containers/customers/CustomerDetails'
import React from 'react'
import { useLoadCustomerDetails } from 'services/query/admin'
import { useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

const CustomerDetails: React.FC = () => {
  const { id } = useParams()
  const detailsQuery = useLoadCustomerDetails(id)
  const { data: customer, isFetching } = detailsQuery

  return (
    <>
      {isFetching ? (
        <Skeleton className="mt-100 px-25" active avatar paragraph={{ rows: 12 }} />
      ) : (
        <CustomerDetailsContainer customer={customer} />
      )}
    </>
  )
}

CustomerDetails.displayName = 'CustomerDetails'

export default CustomerDetails
