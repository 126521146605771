import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Enums from 'types/enums'

interface Props {
  value: Enums.ChargeStatus
}
const ChargeStatus: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation()
  switch (value) {
    case Enums.ChargeStatus.NotSet:
      return <Tag color="warning">{t('status.notset')}</Tag>
    case Enums.ChargeStatus.Available:
      return <Tag color="success">{t('status.available')}</Tag>
    case Enums.ChargeStatus.Used:
      return <Tag color="default">{t('status.used')}</Tag>
    case Enums.ChargeStatus.Expired:
      return <Tag color="default">{t('status.expired')}</Tag>
    case Enums.ChargeStatus.Canceled:
      return <Tag color="error">{t('status.canceled')}</Tag>
    default:
      return null
  }
}

export default ChargeStatus
