import React from 'react'
import { Language } from 'types/app/admin'
import { useDeleteLanguage } from 'services/query/admin'

import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import LanguagesTable from 'components/languages/LanguagesTable'

const LanguagesTableContainer: React.FC = () => {
  const { t } = useTranslation()
  const editLanguage = useDeleteLanguage()

  const onDeleteLanguage = async (language: Language) => {
    try {
      await editLanguage(language)
      notification.success({ message: t('messagesCrud.languageDeleted') })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.deleteError') })
    }
  }

  return <LanguagesTable onDeleteLanguage={onDeleteLanguage} />
}

LanguagesTableContainer.displayName = 'LanguagesContainer'

export default LanguagesTableContainer
