import React from 'react'

import { Row, Col } from 'antd'
import styles from './styles.module.scss'
import ChargesTableComponent from './ChargesTableComponent'
import { Charge } from 'types/app/charges'
import CurrentBalanceWidgetContainer from 'containers/Dashboard/widgets/CurrentBalanceWidgetContainer'
import MonthlyChargesWidgetContainer from 'containers/Dashboard/widgets/MonthlyChargesWidgetContainer'
import YearlyChargesWidgetContainer from 'containers/Dashboard/widgets/YearlyChargesWidgetContainer'
import ClientServedWidgetContainer from 'containers/Dashboard/widgets/ClientServedWidgetContainer'

interface Props {
  data: Charge[]
  isLoading: boolean
}

const Dashboard: React.FC<Props> = ({ data, isLoading }) => {
  return (
    <section className={styles.dashboard}>
      <section className="app-main-content">
        <Row gutter={[24, 24]}>
          <Col xs={24} lg={8}>
            <CurrentBalanceWidgetContainer />
          </Col>
          <Col xs={24} lg={16}>
            <MonthlyChargesWidgetContainer />
          </Col>
          <Col xs={24} lg={16}>
            <YearlyChargesWidgetContainer />
          </Col>
          <Col xs={24} lg={8}>
            <ClientServedWidgetContainer />
          </Col>
          <Col span={24}>
            <ChargesTableComponent data={data} isLoading={isLoading} />
          </Col>
        </Row>
      </section>
    </section>
  )
}

Dashboard.displayName = 'Dashboard'

export default Dashboard
