import React from 'react'
import routes from 'config/routes'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useLoadMessagesUnread } from 'services/query/messages'

import { BellOutlined, MessageFilled } from '@ant-design/icons'
import { Badge, Dropdown, Menu } from 'antd'

const Notifications: React.FC = () => {
  const { t } = useTranslation()
  const { data: messages } = useLoadMessagesUnread()

  const menu3 = (
    <Menu style={{ width: '200px', fontSize: '20px' }}>
      <Menu.Item key={routes.messages.key} icon={<MessageFilled style={{ fontSize: '20px' }} />}>
        <Link style={{ fontSize: '16px' }} to={routes.messages.url}>
          {t('menu.messagesList')}
        </Link>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu3} trigger={['click']}>
      <Badge count={messages?.unread}>
        <BellOutlined />
      </Badge>
    </Dropdown>
  )
}
Notifications.displayName = 'Notifications'
export default Notifications
