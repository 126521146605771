import { useTheme } from 'providers/ThemeProvider'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { ThemeType } from 'types/enums'

const Seo: React.FC = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  if (theme === ThemeType.Timerpay) {
    return (
      <Helmet>
        <link rel="icon" href="/images/timerpay-icon.png" sizes="32x32" />
        <link rel="icon" href="/images/timerpay-icon.png" sizes="192x192" />
        <link rel="apple-touch-icon" href="/images/timerpay-icon.png" />
        <title>{t('general.headTitlePay')}</title>
        <meta name="description" content={t('general.headTitlePay')} />
      </Helmet>
    )
  }

  return (
    <Helmet>
      <link rel="icon" href="/images/epayrex-icon.png" sizes="32x32" />
      <link rel="icon" href="/images/epayrex-icon.png" sizes="192x192" />
      <link rel="apple-touch-icon" href="/images/epayrex-icon.png" />
      <title>{t('general.headTitle')}</title>
      <meta name="description" content={t('general.headTitle')} />
    </Helmet>
  )
}

export default Seo
