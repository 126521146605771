import React, { useState } from 'react'
import { Button, Col, Input, Card, Row, Select, Form } from 'antd'

import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { UsersFilter } from 'types/app/filters'
import { UserRoleLevel } from 'types/enums'
import FloatingLabel from 'components/FloatingLabel'

interface Props {
  initialFilter: UsersFilter
  onFilter: (filter: UsersFilter) => void
}

const CardPanel: React.FC<Props> = ({ initialFilter, onFilter }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(initialFilter)
  const onResetFilter = () => {
    setFilter(initialFilter)
    onFilter(initialFilter)
  }
  return (
    <Card className={style['card-panel']}>
      <Row gutter={[12, 12]} align="middle">
        <Col xs={24} lg={12}>
          <Row gutter={[12, 12]} align="middle">
            <Col xs={24} md={8}>
              <FloatingLabel value={filter.roleLevel}>
                <Form.Item label={t('user.role')}>
                  <Select
                    defaultValue={-1}
                    onChange={(value) => setFilter({ ...filter, roleLevel: value })}
                    value={filter.roleLevel}
                  >
                    <Select.Option value={-1}>{t('role.any')}</Select.Option>
                    <Select.Option value={UserRoleLevel.Admin}>{t('role.admin')}</Select.Option>
                    <Select.Option value={UserRoleLevel.Affiliate}>{t('role.affiliate')}</Select.Option>
                    <Select.Option value={UserRoleLevel.Agent}>{t('role.agent')}</Select.Option>
                    <Select.Option value={UserRoleLevel.Operator}>{t('role.operator')}</Select.Option>
                    <Select.Option value={UserRoleLevel.SuperAdmin}>{t('role.superAdmin')}</Select.Option>
                  </Select>
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col xs={24} md={8}>
              <FloatingLabel value={filter.surnameName}>
                <Form.Item label={t('user.surnameName')}>
                  <Input
                    className="i-panel"
                    onChange={(e) => setFilter({ ...filter, surnameName: e.target.value })}
                    value={filter.surnameName}
                    onPressEnter={() => onFilter(filter)}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
            <Col xs={24} md={8}>
              <FloatingLabel value={filter.email}>
                <Form.Item label={t('general.email')}>
                  <Input
                    className="i-panel"
                    onChange={(e) => setFilter({ ...filter, email: e.target.value })}
                    value={filter.email}
                    onPressEnter={() => onFilter(filter)}
                  />
                </Form.Item>
              </FloatingLabel>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={8}>
          <Row gutter={[12, 12]} align="middle">
            <Col span={12}>
              <Button type="primary" block onClick={onResetFilter}>
                {t('btn.reset')}
              </Button>
            </Col>
            <Col span={12}>
              <Button type="default" block onClick={() => onFilter(filter)}>
                {t('btn.search')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

CardPanel.displayName = 'CardPanel'

export default CardPanel
