import Debug from 'debug'

import * as Api from 'types/api/invoices'
import { Invoice, InvoiceItem } from 'types/app/invoices'
import { mapNumber, mapString, mapDate } from './common'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'

const debug = Debug('services:map:invoices')

export const mapInvoiceItem = (data: Api.InvoiceItemListData): InvoiceItem => {
  try {
    return {
      description: mapString(data.description),
      amount: mapNumber(data.amount),
      net: mapNumber(data.net),
      commissionPerc: mapNumber(data.commissionPerc),
      commissionAmount: mapNumber(data.commissionAmount),
      vatPerc: mapNumber(data.vatPerc),
      vatAmount: mapNumber(data.vatAmount),
      qnt: mapNumber(data.qnt),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapInvoice = (data: Api.InvoiceListData): Invoice => {
  try {
    return {
      id: mapNumber(data.id),
      nr: mapNumber(data.nr),
      register: mapString(data.register),
      registrationDate: mapDate(data.registrationDate),
      invoiceDate: mapDate(data.invoiceDate),
      totalNet: mapNumber(data.totalNet),
      totalTaxableAmount: mapNumber(data.totalTaxableAmount),
      totalVat: mapNumber(data.totalVat),
      totalAmount: mapNumber(data.totalAmount),
      totalCommissions: mapNumber(data.totalCommissions),
      dueDate: mapDate(data.dueDate),
      idRecipient: mapString(data.idRecipient),
      recipient: mapString(data.recipientUserName),
      collectionDate: mapDate(data.collectionDate),
      collectionUserId: mapString(data.collectionUserId),
      status: mapNumber(data.status),
      lastUpdate: mapDate(data.lastUpdate),
      instanceId: mapNumber(data.instanceId),
      items: (data.items || []).map((item) => mapInvoiceItem(item)),
      recipientAddress: mapString(data.recipientAddress),
      recipientBusinessName: mapString(data.recipientBusinessName),
      recipientCountry: mapString(data.recipientCountry),
      recipientEmail: mapString(data.recipientEmail),
      recipientName: mapString(data.recipientName),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapInvoiceList = (data: Api.InvoiceListData[]): Invoice[] => {
  try {
    return data.map((item) => mapInvoice(item))
  } catch (error) {
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
