import React, { useContext } from 'react'
import { EditFilled, EuroCircleFilled, MailFilled, SettingFilled } from '@ant-design/icons'

import ChangeSmtp from './ChangeSmtp'
import ChangeDetails from './ChangeDetails'
import ChangeSettings from './ChangeSettings'
import ChangeCredits from './ChangeCredits'
import { UserRole } from 'types/enums'
import { Tabs } from 'antd'

import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs

import TopPage from 'components/TopPage'
import routes from 'config/routes'
import { InstanceDetails } from 'types/app/admin'

import style from './styles.module.scss'
import { userContext } from 'core'
interface Props {
  instance: InstanceDetails
  cultures: any
  mode: string
  onSave: (values: Json) => void
  isFetching: boolean
}

const InstanceEdit: React.FC<Props> = ({ instance, cultures, mode, onSave, isFetching }) => {
  const { t } = useTranslation()
  const crumbs = () => [
    { route: routes.home },
    { route: routes.instances },
    { route: routes.instanceDetails, routeParam: { key: 'id', value: instance ? instance?.id : 'new' } },
  ]

  const { role: userRole } = useContext(userContext)
  const tabDetails = () => (
    <TabPane
      tab={
        <span>
          <EditFilled />
          {t('general.details')}
        </span>
      }
      key="details"
    >
      <ChangeDetails instance={instance} cultures={cultures} onSave={onSave} isFetching={isFetching} />
    </TabPane>
  )

  const tabSettings = () => (
    <TabPane
      tab={
        <span>
          <SettingFilled />
          {t('general.settings')}
        </span>
      }
      key="settings"
    >
      <ChangeSettings instance={instance} onSave={onSave} isFetching={isFetching} />
    </TabPane>
  )

  const tabSmtp = () => (
    <TabPane
      tab={
        <span>
          <MailFilled />
          {t('general.smtp')}
        </span>
      }
      key="smtp"
    >
      <ChangeSmtp instance={instance} onSave={onSave} isFetching={isFetching} />
    </TabPane>
  )

  const tabCredit = () => (
    <TabPane
      tab={
        <span>
          <EuroCircleFilled />
          {t('general.credit')}
        </span>
      }
      key="credit"
    >
      <ChangeCredits instance={instance} onSave={onSave} isFetching={isFetching} />
    </TabPane>
  )

  return (
    <section className={style['instance-edit']}>
      <TopPage title={t('instance.detailInstance')} crumbs={crumbs()} />
      <Tabs className="mt-20" defaultActiveKey={mode}>
        {tabDetails()}
        {tabSettings()}
        {tabSmtp()}
        {userRole === UserRole.SuperAdmin && tabCredit()}
      </Tabs>
    </section>
  )
}

InstanceEdit.displayName = 'InstanceEdit'

export default InstanceEdit
