import React, { useContext, useState } from 'react'
import { Button, Col, Card, Row, Select, Form } from 'antd'

import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { ReportFilter } from 'types/app/filters'
import { useLoadAgents } from 'services/query/users'
import format from 'dayjs'
import { DatePicker } from 'components/antd-dayjs'
import FloatingLabel from 'components/FloatingLabel'
import { UserRole, UserRoleLevel } from 'types/enums'
import { selectUserSettings } from 'store/user/selectors'
import { useSelector } from 'react-redux'
import { userContext } from 'core'

interface Props {
  initialFilter: ReportFilter
  onFilter: (filter: ReportFilter) => void
}

const FilterPanel: React.FC<Props> = ({ initialFilter, onFilter }) => {
  const { t } = useTranslation()
  const userSettings = useSelector(selectUserSettings)
  const { role: userRole } = useContext(userContext)
  const [filter, setFilter] = useState(initialFilter)

  const { instanceId: currentUserInstanceId } = useContext(userContext)
  const shouldShowAgentFilter =
    [UserRole.SuperAdmin, UserRole.Admin, UserRole.Operator].indexOf(userRole) >= 0
  const queryParams = shouldShowAgentFilter
    ? {
        instanceId: userSettings?.selectedInstance?.id || currentUserInstanceId,
        pageSize: 100,
        page: 0,
        roleLevel: UserRoleLevel.Agent,
        email: '',
        surnameName: '',
        orderBy: 'Name',
        orderByDirection: 1,
      }
    : null
  const { data: agents, isFetching: isAgentsFetching } = useLoadAgents(queryParams)

  const onResetFilter = () => {
    setFilter(initialFilter)
    onFilter(initialFilter)
  }

  if (isAgentsFetching) return null

  return (
    <Card className={`${style['card-panel']} not-printable`}>
      <Form>
        <Row gutter={[12, 12]} align="middle">
          <Col xs={24} md={4}>
            <FloatingLabel value={filter.dateFrom && format(filter.dateFrom)}>
              <Form.Item label={t('general.dateFromPlaceholder')}>
                <DatePicker
                  placeholder={null}
                  format="DD MM YYYY"
                  value={filter.dateFrom && format(filter.dateFrom)}
                  onChange={(value) => setFilter({ ...filter, dateFrom: value.toDate() })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col xs={24} md={4}>
            <FloatingLabel value={filter.dateTo && format(filter.dateTo)}>
              <Form.Item label={t('general.dateToPlaceholder')}>
                <DatePicker
                  placeholder={null}
                  format="DD MM YYYY"
                  value={filter.dateTo && format(filter.dateTo)}
                  onChange={(value) => setFilter({ ...filter, dateTo: value.toDate() })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>

          {shouldShowAgentFilter && (
            <Col xs={24} md={4}>
              <FloatingLabel value={filter.agentId}>
                <Form.Item label={t('role.agent')}>
                  <Select
                    onChange={(value) => setFilter({ ...filter, agentId: value })}
                    value={filter?.agentId}
                  >
                    {agents.data.map((agent) => (
                      <Select.Option key={agent.id} value={agent.id}>
                        {agent.name} {agent.surname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </FloatingLabel>
            </Col>
          )}
          <Col xs={24} lg={8}>
            <Row gutter={[12, 12]} align="middle">
              <Col span={12}>
                <Button type="primary" block onClick={onResetFilter}>
                  {t('btn.reset')}
                </Button>
              </Col>
              <Col span={12}>
                <Button type="default" block onClick={() => onFilter(filter)}>
                  {t('btn.execute')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

FilterPanel.displayName = 'FilterPanel'

export default FilterPanel
