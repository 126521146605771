import React, { useState } from 'react'
import UsersTable from 'components/users/UsersTable'
import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE } from 'core'
import { ListUsersRequest } from 'types/api/users'
import { useDeleteUser, useLoadUserList } from 'services/query/users'
import { UsersFilter } from 'types/app/filters'

const UsersTableContainer: React.FC = () => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(TABLE_PAGINATION_DEFAULT_PAGE_SIZE)
  const [sorterField, setSorterField] = useState('name')
  const [sorterDirection, setSorterDirection] = useState('ascend')
  const initialFilter: UsersFilter = {
    surnameName: '',
    email: '',
    roleLevel: -1,
  }
  const [filter, setFilter] = useState(initialFilter)

  const queryParams: ListUsersRequest = {
    page,
    pageSize,
    roleLevel: filter.roleLevel,
    surnameName: filter.surnameName,
    email: filter.email,
    orderBy: sorterField || 'name',
    orderByDirection: sorterDirection === 'ascend' ? 1 : -1,
  }

  const { data: users, refetch, isFetching } = useLoadUserList(queryParams)
  const dataLoading: boolean = isFetching
  const deleteUser = useDeleteUser()

  const handleChange = (pagination, filters, sorter) => {
    if (pagination && pagination.current) {
      setPage(pagination.current - 1)
      setPageSize(pagination.pageSize)
    }

    setSorterField(sorter.field)

    if (sorter.hasOwnProperty('column')) {
      setSorterField(sorter.field)
      setSorterDirection(sorter.order)
    }
  }

  const handleFiltering = ({ roleLevel, email, surnameName }) => {
    setPage(0)
    setSorterField('name')
    setSorterDirection('ascend')
    setFilter({ roleLevel, email, surnameName })
  }

  const handleDelete = async (userId: string) => {
    await deleteUser(userId)
    await refetch()
  }

  return (
    <UsersTable
      initialFilter={initialFilter}
      isLoading={dataLoading}
      handleChange={handleChange}
      handleFiltering={handleFiltering}
      handleDelete={handleDelete}
      dataSource={users}
    ></UsersTable>
  )
}

UsersTableContainer.displayName = 'UsersTableContainer'

export default UsersTableContainer
