import React, { useContext } from 'react'
import * as Api from 'types/api/widgets'
import { useGetClientServedWidget } from 'services/query/widgets'
import { useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'
import { userContext } from 'core'
import { UserRole } from 'types/enums'
import ClientServedWidget from 'components/Dashboard/ClientServedComponent'

const ClientServedWidgetContainer: React.FC = () => {
  const userSettings = useSelector(selectUserSettings)
  const { role: userRole } = useContext(userContext)
  const searchInstanceId = userRole === UserRole.SuperAdmin ? userSettings?.selectedInstance?.id || null : -1

  const queryParams: Api.GetWidgetRequest = {
    instanceId: searchInstanceId,
  }

  const { data, isFetched, isLoading } = useGetClientServedWidget(queryParams)

  return <ClientServedWidget data={data} isLoading={isLoading || !isFetched} />
}

ClientServedWidgetContainer.displayName = 'ClientServedWidgetContainer'

export default ClientServedWidgetContainer
