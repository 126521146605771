export enum RequestStatus {
  None = 0,
  Requesting = 1,
  Success = 2,
  Error = 3,
}

export enum ErrorCode {
  Unknown = 0,
  Api = 1,
  Parameters = 3,
  PageUndefined = 4,
  GraphQlApi = 11,
  BootstrapFailed = 101,
  Login = 102,
  Register = 103,
  RegisterCodeNotFound = 104,
  RegisterInvalidPassword = 105,
  RegisterEmailTaken = 106,
  UserAlreadyRegistered = 107,
  AuthenticationFailed = 108,
  ResetPasswordInvalidCode = 121,
  GenericError = 999,
}

export enum UserOperation {
  Login = 'login',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
}

export enum LoadingState {
  Unset = 'unset',
  Loading = 'loading',
  Loaded = 'loaded',
  LoadFailure = 'load failure',
  Deleting = 'deleting',
  Deleted = 'deleted',
  DeleteFailed = 'deleting failed',
  Creating = 'creating',
  Created = 'created',
  CreateFailure = 'created failure',
  Updating = 'updating',
  Updated = 'updated',
  UpdateFailure = 'update failure',
}

export enum UserRole {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Operator = 'Operator',
  Agent = 'Agent',
  Affiliate = 'Affiliate',
}

export enum UserRoleLevel {
  SuperAdmin = 0,
  Admin = 1,
  Operator = 2,
  Agent = 3,
  Affiliate = 4,
}

export enum UserStatus {
  Active = 0,
  Blocked = 1,
  BlockedByInvoicePayTerm = 2,
}

export enum SmsAnswerStatus {
  NotSet = 0,
  SmsSuccess = 1,
  SmsError = 2,
}

export enum ChargeStatus {
  NotSet = 0,
  Available = 3,
  Used = 4,
  Canceled = 5,
  Expired = 6,
}

export enum ChargeInvoiceStatus {
  NotInvoiced = 0,
  Invoiced = 1,
}

export enum InvoiceStatus {
  Created = 0,
  Paid = 1,
  Deleted = 2,
}

export enum Operation {
  Create = 'CREATE',
  Read = 'READ',
  Update = 'UPDATE',
  Delete = 'DELETE',
}

export enum InvoiceViewMode {
  Create,
  Pay = 1,
  Details = 2,
}

export enum MessageType {
  Info = 1,
  Warning = 2,
  HelpDesk = 3,
}

export enum TransactionType {
  NewCharge = 0,
  NewCreditLimit = 1,
  NewInvoice = 2,
  InvoiceCollection = 3,
}

export enum ThemeType {
  Epayrex = 'epayrex',
  Timerpay = 'timerpay',
}
