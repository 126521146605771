import CountriesContainer from 'containers/Countries'
import React from 'react'

const CountriesPage: React.FC = () => {
  return <CountriesContainer />
}

CountriesPage.displayName = 'CountriesPage'

export default CountriesPage
