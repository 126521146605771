import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import InvoiceView from 'components/invoices/InvoiceView'
import { useGetInvoiceDetails } from 'services/query/invoices'
import { InvoiceViewMode } from 'types/enums'
import { useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'
import { userContext } from 'core'

const InvoicePayContainer: React.FC = () => {
  const { id } = useParams()
  const userSettings = useSelector(selectUserSettings)
  const { instanceId: currentUserInstanceId } = useContext(userContext)
  const { data: invoice, isFetching } = useGetInvoiceDetails(
    id,
    userSettings?.selectedInstance?.id || currentUserInstanceId,
  )

  if (isFetching) return <section className="app-main-content" />

  return <InvoiceView invoice={invoice} mode={InvoiceViewMode.Details} />
}

InvoicePayContainer.displayName = 'InvoicePayContainer'
export default InvoicePayContainer
