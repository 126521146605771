import React, { useState, useContext } from 'react'
import routes from 'config/routes'
import { useSelector } from 'react-redux'
import { generatePath, useParams, useNavigate } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import InvoiceView from 'components/invoices/InvoiceView'
import { useGetInvoiceDetails, usePayInvoice } from 'services/query/invoices'
import { InvoiceViewMode } from 'types/enums'
import { selectUserSettings } from 'store/user/selectors'
import { userContext } from 'core'
import { notification } from 'antd'

const InvoicePayContainer: React.FC = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { instanceId: currentUserInstanceId } = useContext(userContext)
  const userSettings = useSelector(selectUserSettings)
  const { data: invoice, isFetching } = useGetInvoiceDetails(
    id,
    userSettings?.selectedInstance?.id || currentUserInstanceId,
  )
  const payInvoice = usePayInvoice(id, userSettings?.selectedInstance?.id || currentUserInstanceId)
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (isFetching) return null

  const onPayInvoice = async () => {
    try {
      setIsSubmitting(true)
      await payInvoice()
      notification.success({ message: t('messagesCrud.invoicePaid') })
      navigate(generatePath(routes.invoices.url), { replace: true })
    } catch (error) {
      console.error(error)
      notification.error({ message: `${t('messagesCrud.saveError')} (${error.data})` })
      setIsSubmitting(false)
    }
    setIsSubmitting(false)
  }

  return (
    <InvoiceView
      invoice={invoice}
      mode={InvoiceViewMode.Pay}
      onSubmit={onPayInvoice}
      isSubmitting={isSubmitting}
    />
  )
}

InvoicePayContainer.displayName = 'InvoicePayContainer'
export default InvoicePayContainer
