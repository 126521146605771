import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as queryKeys from 'config/queryKeys'
import {
  createInstance,
  deleteInstance,
  editInstance,
  getInstanceDetails,
  listCountries,
  getCountryDetails,
  listCurrencies,
  deleteCurrency,
  createCurrency,
  editCurrency,
  getCurrencyDetails,
  listCustomers,
  listCultures,
  listInstances,
  listLanguages,
  getLanguageDetails,
  editLanguage,
  deleteLanguage,
  createLanguage,
  createCountry,
  editCountry,
  deleteCountry,
  createCustomer,
  getCustomerDetails,
  editCustomer,
  deleteCustomer,
  searchCustomers,
  searchInstances,
  getUserDefaultsCreditLimits,
  createTranslation,
  editTranslation,
} from 'api/admin'
import {
  mapCountry,
  mapCountryList,
  mapCreateEditFromCustomer,
  mapCreateEditFromInstance,
  mapCreditLimits,
  mapCurrency,
  mapCurrencyList,
  mapCurrencyToApi,
  mapCustomerDetails,
  mapCustomerList,
  mapInstanceDetails,
  mapInstanceList,
  mapLanguage,
  mapLanguageList,
} from 'services/map/admin'

import {
  InstanceCreateEditRequest,
  SearchInstancesRequest,
  CurrencyListData,
  CustomerCreateEditRequest,
  SearchCustomersRequest,
  UserDefaultCreditLimitsRequest,
} from 'types/api/admin'
import { InstanceDetails, Currency, Language, Country, CustomerDetails } from 'types/app/admin'
import { Translation } from 'types/app/translations'

export function useLoadCountryList() {
  const queryFunction = async () => {
    const response = await listCountries()
    return mapCountryList(response)
  }
  return useQuery(queryKeys.countryList(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadCountry(countryId: string) {
  const queryFunction = async () => {
    const response = await getCountryDetails(countryId)
    return mapCountry(response)
  }
  return useQuery(queryKeys.countryDetails(parseInt(countryId)), queryFunction, {
    placeholderData: null,
  })
}

//Currencies
export function useLoadCurrencyList() {
  const queryFunction = async () => {
    const response = await listCurrencies()
    return mapCurrencyList(response)
  }
  return useQuery(queryKeys.currencyList(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadCurrency(id: string) {
  const queryFunction = async () => {
    if (id.toUpperCase() === 'NEW') return {}
    const response = await getCurrencyDetails(id)
    return mapCurrency(response)
  }

  return useQuery(queryKeys.currencyDetails(id), queryFunction, {
    placeholderData: null,
  })
}

export function useDeleteCurrency() {
  const deleteFunction = async (currency: Currency) => {
    const result = await deleteCurrency(currency.id)
    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.currencyList())
    },
  })
  return mutateAsync
}

export function useCreateCurrency() {
  const mutateFunction = async (data: Currency) => {
    const apiData = mapCurrencyToApi(data)
    const newItem = await createCurrency(apiData)
    return newItem
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (inserted) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.currencyDetails(inserted.id)),
        queryClient.invalidateQueries(queryKeys.currencyList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditCurrency() {
  const mutateFunction = async (data: Currency) => {
    const item = mapCurrencyToApi(data)
    await editCurrency(data.id, item)
    return item as CurrencyListData
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (item) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.currencyDetails(item.id)),
        queryClient.invalidateQueries(queryKeys.currencyList()),
      ])
    },
  })
  return mutateAsync
}

// Cultures
export function useLoadCultureList() {
  const queryFunction = async () => {
    const response = await listCultures()
    return response
  }
  return useQuery(queryKeys.culturesList(), queryFunction, {
    placeholderData: [],
  })
}

// INSTANCES
export function useLoadInstanceList() {
  const queryFunction = async () => {
    const response = await listInstances()
    return mapInstanceList(response)
  }
  return useQuery(queryKeys.instancesList(), queryFunction, {
    placeholderData: [],
  })
}

export function useSearchInstances(queryParams: SearchInstancesRequest) {
  const queryFunction = async () => {
    const response = await searchInstances(queryParams)

    return { data: mapInstanceList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.instancesSearch(queryParams), queryFunction, {
    placeholderData: { data: [], total: 0 },
    keepPreviousData: true,
  })
}

export function useLoadInstanceDetails(instanceId: string) {
  const queryFunction = async () => {
    if (instanceId.toUpperCase() === 'NEW') return mapInstanceDetails({})
    const response = await getInstanceDetails(instanceId)
    const mapped = mapInstanceDetails(response)
    return mapped
  }
  return useQuery(queryKeys.instanceDetails(instanceId), queryFunction, {
    placeholderData: null,
  })
}

export function useLoadUserDefaultCreditLimits(queryParams: UserDefaultCreditLimitsRequest) {
  const queryFunction = async () => {
    const response = await getUserDefaultsCreditLimits(queryParams)
    const mapped = mapCreditLimits(response)
    return mapped
  }
  return useQuery(queryKeys.userDefaultCreditLimits(queryParams), queryFunction, {
    placeholderData: null,
    enabled: !!queryParams.agentId || !!queryParams.instanceId,
  })
}

export function useCreateInstance() {
  const mutateFunction = async (instance: InstanceDetails) => {
    const mappedData: InstanceCreateEditRequest = mapCreateEditFromInstance(instance)
    const newInstance = await createInstance(mappedData)
    return newInstance
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (insertedInstance) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.instanceDetails(insertedInstance.id.toString())),
        queryClient.invalidateQueries(queryKeys.instancesList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditInstance() {
  const mutateFunction = async (instance: InstanceDetails) => {
    const mappedData: InstanceCreateEditRequest = mapCreateEditFromInstance(instance)
    await editInstance(instance.id.toString(), mappedData)
    return instance as InstanceDetails
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (instance) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.instanceDetails(instance.id.toString())),
        queryClient.invalidateQueries(queryKeys.instancesList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteInstance() {
  const deleteFunction = async (instanceId: string) => {
    const result = await deleteInstance(instanceId)
    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.instancesList())
    },
  })
  return mutateAsync
}

// CUSTOMERS

export function useLoadCustomerList() {
  const queryFunction = async () => {
    const response = await listCustomers()
    return mapCustomerList(response)
  }
  return useQuery(queryKeys.customersList(), queryFunction, {
    placeholderData: [],
  })
}

export function useSearchCustomers(queryParams: SearchCustomersRequest) {
  const queryFunction = async () => {
    const response = await searchCustomers(queryParams)
    return { data: mapCustomerList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.customersSearch(queryParams), queryFunction, {
    placeholderData: { data: [], total: 0 },
    keepPreviousData: true,
  })
}

export function useLoadCustomerDetails(customerId: string) {
  const queryFunction = async () => {
    if (customerId.toUpperCase() === 'NEW') return mapCustomerDetails({})
    const response = await getCustomerDetails(customerId)
    const mapped = mapCustomerDetails(response)
    return mapped
  }
  return useQuery(queryKeys.customerDetails(customerId), queryFunction, {
    placeholderData: null,
  })
}

export function useCreateCustomer() {
  const mutateFunction = async (customer: CustomerDetails) => {
    const mappedData: CustomerCreateEditRequest = mapCreateEditFromCustomer(customer)
    const newCustomer = await createCustomer(mappedData)
    return newCustomer
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (inserted) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.customerDetails(inserted.id.toString())),
        queryClient.invalidateQueries(queryKeys.customersList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditCustomer() {
  const mutateFunction = async (customer: CustomerDetails) => {
    const mappedData: CustomerCreateEditRequest = mapCreateEditFromCustomer(customer)
    await editCustomer(customer.id.toString(), mappedData)
    return customer as CustomerDetails
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (customer) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.customerDetails(customer.id.toString())),
        queryClient.invalidateQueries(queryKeys.customersList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteCustomer() {
  const deleteFunction = async (customerId: string) => {
    const result = await deleteCustomer(customerId)
    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.customersList())
    },
  })
  return mutateAsync
}

// languages

export function useLoadLanguageList() {
  const queryFunction = async () => {
    const response = await listLanguages()
    return mapLanguageList(response)
  }
  return useQuery(queryKeys.languageList(), queryFunction, {
    placeholderData: [],
  })
}

export function useLoadLnguage(languageId: number) {
  const queryFunction = async () => {
    const response = await getLanguageDetails(languageId)
    return mapLanguage(response)
  }

  return useQuery(queryKeys.languageDetails(languageId), queryFunction, {
    placeholderData: null,
  })
}

export function useEditLanguage() {
  const mutateFunction = async (language: Language) => {
    await editLanguage(language.id, { id: language.id, name: language.name, iso: language.iso })
    return language as Language
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (language) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.languageDetails(language.id)),
        queryClient.invalidateQueries(queryKeys.languageList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteLanguage() {
  const mutateFunction = async (language: Language) => {
    await deleteLanguage(language.id)
    return language as Language
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (language) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.languageDetails(language.id)),
        queryClient.invalidateQueries(queryKeys.languageList()),
      ])
    },
  })
  return mutateAsync
}

export function useCreateLanguage() {
  const mutateFunction = async (language: Language) => {
    await createLanguage({ name: language.name, iso: language.iso })
    return language as Language
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (language) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.languageDetails(language.id)),
        queryClient.invalidateQueries(queryKeys.languageList()),
      ])
    },
  })
  return mutateAsync
}

export function useDeleteCountry() {
  const deleteFunction = async (countryId: string) => {
    const result = await deleteCountry(countryId)
    return result
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(deleteFunction, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.countryList())
    },
  })
  return mutateAsync
}

export function useEditCountry() {
  const mutateFunction = async (country: Country) => {
    await editCountry(country.id, { id: country.id, name: country.name, iso: country.iso, vat: country.vat })
    return country as Country
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (country) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.countryDetails(country.id)),
        queryClient.invalidateQueries(queryKeys.countryList()),
      ])
    },
  })
  return mutateAsync
}

export function useCreateCountry() {
  const mutateFunction = async (country: Country) => {
    await createCountry({ id: country.id, name: country.name, iso: country.iso, vat: country.vat })
    return country as Country
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (country) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.countryDetails(country.id)),
        queryClient.invalidateQueries(queryKeys.countryList()),
      ])
    },
  })
  return mutateAsync
}

export function useCreateTranslation() {
  const mutateFunction = async (translation: Translation) => {
    await createTranslation({
      labelTag: translation.labelTag,
      defaultTranslation: translation.defaultTranslation,
      translations: translation.translations,
    })
    return translation as Translation
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (translation) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.translationDetails(translation.labelTag)),
        queryClient.invalidateQueries(queryKeys.translationList()),
      ])
    },
  })
  return mutateAsync
}

export function useEditTranslation(oldTag: string) {
  const mutateFunction = async (translation: Translation) => {
    await editTranslation(oldTag, {
      labelTag: translation.labelTag,
      defaultTranslation: translation.defaultTranslation,
      translations: translation.translations,
    })
    return translation as Translation
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (translation) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.translationDetails(translation.labelTag)),
        queryClient.invalidateQueries(queryKeys.translationList()),
      ])
    },
  })
  return mutateAsync
}
