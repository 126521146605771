import React from 'react'
import { EditOutlined } from '@ant-design/icons'

import { Tabs } from 'antd'

import { useTranslation } from 'react-i18next'

const { TabPane } = Tabs
import style from './styles.module.scss'

import ChangeDetails from './ChangeDetails'

import TopPage from 'components/TopPage'
import routes from 'config/routes'
import { CustomerDetails } from 'types/app/admin'

interface Props {
  customer: CustomerDetails
  mode: string
  onSave: (values: Json) => void
}

const CustomerEdit: React.FC<Props> = ({ customer, mode, onSave }) => {
  const { t } = useTranslation()
  const crumbs = () => [
    { route: routes.home },
    { route: routes.customers },
    { route: routes.customerDetails, routeParam: { key: 'id', value: customer.id } },
  ]
  const tabDetails = () => (
    <TabPane
      tab={
        <span>
          <EditOutlined />
          {t('general.details')}
        </span>
      }
      key="details"
    >
      <ChangeDetails customer={customer} onSave={onSave} />
    </TabPane>
  )

  return (
    <section className={style['customer-edit']}>
      <TopPage title={t('menu.customerDetails')} crumbs={crumbs()} />
      <Tabs className="mt-20" defaultActiveKey={mode}>
        {tabDetails()}
      </Tabs>
    </section>
  )
}

CustomerEdit.displayName = 'CustomerEdit'

export default CustomerEdit
