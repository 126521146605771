import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActiveBalance } from 'types/app/charges'

import FloatingLabel from 'components/FloatingLabel'

import { Button, Col, Form, InputNumber, Row } from 'antd'
import style from './styles.module.scss'

interface Props {
  setAmount: (value: number) => void
  amount: number
  balance: ActiveBalance
}

const AmountCharge: React.FC<Props> = ({ setAmount, amount, balance }) => {
  const { t } = useTranslation()

  const onSuggestedClick = (e) => {
    if (!e) {
      setAmount(0)
    } else {
      setAmount(e.target.value)
    }
  }

  const onValueChanged = (value) => {
    if (!value) {
      setAmount(0)
    } else {
      setAmount(value)
    }
  }

  const suggestedValues = [5, 10, 20, 30, 50]

  return (
    <section className={style['amount-charge']}>
      <Form>
        <Row className="mb-20" gutter={[12, 12]}>
          {suggestedValues.map((val) => (
            <Col key={val} flex="0 0 70px">
              <Button
                block
                value={val}
                type="default"
                onClick={onSuggestedClick}
                disabled={val > balance.activeBalance}
                style={{ fontWeight: 700 }}
              >
                {val}
              </Button>
            </Col>
          ))}
        </Row>
        <Row>
          <Col span={24}>
            <FloatingLabel value={amount}>
              <Form.Item
                label={t('charge.amount')}
                rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
              >
                <InputNumber
                  min={1}
                  max={balance.activeBalance}
                  defaultValue={balance.activeBalance}
                  value={amount}
                  onChange={onValueChanged}
                  prefix={balance.currencySymbol}
                  type="number"
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
        </Row>
      </Form>
    </section>
  )
}

AmountCharge.displayName = 'AmountCharge'

export default AmountCharge
