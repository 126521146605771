import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { formatTwoDecimals } from 'core/helpers'
import TableComponent from 'components/Table'

interface Props {
  dataSource?: any
  onSubmit?: () => void
}

const InvoiceItemsTable: React.FC<Props> = ({ dataSource }) => {
  const { t } = useTranslation()
  const columns = [
    {
      key: 'index',
      title: t('general.nr'),
      fixed: true,
      width: '10%',
      render: (_1, _2, index) => index + 1,
    },
    {
      key: 'registrationDate',
      title: t('general.registrationDate'),
      dataIndex: 'registrationDate',
      width: '30%',
      render: (date: Date) => {
        return dayjs(date).toISOString()
      },
    },
    {
      key: 'amount',
      title: t('general.amount'),
      dataIndex: 'amount',
      width: '30%',
      render: (value) => formatTwoDecimals(value),
    },
    {
      key: 'commission',
      title: t('user.commission'),
      dataIndex: 'commissionAmount',
      width: '30%',
      render: (value) => formatTwoDecimals(value),
    },
    {
      key: 'net',
      title: t('general.net'),
      dataIndex: 'net',
      width: '15%',
      render: (value) => formatTwoDecimals(value),
    },
    {
      key: 'tax',
      title: t('general.tax'),
      dataIndex: 'vatAmount',
      width: '15%',
      render: (value) => formatTwoDecimals(value),
    },
  ]

  return <TableComponent columns={columns} dataSource={dataSource} />
}

InvoiceItemsTable.displayName = 'ChargesInvoiceTable'
export default InvoiceItemsTable
