import React, { useState } from 'react'
import { Button, Col, Input, Card, Row, Select, Form } from 'antd'

import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { ChargesFilter } from 'types/app/filters'
import * as Enums from 'types/enums'
import format from 'dayjs'
import FloatingLabel from 'components/FloatingLabel/FloatingLabel'

import { DatePicker } from 'components/antd-dayjs'

interface Props {
  initialFilter: ChargesFilter
  onFilter: (filter: ChargesFilter) => void
  setNumber: (number: string) => void
}

const FilterPanel: React.FC<Props> = ({ initialFilter, onFilter, setNumber }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState(initialFilter)

  const smsAnswerStatuses = [
    {
      key: Enums.SmsAnswerStatus.NotSet,
      value: t('status.notset'),
    },
    {
      key: Enums.SmsAnswerStatus.SmsSuccess,
      value: t('status.success'),
    },
    {
      key: Enums.SmsAnswerStatus.SmsError,
      value: t('status.failed'),
    },
  ]

  const chargeStatuses = [
    {
      key: Enums.ChargeStatus.NotSet,
      value: t('status.notset'),
    },
    {
      key: Enums.ChargeStatus.Available,
      value: t('status.available'),
    },
    {
      key: Enums.ChargeStatus.Used,
      value: t('status.used'),
    },
  ]

  const invoicedStatuses = [
    {
      key: -1,
      value: t('general.any'),
    },
    {
      key: Enums.ChargeInvoiceStatus.Invoiced,
      value: t('status.invoiced'),
    },
    {
      key: Enums.ChargeInvoiceStatus.NotInvoiced,
      value: t('status.notInvoiced'),
    },
  ]

  const onResetFilter = () => {
    setFilter(initialFilter)
    onFilter(initialFilter)
    setNumber('')
  }

  const mapStringToNumber = (value: string) => {
    try {
      return parseInt(value)
    } catch (e) {
      return null
    }
  }

  return (
    <Card className={style['card-panel']}>
      <Form>
        <Row gutter={[12, 12]} align="middle">
          <Col xs={24} lg={24}>
            <Row gutter={[12, 12]} align="middle">
              <Col xs={24} md={4}>
                <FloatingLabel value={filter.dateFrom && format(filter.dateFrom)}>
                  <Form.Item label={t('general.dateFromPlaceholder')}>
                    <DatePicker
                      placeholder={null}
                      format="DD MM YYYY"
                      value={filter.dateFrom && format(filter.dateFrom)}
                      onChange={(value) => setFilter({ ...filter, dateFrom: value.toDate() })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col xs={24} md={4}>
                <FloatingLabel value={filter.dateTo && format(filter.dateTo)}>
                  <Form.Item label={t('general.dateToPlaceholder')}>
                    <DatePicker
                      placeholder={null}
                      format="DD MM YYYY"
                      value={filter.dateTo && format(filter.dateTo)}
                      onChange={(value) => setFilter({ ...filter, dateTo: value.toDate() })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col xs={24} md={4}>
                <FloatingLabel value={filter.code}>
                  <Form.Item label={t('general.code')}>
                    <Input
                      value={filter.code}
                      onChange={(e) => setFilter({ ...filter, code: e.target.value })}
                      onPressEnter={() => onFilter(filter)}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col xs={24} md={4}>
                <FloatingLabel value={filter.mobilePhone}>
                  <Form.Item label={t('charge.number')}>
                    <Input
                      value={filter.mobilePhone}
                      onChange={(e) => setFilter({ ...filter, mobilePhone: e.target.value })}
                      onPressEnter={() => onFilter(filter)}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col xs={24} md={4}>
                <FloatingLabel value={filter.smsAnswerStatus}>
                  <Form.Item label={t('status.smsState')}>
                    <Select
                      value={filter.smsAnswerStatus}
                      onChange={(value) => setFilter({ ...filter, smsAnswerStatus: value })}
                    >
                      {smsAnswerStatuses.map(({ key, value }) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col xs={24} md={4}>
                <FloatingLabel value={filter.status}>
                  <Form.Item label={t('status.state')}>
                    <Select
                      onChange={(value) => setFilter({ ...filter, status: value })}
                      value={filter.status}
                    >
                      {chargeStatuses.map(({ key, value }) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 12]} align="bottom" className="mt-30">
          <Col xs={24} lg={24}>
            <Row gutter={[12, 12]} align="middle">
              <Col xs={24} md={4}>
                <FloatingLabel value={filter.invoiceStatus}>
                  <Form.Item label={t('general.invoiceStatus')}>
                    <Select
                      value={filter.invoiceStatus}
                      onChange={(value) => setFilter({ ...filter, invoiceStatus: value })}
                    >
                      {invoicedStatuses.map(({ key, value }) => (
                        <Select.Option key={key} value={key}>
                          {value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col xs={24} md={4}>
                <FloatingLabel value={filter.invoiceId}>
                  <Form.Item label={t('general.invoiceId')}>
                    <Input
                      type="number"
                      value={filter.invoiceId}
                      onChange={(e) => setFilter({ ...filter, invoiceId: mapStringToNumber(e.target.value) })}
                      onPressEnter={() => onFilter(filter)}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              {/* <Col xs={24} md={4}>
                <FloatingLabel value={filter.amountMin}>
                  <Form.Item label={t('charge.amountMin')}>
                    <Input
                      type="number"
                      value={filter.amountMin}
                      onChange={(e) => setFilter({ ...filter, amountMin: mapStringToNumber(e.target.value) })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col>
              <Col xs={24} md={4}>
                <FloatingLabel value={filter.amountMax}>
                  <Form.Item label={t('charge.amountMax')}>
                    <Input
                      type="number"
                      value={filter.amountMax}
                      onChange={(e) => setFilter({ ...filter, amountMax: mapStringToNumber(e.target.value) })}
                    />
                  </Form.Item>
                </FloatingLabel>
              </Col> */}
              <Col xs={24} lg={8}>
                <Row gutter={[12, 12]} align="middle">
                  <Col span={12}>
                    <Button type="primary" block onClick={onResetFilter}>
                      {t('btn.reset')}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button type="default" block onClick={() => onFilter(filter)}>
                      {t('btn.search')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}

FilterPanel.displayName = 'CardPanel'

export default FilterPanel
