import { FileTextOutlined, MailOutlined } from '@ant-design/icons'
import { Button, Card, Col, Form, Input, Row, Spin } from 'antd'
import FloatingLabel from 'components/FloatingLabel'
import { checkSubmitButtonBeDisabled } from 'core/helpers'
import TopPage from 'components/TopPage'
import routes from 'config/routes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './styles.module.scss'
import { Message } from 'types/app/messages'

interface Props {
  onSendMessage?: (newMessage) => void
  spin: boolean
}

const Support: React.FC<Props> = ({ onSendMessage, spin }) => {
  const { t } = useTranslation()
  const [formData, setFormData] = React.useState({
    subject: null,
    body: null,
  })
  const crumbs = () => [{ route: routes.home }, { route: routes.support }]
  const requiredFields = ['subject', 'body']
  const [form] = Form.useForm()
  const onFinish = (model: Message) => {
    onSendMessage(model)
    form.resetFields()
  }

  return (
    <section className={style['support']}>
      <TopPage title={t('menu.support')} crumbs={crumbs()}></TopPage>
      <section className="app-main-content">
        <Card className="mt-16">
          {spin && (
            <Row align="middle" justify="center" style={{ height: '200px' }}>
              <Spin size="large" />
            </Row>
          )}
          {!spin && (
            <Form form={form} className="mt-40" onFinish={onFinish}>
              <Row justify="center" align="middle">
                <MailOutlined className="mr-20" />
                <Col md={16} xs={24}>
                  <FloatingLabel value={formData.subject}>
                    <Form.Item
                      style={{ width: '50%' }}
                      label={t('message.subject')}
                      name="subject"
                      rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                    >
                      <Input
                        value={formData.subject}
                        onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
                      />
                    </Form.Item>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row justify="center" align="middle" className="mt-50">
                <FileTextOutlined className="mr-20" />
                <Col md={16} xs={24}>
                  <FloatingLabel value={formData.body}>
                    <Form.Item
                      label={t('message.body')}
                      name="body"
                      rules={[{ required: true, message: t('validationForm.fieldRequired') }]}
                    >
                      <Input.TextArea
                        value={formData.body}
                        onChange={(e) => setFormData({ ...formData, body: e.target.value })}
                      />
                    </Form.Item>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mt-40">
                <Col xs={24} lg={{ span: 12, offset: 12 }}>
                  <Row justify="end" gutter={[12, 12]}>
                    <Col xs={12} lg={6}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={checkSubmitButtonBeDisabled(form, requiredFields)}
                      >
                        {t('btn.send')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          )}
        </Card>
      </section>
    </section>
  )
}

Support.displayName = 'Support'

export default Support
