import InstancesTableContainer from 'containers/instances/InstancesTable'
import React from 'react'

const InstancesPage: React.FC = () => {
  return <InstancesTableContainer />
}

InstancesPage.displayName = 'InstancesPage'

export default InstancesPage
