import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Enums from 'types/enums'
import { Tooltip } from 'antd'

interface Props {
  value: Enums.TransactionType
}
const TransactionType: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation()
  switch (value) {
    case Enums.TransactionType.NewCharge:
      return (
        <Tooltip title={t('tooltip.newCharge')}>
          <span>{t('tooltip.newCharge')}</span>
        </Tooltip>
      )
    case Enums.TransactionType.NewCreditLimit:
      return (
        <Tooltip title={t('tooltip.newCreditLimit')}>
          <span>{t('tooltip.newCreditLimit')}</span>
        </Tooltip>
      )
    case Enums.TransactionType.NewInvoice:
      return (
        <Tooltip title={t('tooltip.newInvoice')}>
          <span>{t('tooltip.newInvoice')}</span>
        </Tooltip>
      )
    case Enums.TransactionType.InvoiceCollection:
      return (
        <Tooltip title={t('tooltip.invoiceCollection')}>
          <span>{t('tooltip.invoiceCollection')}</span>
        </Tooltip>
      )
  }
}

export default TransactionType
