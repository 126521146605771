import Debug from 'debug'

import * as Api from 'types/api/users'
import { User, UserAccount, UserRole } from 'types/app/users'
import { mapBoolean, mapDate, mapNumber, mapString } from './common'

import { getApplicationError } from 'core'

import { ErrorCode } from 'types/enums'
import { CreditLimits } from 'types/app/admin'

const debug = Debug('services:map:users')

export const mapUserAccount = (data: Api.UserAccountListData): UserAccount => {
  try {
    return {
      id: mapString(data.id),
      availableBalance: mapNumber(data.availableBalance),
      creditLimit: mapNumber(data.creditLimit),
      dailyLimit: mapNumber(data.dailyLimit),
      monthlyLimit: mapNumber(data.monthlyLimit),
      agentsCredit: mapNumber(data.agentsCredit),
      active: mapBoolean(data.active),
      status: mapNumber(data.status),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapUser = (data: Api.UserListData): User => {
  try {
    return {
      id: mapString(data.id),
      businessName: mapString(data.businessName),
      surname: mapString(data.surname),
      name: mapString(data.name),
      instanceId: mapNumber(data.instanceId),
      countryId: mapNumber(data.countryId),
      postalCode: mapString(data.postalCode),
      city: mapString(data.city),
      email: mapString(data.email),
      address: mapString(data.address),
      emailConfirmed: mapBoolean(data.emailConfirmed),
      role: mapString(data.role),
      commission: mapNumber(data.commission),
      locked: mapBoolean(data.locked),
      country: mapString(data.country),
      instance: mapString(data.instance),
      idUpperLevel: mapString(data.idUpperLevel),
      phoneNumber: mapString(data.phoneNumber),
      level: mapNumber(data.level),
      registrationDate: mapDate(data.registrationDate),
      lastUpdate: mapDate(data.lastUpdate),
      emailUpperLevel: mapString(data.emailUpperLevel),
      account: data.account && mapUserAccount(data.account),
      childs: mapNumber(data.childs),
      availableBalance: mapNumber(data.availableBalance),
      activeRefills: mapNumber(data.activeRefills),
      canInvalidateCharge: mapBoolean(data.canInvalidateCharge),
      status: mapNumber(data.status),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapUserList = (data: Api.UserListData[]): User[] => {
  try {
    return data.map((item) => mapUser(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapCreateEditFromUser = (
  entity: User,
  creditLimits?: CreditLimits,
): Api.UserCreateEditRequest => {
  try {
    const data: Api.UserCreateEditRequest = {
      email: entity.email,
      name: entity.name,
      surname: entity.surname,
      businessName: entity.businessName,
      instanceId: entity.instanceId,
      countryId: entity.countryId,
      postalCode: entity.postalCode,
      city: entity.city,
      address: entity.address,
      emailConfirmed: entity.emailConfirmed,
      role: entity.role,
      commission: entity.commission,
      locked: entity.locked,
      idUpperLevel: entity.idUpperLevel,
      phoneNumber: entity.phoneNumber,
      canInvalidateCharge: entity.canInvalidateCharge,
    }
    if (creditLimits) {
      data.creditLimit = creditLimits.creditLimit
      data.status = creditLimits.status
    }
    return data
  } catch (error) {
    debug(error)
    throw Error('Mapping error')
  }
}

export const mapRole = (data: Api.UserRoleListData): UserRole => {
  try {
    return {
      name: mapString(data.role),
      level: mapNumber(data.level),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapRoleList = (data: Api.UserRoleListData[]): UserRole[] => {
  try {
    return data.map((item) => mapRole(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapEditCreditLimits = (data: CreditLimits): Api.UserEditCreditLimitsRequest => {
  try {
    const mapped: Api.UserEditCreditLimitsRequest = {
      creditLimit: mapNumber(data.creditLimit),
      status: mapNumber(data.status),
    }
    return mapped
  } catch (error) {
    debug(error)
    throw Error('Mapping error')
  }
}
