import * as K from './constants'
import { put, call, takeLatest, all } from 'redux-saga/effects'
import Debug from 'debug'
import { LoadingState, UserOperation } from 'types/enums'
import * as Auth from 'types/api/auth'
import * as localStore from 'core/localStore'
import { AnyAction } from 'redux'
import * as authApi from 'api/auth'
import * as Store from 'types/store'
import { ResponseCode } from 'types/enums.api'

const debug = Debug('Frontend')

export const selectUser = (state) => state.root.user.user

export function* login(action: AnyAction) {
  try {
    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.Login, status: LoadingState.Loading },
    })

    const { username, password } = action.payload
    const loginRequest: Auth.LoginRequest = {
      username,
      password,
    }

    const loginResponse = yield call(authApi.login, loginRequest)
    if ((loginResponse as ApiError).status) {
      debug('Response', loginResponse, loginResponse as ApiError)

      switch ((loginResponse as ApiError).status) {
        case 401:
          yield put({
            type: K.SET_OPERATION_ERROR,
            payload: { operation: UserOperation.Login, error: ResponseCode.Unauthorized },
          })
          break
        default:
          break
      }

      if (loginResponse.data && (loginResponse.data.message || loginResponse.data.error)) {
        throw new Error(loginResponse.data.message || loginResponse.data.error)
      }
      throw new Error('API Error: login')
    }

    yield localStore.saveUser(loginResponse)

    const user: Store.User = {
      email: loginResponse.email,
      name: loginResponse.name,
      surname: loginResponse.surname,
    }
    yield put({ type: K.FETCH_USER_SUCCESS, payload: user })

    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.Login, status: LoadingState.Loaded },
    })
  } catch (error) {
    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.Login, status: LoadingState.LoadFailure },
    })
    debug('login Error', error)
  }
}

export function* forgotPassword(action: AnyAction) {
  try {
    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.ForgotPassword, status: LoadingState.Loading },
    })

    const { email } = action.payload
    const request: Auth.ForgotPasswordRequest = {
      email,
    }

    const response = yield call(authApi.forgotPassword, request)
    if ((response as ApiError).status) {
      debug('Response', response, response as ApiError)

      switch ((response as ApiError).status) {
        case 400:
          yield put({
            type: K.SET_OPERATION_ERROR,
            payload: { operation: UserOperation.ForgotPassword, error: ResponseCode.BadRequest },
          })
          break
        case 401:
          yield put({
            type: K.SET_OPERATION_ERROR,
            payload: { operation: UserOperation.ForgotPassword, error: ResponseCode.Unauthorized },
          })
          break
        default:
          break
      }

      if (response.data && (response.data.message || response.data.error)) {
        throw new Error(response.data.message || response.data.error)
      }
      throw new Error('API Error: forgot password')
    }

    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.ForgotPassword, status: LoadingState.Loaded },
    })
  } catch (error) {
    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.ForgotPassword, status: LoadingState.LoadFailure },
    })
    debug('forgot password Error', error)
  }
}

export function* resetPassword(action: AnyAction) {
  try {
    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.ResetPassword, status: LoadingState.Loading },
    })

    const { email, code, password, confirmPassword } = action.payload
    const request: Auth.ResetPasswordRequest = {
      email,
      code,
      password,
      confirmPassword,
    }

    const response = yield call(authApi.resetPassword, request)
    if ((response as ApiError).status) {
      const detail = (response as ApiError).data?.detail
      debug('Response', response, response as ApiError)

      switch ((response as ApiError).status) {
        case 400:
          yield put({
            type: K.SET_OPERATION_ERROR,
            payload: {
              operation: UserOperation.ResetPassword,
              error: detail || ResponseCode.BadRequest,
            },
          })
          break
        case 401:
          yield put({
            type: K.SET_OPERATION_ERROR,
            payload: { operation: UserOperation.ResetPassword, error: ResponseCode.Unauthorized },
          })
          break
        default:
          break
      }

      if (response.data && (response.data.message || response.data.error)) {
        throw new Error(response.data.message || response.data.error)
      }
      throw new Error('API Error: reset password')
    }

    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.ResetPassword, status: LoadingState.Loaded },
    })
  } catch (error) {
    yield put({
      type: K.SET_OPERATION_STATUS,
      payload: { operation: UserOperation.ResetPassword, status: LoadingState.LoadFailure },
    })
    debug('forgot password Error', error)
  }
}

export function* logout() {
  debug('Saga logout')
  yield localStore.clearUser()
}

function* watchUser() {
  yield all([
    takeLatest(K.LOGIN, login),
    takeLatest(K.LOGOUT, logout),
    takeLatest(K.FORGOT_PASSWORD, forgotPassword),
    takeLatest(K.RESET_PASSWORD, resetPassword),
  ])
}

export default watchUser
