import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Enums from 'types/enums'

interface Props {
  value: Enums.InvoiceStatus
}
const InvoiceStatus: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation()
  switch (value) {
    case Enums.InvoiceStatus.Created:
      return <Tag color="red">{t('invoice.created')}</Tag>
    case Enums.InvoiceStatus.Paid:
      return <Tag color="green">{t('invoice.paid')}</Tag>
    case Enums.InvoiceStatus.Deleted:
      return <Tag color="green">{t('invoice.deleted')}</Tag>
  }
}

export default InvoiceStatus
