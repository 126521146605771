import React, { useContext, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import UserLogout from './UserLogout'

import { userContext } from 'core'
import { UserRole } from 'types/enums'
import SuperAdminInstancesContainer from 'containers/instances/SuperAdminInstances/SuperAdminInstancesContainer'
import LanguageHeaderDropdownContainer from 'containers/languages/LanguageHeaderDropdown/LanguageHeaderDropdownContainer'
import { acronymify } from 'core/helpers'
import routes from 'config/routes'
import Notifications from './Notifications'

import { ExpandOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons'
import { Col, Dropdown, Input, Layout, Row, Button, Avatar, Typography, Space, Affix, Form, Card } from 'antd'
import style from './styles.module.scss'

const Header: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState(null)
  const { user, role: userRole } = useContext(userContext)

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }
  const handleNumber = () => {
    if (searchValue.length >= 6) {
      navigate(generatePath(`${routes.charges.url}${'?number='}${searchValue}`))
      setSearchValue(null)
    }
  }

  const menu4 = (
    <Card style={{ width: 300 }} className="p-12">
      <Space align="center">
        <UserOutlined style={{ fontSize: 24 }} />
        <div>
          <Typography.Title level={5} className="m-0">
            {user}
          </Typography.Title>
          <Typography.Text>({userRole})</Typography.Text>
        </div>
      </Space>

      <Button type="primary" block className="mt-20">
        <UserLogout />
      </Button>
    </Card>
  )
  return (
    <Affix offsetTop={0}>
      <Layout.Header className={style['header']}>
        <Row align="middle">
          <Col flex="1 0 auto" className="search px-12">
            <Form>
              <Form.Item
                className="input-search-container"
                name="phone"
                required
                rules={[
                  {
                    required: true,
                    pattern: /^\d{6,}$/,
                    message: <span>{t('validationForm.minNumber')}</span>,
                  },
                ]}
                normalize={(value) => value.replace(/\D/g, '')}
                validateTrigger={['onBlur', 'onSubmit']}
              >
                <Input
                  type="number"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  suffix={<SearchOutlined onClick={handleNumber} />}
                  placeholder={t('menu.searchCharge')}
                  onPressEnter={handleNumber}
                  className="charge"
                />
              </Form.Item>
            </Form>
          </Col>
          <Col className="col-drop align-right">
            <Space size="small">
              {userRole === UserRole.SuperAdmin && <SuperAdminInstancesContainer />}

              <LanguageHeaderDropdownContainer />
              {userRole !== UserRole.SuperAdmin && <Notifications />}

              <Dropdown overlay={menu4} trigger={['click']} align={{ offset: [0, 20] }}>
                <Avatar size={30}>{acronymify(user, 2).toUpperCase()}</Avatar>
              </Dropdown>
              <ExpandOutlined onClick={toggleFullScreen} />
            </Space>
          </Col>
        </Row>
      </Layout.Header>
    </Affix>
  )
}

Header.displayName = 'Header'

export default Header
