import React, { useEffect, useState } from 'react'
import Debug from 'debug'
import UserEdit from 'components/UserDetails/UserEdit'
import UserDetails from 'components/UserDetails'
import { ChangePassword, User } from 'types/app/users'
import {
  useChangeUserPassword,
  useCreateUser,
  useDeleteUser,
  useEditUser,
  useEditUserCreditLimits,
} from 'services/query/users'
import { generatePath, useNavigate } from 'react-router-dom'
import { notification } from 'antd'
import { useRouter } from 'core/useRouter'
import routes from 'config/routes'
import { Operation } from 'types/enums'
import { CreditLimits } from 'types/app/admin'
import { useTranslation } from 'react-i18next'

interface Props {
  user: User
  network: User[]
}

const debug = Debug('Frontend:UserDetailsContainer')

const UserDetailsContainer: React.FC<Props> = ({ user, network }) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [mode, setMode] = useState(null)
  const createUser = useCreateUser()
  const editUser = useEditUser()
  const editCreditLimits = useEditUserCreditLimits(user.id)
  const changeUserPassword = useChangeUserPassword(user.id)
  const navigate = useNavigate()
  const router = useRouter()
  const deleteUser = useDeleteUser()
  const [isFetching, setFetching] = useState(false)

  useEffect(() => {
    if (router.query.editmode) {
      setEditMode(true)
      setMode(router.query.editmode)
    } else {
      setEditMode(false)
    }
  }, [router.query])

  const handleDelete = async (userId: string) => {
    await deleteUser(userId)
  }

  if (editMode) {
    const operationType = user?.id ? Operation.Update : Operation.Create

    const onUserDetailsSaved = async (userModel: User, creditLimits: CreditLimits) => {
      debug('onUserDetailsSaved', user)
      setFetching(true)
      try {
        if (operationType === Operation.Update) {
          // either update of user details and role or user credit limits
          if (creditLimits) {
            await editCreditLimits(creditLimits)
          } else {
            await editUser({ ...user, ...userModel })
            notification.success({ message: t('messagesCrud.userUpdated') })
          }
        } else {
          await createUser({ user: userModel, creditLimits })
          notification.success({ message: t('messagesCrud.userCreated') })
        }
        navigate(generatePath(routes.users.url), { replace: true })
      } catch (error) {
        console.error(error)
        notification.error({ message: `User save error: ${error || ''}` })
      }
      setFetching(false)
    }

    const onUserPasswordChanged = async (model: ChangePassword) => {
      debug('onUserPasswordChanged', model)
      setFetching(true)
      try {
        await changeUserPassword({ ...model })
        navigate(generatePath(routes.userDetails.url, { id: user.id }), { replace: true })
      } catch (error) {
        console.error(error)
        notification.error({ message: `User change password error: ${error || ''}` })
      }
      setFetching(false)
    }

    return (
      <UserEdit
        user={user}
        operationType={operationType}
        mode={mode}
        setMode={setMode}
        onUserDetailsSaved={onUserDetailsSaved}
        onUserPasswordChanged={onUserPasswordChanged}
        isFetching={isFetching}
      ></UserEdit>
    )
  }

  return <UserDetails user={user} handleDelete={handleDelete} network={network} />
}

export default UserDetailsContainer
