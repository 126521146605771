import React from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'

interface Props {
  fileName: string
  headers: any
  dataSource: any
}

const ExportToCsv: React.FC<Props> = ({ fileName, dataSource, headers }) => {
  const { t } = useTranslation()
  return (
    <Button type="primary" block>
      <CSVLink filename={fileName} data={dataSource} headers={headers} className="btn btn-primary">
        {t('btn.exportToCsv')}
      </CSVLink>
    </Button>
  )
}

ExportToCsv.displayName = 'ExportToCsv'

export default ExportToCsv
