import React, { useContext } from 'react'
import * as Api from 'types/api/widgets'
import BalanceComponent from 'components/Dashboard/BalanceComponent'
import { useGetCurrentBalanceWidget } from 'services/query/widgets'
import { useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'
import { userContext } from 'core'
import { UserRole } from 'types/enums'

const CurrentBalanceWidgetContainer: React.FC = () => {
  const userSettings = useSelector(selectUserSettings)
  const { role: userRole } = useContext(userContext)
  const searchInstanceId = userRole === UserRole.SuperAdmin ? userSettings?.selectedInstance?.id || null : -1

  const queryParams: Api.GetWidgetRequest = {
    instanceId: searchInstanceId,
  }

  const { data, isFetched, isLoading } = useGetCurrentBalanceWidget(queryParams)

  return <BalanceComponent data={data} isLoading={isLoading || !isFetched} />
}

CurrentBalanceWidgetContainer.displayName = 'CurrentBalanceWidgetContainer'

export default CurrentBalanceWidgetContainer
