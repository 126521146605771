import React from 'react'
import IconFont from 'core/IconFont'
import { Row, Col, Typography, Button } from 'antd'
import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'

const Error500: React.FC = () => {
  const { t } = useTranslation()

  return (
    <section className={style['error-page']}>
      <Row justify="center">
        <Col xs={24} className="align-center">
          <div className="icon-container">
            <IconFont type="icon-error-icon" />
          </div>

          <Typography.Title>{t('errors.errorPageTitle')}</Typography.Title>
        </Col>

        <Col xs={24} lg={8}>
          <Typography.Paragraph>
            <Typography.Text>{t('errors.errorPageTextFirst')} </Typography.Text>
            <Typography.Text strong>{t('errors.errorPageTextSecond')}</Typography.Text>.
          </Typography.Paragraph>

          <Button
            block
            size="large"
            type="primary"
            shape="round"
            className="app-bg-secondary-color app-secondary-color-invert mt-20"
          >
            <a href="/">{t('errors.errorBtnHome')}</a>
          </Button>
        </Col>
      </Row>
    </section>
  )
}

Error500.displayName = 'Error500'

export default Error500
