import React, { useContext, useState } from 'react'
import TransactionsTable from 'components/transactions/TransactionsTable'
import dayjs from 'dayjs'
import { TransactionsFilter } from 'types/app/filters'
import { TABLE_PAGINATION_DEFAULT_PAGE_SIZE, userContext } from 'core'
import { UserRole } from 'types/enums'
import { SearchTransactionsRequest } from 'types/api/transanctions'
import { useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'

import { useSearchTransactions } from 'services/query/transactions'

const TransactionsTableContainer: React.FC = () => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(TABLE_PAGINATION_DEFAULT_PAGE_SIZE)
  const [sorterField, setSorterField] = useState('registrationDate')
  const [sorterDirection, setSorterDirection] = useState('descend')
  const userSettings = useSelector(selectUserSettings)
  const { role: userRole } = useContext(userContext)
  const initialFilter: TransactionsFilter = {
    dateFrom: dayjs().subtract(1, 'months').toDate(),
    dateTo: dayjs().toDate(),
    userId: '',
  }

  const [filter, setFilter] = useState<TransactionsFilter>(initialFilter)

  const searchInstanceId = userRole === UserRole.SuperAdmin ? userSettings?.selectedInstance?.id || null : -1
  const queryParams: SearchTransactionsRequest = {
    page,
    pageSize,
    instanceId: searchInstanceId,
    dateFrom: filter.dateFrom.toISOString(),
    dateTo: filter.dateTo.toISOString(),
    orderBy: sorterField || 'registrationDate',
    orderByDirection: sorterDirection === 'descend' ? -1 : 1,
    userId: filter.userId,
  }

  const { data: transactions, isFetched, isLoading } = useSearchTransactions(queryParams)
  const dataLoading: boolean = isLoading || !isFetched

  const handleChange = (pagination, filters, sorter) => {
    if (pagination && pagination.current) {
      setPage(pagination.current - 1)
      setPageSize(pagination.pageSize)
    }

    setSorterField(sorter.field)

    if (sorter.hasOwnProperty('column')) {
      setSorterField(sorter.field)
      setSorterDirection(sorter.order)
    }
  }

  const handleFiltering = (filter: TransactionsFilter) => {
    setPage(0)
    setSorterField('registrationDate')
    setSorterDirection('descend')
    setFilter(filter)
  }

  return (
    <TransactionsTable
      initialFilter={initialFilter}
      dataSource={transactions}
      isLoading={dataLoading}
      handleFiltering={handleFiltering}
      handleChange={handleChange}
    />
  )
}

TransactionsTableContainer.displayName = 'TransactionsTableContainer'

export default TransactionsTableContainer
