import * as K from './constants'
import { AnyAction } from 'redux'

export function bootstrap() {
  return {
    type: K.APP_BOOTSTRAP,
    payload: null,
  }
}

export function bootstrapLoaded(): AnyAction {
  return {
    type: K.APP_BOOTSTRAP_LOADED,
    payload: null,
  }
}
