import * as K from './constants'
import { AnyAction } from 'redux'
import * as Store from 'types/store'

// The initial state of the Reducer
export const initialState: Store.AppState = {
  bootstrapLoading: true,
}

const reducer = (state = initialState, action: AnyAction): Store.AppState => {
  switch (action.type) {
    case K.APP_BOOTSTRAP_LOADED:
      return {
        ...state,
        bootstrapLoading: false,
      }

    default:
      return state
  }
}

export default reducer
