import React, { useRef, useState } from 'react'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'

import { formatTwoDecimals, tableSorter } from 'core/helpers'
import TopPage from 'components/TopPage'
import FilterPanel from 'components/reports/FilterPanel'

import { ReportFilter } from 'types/app/filters'

import style from './styles.module.scss'
import { Card, Button, Row, Col } from 'antd'
import TableComponent from 'components/Table'
import { CommissionsReportAgentRow, CommissionsReportCommissionRow } from 'types/app/reports'
import dayjs from 'dayjs'
import ExportToCsv from 'components/reports/ExportToCsv/ExportToCsv'
import { useReactToPrint } from 'react-to-print'
import CommissionRowTable from './ComissionRowTable'

interface Props {
  dataSource: any
  initialFilter?: ReportFilter
  isLoading: boolean
  handleChange?: (pagination, filters, sorter) => void
  handleFiltering?: (filter: ReportFilter) => void
}

const CommissionsReportTable: React.FC<Props> = ({
  dataSource,
  initialFilter,
  isLoading,
  handleFiltering,
  handleChange,
}) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<ReportFilter>(initialFilter)
  const printRef = useRef<any>()
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  })

  const commissionsExpandable = (record) => {
    return <CommissionRowTable dataSource={record.commissions} />
  }

  const csvHeaders = [
    { label: t('user.agentId'), key: 'agentId' },
    { label: t('role.agent'), key: 'agentName' },
    { label: t('user.affiliateId'), key: 'affiliateId' },
    { label: t('role.affiliate'), key: 'affiliateName' },
    { label: t('reports.totalChargesNumber'), key: 'totalChargesNumber' },
    { label: t('reports.totalChargesAmount'), key: 'totalChargesAmount' },
    { label: t('general.commissionPerc'), key: 'commissionsPerc' },
    { label: t('reports.totalCommissionsAmount'), key: 'totalCommissionsAmount' },
  ]

  const getCsvData = (agents: CommissionsReportAgentRow[]) => {
    const reduceCommissions = (rows: CommissionsReportCommissionRow[]) => {
      return rows.reduce((prev, curr) => {
        return prev.concat(
          curr.affiliates.map((affiliate) => {
            return { ...affiliate }
          }),
        )
      }, [])
    }
    return agents.reduce((prev, cur) => {
      return prev.concat(reduceCommissions(cur.commissions))
    }, [])
  }

  const getCsvFilename = () => {
    const dateFormat = 'DD-MM-YYYY'
    const agentIdString = filter.agentId ? `_${filter.agentId?.toString()}` : ''
    return `commissions_report_${dayjs(filter.dateFrom).format(dateFormat)}_${dayjs(filter.dateTo).format(
      dateFormat,
    )}${agentIdString}.csv`
  }

  const columns = [
    {
      key: 'agentName',
      title: t('role.agent'),
      dataIndex: 'agentName',
      sorter: (a, b) => tableSorter(a.agentName, b.agentName),
    },
    {
      key: 'items.totalNumber',
      title: t('reports.totalChargesNumber'),
      dataIndex: 'totalChargesNumber',
      sorter: (a, b) => tableSorter(a.totalChargesNumber, b.totalChargesNumber),
    },
    {
      key: 'items.totalChargesAmount',
      title: t('reports.totalChargesAmount'),
      dataIndex: 'totalChargesAmount',
      render: (val) => formatTwoDecimals(val),
      sorter: (a, b) => tableSorter(a.totalChargesAmount, b.totalChargesAmount),
    },
    {
      key: 'items.totalCommissionsAmount',
      title: t('reports.totalCommissionsAmount'),
      dataIndex: 'totalCommissionsAmount',
      render: (val) => formatTwoDecimals(val),
      sorter: (a, b) => tableSorter(a.totalCommissionsAmount, b.totalCommissionsAmount),
    },
  ]

  const onFilter = (filter: ReportFilter) => {
    setFilter(filter)
    handleFiltering(filter)
  }

  const crumbs = () => [{ route: routes.home }, { route: routes.commissionsReport }]
  return (
    <section className={style['reports-table']} ref={printRef}>
      <TopPage title={t('menu.reports')} crumbs={crumbs()}></TopPage>
      <section className="app-main-content">
        <FilterPanel initialFilter={initialFilter} onFilter={onFilter} />

        <Card className="mt-16 card-table">
          <TableComponent
            columns={columns}
            dataSource={dataSource?.data}
            onChange={handleChange}
            total={dataSource?.total}
            isLoading={isLoading}
            expandable={{ expandedRowRender: commissionsExpandable }}
          />
          {!isLoading && (
            <>
              <Row gutter={[12, 12]} justify="end" className="mt-12 btn">
                <Col flex="0 0 100px">
                  <ExportToCsv
                    fileName={getCsvFilename()}
                    dataSource={getCsvData(dataSource?.data)}
                    headers={csvHeaders}
                  />
                </Col>
                <Col flex="0 0 100px">
                  <Button block onClick={handlePrint} type="primary">
                    {t('btn.exportToPdf')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Card>
      </section>
    </section>
  )
}

export default CommissionsReportTable
