import Debug from 'debug'

import * as Api from 'types/api/charges'
import { ActiveBalance, Charge } from 'types/app/charges'
import { mapDate, mapNumber, mapString } from './common'
import { getApplicationError } from 'core'
import { ErrorCode } from 'types/enums'

const debug = Debug('services:map:charges')

export const mapCharge = (data: Api.ChargeListData): Charge => {
  try {
    return {
      id: mapNumber(data.id),
      guid: mapString(data.guid),
      userId: mapString(data.userId),
      intlDialingPrefix: mapString(data.intlDialingPrefix),
      mobilePhone: mapString(data.mobilePhone),
      amount: mapNumber(data.amount),
      status: mapNumber(data.status),
      registrationDate: mapDate(data.registrationDate),
      smsAnswerStatus: mapNumber(data.smsAnswerStatus),
      smsSentDate: mapDate(data.smsSentDate),
      instanceId: mapNumber(data.instanceId),
      invoiceId: mapNumber(data.invoiceId),
      userName: mapString(data.userName),
      expirationDate: mapDate(data.expirationDate),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapChargeList = (data: Api.ChargeListData[]): Charge[] => {
  try {
    return data.map((item) => mapCharge(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapUserActiveBalance = (data: Api.UserActiveBalanceResponse): ActiveBalance => {
  try {
    return {
      activeBalance: mapNumber(data.activeBalance),
      creditGranted: mapNumber(data.creditGranted),
      currencySymbol: mapString(data.currencySymbol),
      status: mapNumber(data.status),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
