import React from 'react'
import { Layout } from 'antd'

import style from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'providers/ThemeProvider'
import { ThemeType } from 'themes/types'

const Footer: React.FC = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()

  return (
    <Layout.Footer className={`${style.footer} px-24 py-12`}>
      {theme === ThemeType.Epayrex ? (
        <section> {t('general.footerEpayrex')}</section>
      ) : (
        <section> {t('general.footerTimerpay')}</section>
      )}
    </Layout.Footer>
  )
}

Footer.displayName = 'Footer'

export default Footer
