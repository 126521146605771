import { config } from 'core'

import { AxiosError, AxiosResponse } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import * as Api from 'types/api/users'
import { UserChangePasswordRequest } from 'types/api/users'
import { ApiSearchResponse } from 'types/api/common'

const axios = getAxios()

export async function listRoles(): Promise<Api.UserRoleListData[]> {
  try {
    const response = await axios.get(config.api.roles(), await getAuthenticationHeader())
    return response.data as Api.UserRoleListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function listUsers(
  queryParams?: Api.ListUsersRequest,
): Promise<ApiSearchResponse<Api.UserListData>> {
  try {
    const response = await axios.post(
      config.api.listUsers(),
      queryParams || {},
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function listAgents(
  params?: Api.ListUsersRequest,
): Promise<ApiSearchResponse<Api.UserListData>> {
  try {
    const response = await axios.post(config.api.listAgents(), params || {}, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getUserDetails(userId: string): Promise<Api.UserListData> {
  try {
    const response = await axios.get(config.api.user(userId), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getUserNetwork(
  userId: string,
  instanceId?: number,
): Promise<ApiSearchResponse<Api.UserListData>> {
  try {
    let url = `${config.api.user(userId)}/network`
    if (instanceId) {
      url += `?iid=${instanceId}`
    }
    const response = await axios.get(url, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createUser(model: Api.UserCreateEditRequest): Promise<Api.UserListData> {
  try {
    const response = await axios.post(config.api.newUser(), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editUser(userId: string, model: Api.UserCreateEditRequest): Promise<Api.UserListData> {
  try {
    const response = await axios.put(config.api.user(userId), model, await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function editUserCreditLimits(
  userId: string,
  model: Api.UserEditCreditLimitsRequest,
): Promise<Api.UserListData> {
  try {
    const response = await axios.put(
      config.api.changeUserCreditLimits(userId),
      model,
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteUser(userId: string): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.user(userId), await getAuthenticationHeader())
    return response.data as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function changeUserPassword(model: UserChangePasswordRequest): Promise<AxiosResponse> {
  try {
    const response = await axios.post(config.api.changePassword(), model, await getAuthenticationHeader())
    return response
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
