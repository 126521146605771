import React from 'react'
import LanguagesTableContainer from 'containers/languages/LanguagesTable/LanguagesTableContainer'

import style from './styles.module.scss'

const LanguagesPage: React.FC = () => {
  return (
    <section className={style['users-page']}>
      <LanguagesTableContainer />
    </section>
  )
}

LanguagesPage.displayName = 'LanguagesPage'

export default LanguagesPage
