export const apiProvider = function provider(baseUrl: string) {
  let language: string = null

  return {
    api: {
      // vocabulary: this is used by i18n. Do not remove {{lng}} parameter
      vocabulary: () => `${baseUrl}/api/v1/labels/{{lng}}`,

      //local resources
      localVocabulary: () => `/translations/{{lng}}/labels.json`,

      // auth api
      login: () => `${baseUrl}/api/v1/auth/login`,
      forgotPassword: () => `${baseUrl}/api/v1/auth/forgot-password`,
      resetPassword: () => `${baseUrl}/api/v1/auth/reset-password`,
      refreshToken: () => `${baseUrl}/api/v1/auth/refresh-token`,
      register: () => `${baseUrl}/auth/cognito/registration`,
      confirmEmail: () => `${baseUrl}/api/v1/auth/confirm-email`,

      // users
      listUsers: () => `${baseUrl}/api/v1/users/list`,
      listAgents: () => `${baseUrl}/api/v1/users/list-agents`,
      roles: () => `${baseUrl}/api/v1/users/roles`,
      changePassword: () => `${baseUrl}/api/v1/users/change-password`,
      activeBalance: () => `${baseUrl}/api/v1/users/active-balance`,
      // user
      newUser: () => `${baseUrl}/api/v1/user`,
      user: (userId) => `${baseUrl}/api/v1/user/${userId}`,

      // admin
      // Countries
      countries: () => `${baseUrl}/api/v1/admin/countries`,
      country: (countryId) => `${baseUrl}/api/v1/admin/countries/${countryId}`,

      // Currencies
      currencies: () => `${baseUrl}/api/v1/admin/currencies`,
      currency: (currencyId) => `${baseUrl}/api/v1/admin/currencies/${currencyId}`,

      // languages
      language: (languageId) => `${baseUrl}/api/v1/admin/languages/${languageId}`,
      listLanguages: () => `${baseUrl}/api/v1/admin/languages`,
      newLanguage: () => `${baseUrl}/api/v1/admin/languages`,

      // customers
      searchCustomers: () => `${baseUrl}/api/v1/admin/customers/search`,
      customers: () => `${baseUrl}/api/v1/admin/customers`,
      customer: (id) => `${baseUrl}/api/v1/admin/customers/${id}`,

      // Cultures
      culture: () => `${baseUrl}/api/v1/admin/cultures`,

      // instances
      searchInstances: () => `${baseUrl}/api/v1/admin/instances/search`,
      instances: () => `${baseUrl}/api/v1/admin/instances`,
      instance: (id) => `${baseUrl}/api/v1/admin/instances/${id}`,

      // charges
      searchCharges: () => `${baseUrl}/api/v1/charges/search`,
      charges: () => `${baseUrl}/api/v1/charges`,
      charge: (id) => `${baseUrl}/api/v1/charges/${id}`,

      // invoices
      invoices: () => `${baseUrl}/api/v1/invoices`,
      searchInvoices: () => `${baseUrl}/api/v1/invoices/search`,
      invoice: (id, instanceId) =>
        instanceId
          ? `${baseUrl}/api/v1/invoices/${id}?iid=${instanceId}`
          : `${baseUrl}/api/v1/invoices/${id}`,
      payInvoice: (id, instanceId) =>
        instanceId
          ? `${baseUrl}/api/v1/invoices/${id}/pay?iid=${instanceId}`
          : `${baseUrl}/api/v1/invoices/${id}/pay`,

      // transactions
      searchTransactions: () => `${baseUrl}/api/v1/transaction/search`,

      // users credit limits
      creditLimitsUserDefaults: () => `${baseUrl}/api/v1/users/credit-limits/defaults`,
      changeUserCreditLimits: (id) => `${baseUrl}/api/v1/users/credit-limits/${id}`,

      // reports
      chargesReport: () => `${baseUrl}/api/v1/reports/charges-report`,
      commissionsReport: () => `${baseUrl}/api/v1/reports/commissions-report`,

      // widgets
      currentBalanceWidget: () => `${baseUrl}/api/v1/widgets/current-balance`,
      monthlyChargesWidget: () => `${baseUrl}/api/v1/widgets/monthly-charges`,
      yearlyChargesWidget: () => `${baseUrl}/api/v1/widgets/yearly-charges`,

      // translations
      searchTranslations: () => `${baseUrl}/api/v1/admin/labels/search`,
      translation: (tag) => `${baseUrl}/api/v1/admin/labels/${tag}`,
      createTranslation: () => `${baseUrl}/api/v1/admin/labels`,
      // widget
      clientServedWidegt: () => `${baseUrl}/api/v1/widgets/clients-served`,
      // messages
      messages: () => `${baseUrl}/api/v1/messages/unread`,
      listMessages: () => `${baseUrl}/api/v1/messages/list`,
      getMessage: (id) => `${baseUrl}/api/v1/messages/${id}`,
      deleteMessage: (id) => `${baseUrl}/api/v1/messages/${id}`,
      readMessage: (id) => `${baseUrl}/api/v1/messages/read/${id}`,
      newMessage: () => `${baseUrl}/api/v1/messages/support`,
    },
    getLanguage: () => language,
    setLanguage: (lng: string): void => {
      language = lng
    },
  }
}
