import React, { useEffect, useState } from 'react'
import Debug from 'debug'

import { InstanceDetails } from 'types/app/admin'
import {
  useCreateInstance,
  useEditInstance,
  useLoadCultureList,
  useLoadCountryList,
} from 'services/query/admin'
import { generatePath, useNavigate } from 'react-router-dom'
import routes from 'config/routes'
import { notification } from 'antd'
import { useRouter } from 'core/useRouter'
import { useTranslation } from 'react-i18next'
import InstanceEdit from 'components/instances/InstanceEdit'
import InstanceDetailsComponent from 'components/instances/InstanceDetails/InstanceDetails'

interface Props {
  instance: InstanceDetails
}

const debug = Debug('Frontend:InstanceDetailsContainer')

const InstanceDetailsContainer: React.FC<Props> = ({ instance }) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const createInstance = useCreateInstance()
  const editInstance = useEditInstance()
  const router = useRouter()
  const navigate = useNavigate()

  const { data: cultures } = useLoadCultureList()
  const { data: countries } = useLoadCountryList()

  const [isFetching, setFetching] = useState(false)
  useEffect(() => {
    if (router.query.editmode) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [router.query])

  const onInstanceDetailsSaved = async (model: InstanceDetails) => {
    debug('onInstanceDetailsSaved', model)
    setFetching(true)

    try {
      if (instance?.id) {
        await editInstance({ ...instance, ...model })
        navigate(generatePath(routes.instanceDetails.url, { id: instance.id.toString() }), { replace: true })
        notification.success({ message: t('messagesCrud.instanceUpdated') })
      } else {
        const newInstance = await createInstance(model)
        navigate(generatePath(routes.instanceDetails.url, { id: newInstance.id.toString() }), {
          replace: true,
        })
        notification.success({ message: t('messagesCrud.instanceCreated') })
      }
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.saveError') })
    }
    setFetching(false)
  }
  if (editMode) {
    return (
      <InstanceEdit
        instance={instance}
        cultures={cultures}
        mode={router.query.editmode}
        onSave={onInstanceDetailsSaved}
        isFetching={isFetching}
      ></InstanceEdit>
    )
  }
  return (
    <>
      <InstanceDetailsComponent instance={instance} countries={countries}></InstanceDetailsComponent>
    </>
  )
}

export default InstanceDetailsContainer
