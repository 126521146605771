import { useMutation, useQuery, useQueryClient } from 'react-query'

import * as queryKeys from 'config/queryKeys'
import {
  createMessage,
  deleteMessage,
  getMessageDetails,
  listMessages,
  listMessagesUnread,
  readMessage,
} from 'api/messages'
import { mapMessage, mapMessageList, mapMessagesUnread } from 'services/map/messages'
import { Message } from 'types/app/messages'

export function useLoadMessagesUnread() {
  const queryFunction = async () => {
    const response = await listMessagesUnread()
    return mapMessagesUnread(response)
  }
  return useQuery(queryKeys.messagesUnread(), queryFunction, {
    refetchInterval: 10000,
    placeholderData: null,
  })
}

export function useLoadMessageList() {
  const queryFunction = async () => {
    const response = await listMessages()
    return { data: mapMessageList(response.data), total: response.totalRecords }
  }
  return useQuery(queryKeys.messageList(), queryFunction, {
    placeholderData: { data: [], total: 0 },
  })
}

export function useLoadMessage(messageId: number) {
  const queryFunction = async () => {
    const response = await getMessageDetails(messageId)
    return mapMessage(response)
  }

  return useQuery(queryKeys.messageDetails(messageId), queryFunction, {
    placeholderData: null,
  })
}

export function useDeleteMessage() {
  const mutateFunction = async (message: Message) => {
    await deleteMessage(message)
    return message as Message
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (message) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.messageDetails(message.id)),
        queryClient.invalidateQueries(queryKeys.messageList()),
      ])
    },
  })
  return mutateAsync
}

export function useReadMessage(messageId: number) {
  const mutateFunction = async () => {
    const response = await readMessage(messageId)
    return mapMessage(response)
  }

  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (modified) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.messageDetails(modified.id)),
        queryClient.invalidateQueries(queryKeys.messageList()),
      ])
    },
  })
  return mutateAsync
}

export function useCreateMessage() {
  const mutateFunction = async (message: Message) => {
    await createMessage({ subject: message.subject, body: message.body })
    return message as Message
  }
  const queryClient = useQueryClient()
  const { mutateAsync } = useMutation(mutateFunction, {
    onSuccess: (message) => {
      return Promise.all([
        queryClient.invalidateQueries(queryKeys.messageDetails(message.id)),
        queryClient.invalidateQueries(queryKeys.messageList()),
      ])
    },
  })
  return mutateAsync
}
