import { put, takeLatest, all } from 'redux-saga/effects'
import * as K from './constants'
import { bootstrapLoaded } from './actions'

export function* bootstrap() {
  // yield all([fetchStrategies(), fetchPortfolios(), fetchAssets(), fetchSessions(), fetchLayouts()])
  yield put(bootstrapLoaded())
}

function* watchApp() {
  yield all([takeLatest(K.APP_BOOTSTRAP, bootstrap)])
}

export default watchApp
