import React, { useEffect } from 'react'

import { useLoadInstanceList } from 'services/query/admin'
import { Instance } from 'types/app/admin'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserSettings } from 'store/user/selectors'
import { setUserInstance } from 'store/user/actions'
import SuperAdminInstancePicker from 'components/instances/SuperAdminInstancePicker'

const SuperAdminInstancesContainer: React.FC = () => {
  const dispatch = useDispatch()
  const userSettings = useSelector(selectUserSettings)

  const { data: instances, isFetching } = useLoadInstanceList()

  let initialInstance = instances[0]

  useEffect(() => {
    if (userSettings) {
      if (userSettings.selectedInstance) {
        initialInstance = userSettings.selectedInstance
      } else {
        dispatch(setUserInstance(initialInstance))
      }
    }
  }, [initialInstance])

  const onChanged = (instance: Instance) => {
    if (instance) dispatch(setUserInstance(instance))
  }

  if (isFetching) return null
  return (
    <SuperAdminInstancePicker instances={instances} initialInstance={initialInstance} onChanged={onChanged} />
  )
}

SuperAdminInstancesContainer.displayName = 'SuperAdminInstancesContainer'

export default SuperAdminInstancesContainer
