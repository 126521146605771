import React from 'react'
import { Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import * as Enums from 'types/enums'

interface Props {
  value: Enums.UserStatus
}
const UserStatus: React.FC<Props> = ({ value }) => {
  const { t } = useTranslation()
  switch (value) {
    case Enums.UserStatus.Active:
      return <Tag color="success">{t('status.active')}</Tag>
    case Enums.UserStatus.Blocked:
    case Enums.UserStatus.BlockedByInvoicePayTerm:
      return <Tag color="error">{t('status.blocked')}</Tag>
    default:
      return null
  }
}

export default UserStatus
