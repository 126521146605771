import Debug from 'debug'

import * as Api from 'types/api/messages'

import { mapDate, mapNumber, mapString } from './common'

import { getApplicationError } from 'core'

import { ErrorCode } from 'types/enums'
import { Message, MessageUnread } from 'types/app/messages'

const debug = Debug('services:map:messages')

export const mapMessagesUnread = (data: Api.MessagesUnreadData): MessageUnread => {
  try {
    return {
      unread: mapNumber(data.unread),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapMessage = (data: Api.MessageListData): Message => {
  try {
    return {
      id: mapNumber(data.id),
      typeId: mapNumber(data.typeId),
      sendDate: mapDate(data.sendDate),
      subject: mapString(data.subject),
      body: mapString(data.body),
      readOnDate: mapDate(data.readOnDate),
      senderName: mapString(data.senderName),
    }
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}

export const mapMessageList = (data: Api.MessageListData[]): Message[] => {
  try {
    return data.map((item) => mapMessage(item))
  } catch (error) {
    debug(error)
    throw getApplicationError(ErrorCode.Api, error.message)
  }
}
