import { ListUsersRequest } from 'types/api/users'
import {
  SearchInstancesRequest,
  SearchCustomersRequest,
  UserDefaultCreditLimitsRequest,
} from 'types/api/admin'
import { SearchChargesRequest } from 'types/api/charges'
import { SearchInvoicesRequest } from 'types/api/invoices'
import { SearchTransactionsRequest } from 'types/api/transanctions'
import { CreateReportRequest } from 'types/api/reports'
import { GetWidgetRequest } from 'types/api/widgets'
import { ListTranslationsRequest } from 'types/api/translations'

export const userList = (queryParams?: ListUsersRequest) => ['USER_LIST', queryParams]
export const agentsList = (params?: ListUsersRequest) => ['AGENTS_LIST', params]

export const userDetails = (userId: string) => ['USER_DETAILS', userId]
export const userNetwork = (userId: string, instanceId?: number) => ['USER_NETWORD', userId, instanceId]

export const culturesList = () => ['CULTURE_LIST']

export const instancesList = () => ['INSTANCE_LIST']
export const instancesSearch = (queryParams?: SearchInstancesRequest) => ['INSTANCE_SEARCH', queryParams]
export const instanceDetails = (instanceId: string) => ['INSTANCE_DETAILS', instanceId]

export const roleList = () => ['ROLE_LIST']

export const languageList = () => ['LANGUAGE_LIST']
export const languageDetails = (languageId: number) => ['LANGUAGE_DETAILS', languageId]

export const currencyList = () => ['CURRENCY_LIST']
export const currencyDetails = (id: string) => ['CURRENCY_DETAILS', id]

export const customersList = () => ['CUSTOMERS_LIST']
export const customersSearch = (queryParams?: SearchCustomersRequest) => ['CUSTOMERS_SEARCH', queryParams]
export const customerDetails = (customerId: string) => ['CUSTOMER_DETAILS', customerId]

export const countryList = () => ['COUNTRY_LIST']
export const countryDetails = (countryId: number) => ['COUNTRY_DETAILS', countryId]

export const chargesList = () => ['CHARGES_LIST']
export const chargesSearch = (queryParams?: SearchChargesRequest) => ['CHARGES_SEARCH', queryParams]
export const chargeDetails = (chargeId: string, instanceId?: number) => [
  'CHARGE_DETAILS',
  chargeId,
  instanceId,
]

export const currentUserActiveBalanceInfo = () => ['CURRENT_USER_ACTIVE_BALANCE']

export const previewInvoice = () => ['PREVIEW_INVOICE']
export const invoiceList = () => ['INVOICE_LIST']
export const invoiceDetails = (invoiceId: string, instanceId?: number) => [
  'INVOICE_DETAILS',
  invoiceId,
  instanceId,
]
export const invoicesSearch = (queryParams?: SearchInvoicesRequest) => ['INVOICES_SEARCH', queryParams]

export const transactionsSearch = (queryParams?: SearchTransactionsRequest) => [
  'TRANSACTIONS_SEARCH',
  queryParams,
]

export const userDefaultCreditLimits = (queryParams?: UserDefaultCreditLimitsRequest) => [
  'USER_DEFAULT_CREDIT_LIMITS',
  queryParams,
]

export const chargesReport = (queryParams?: CreateReportRequest) => ['CHARGES_REPORT', queryParams]
export const commissionsReport = (queryParams?: CreateReportRequest) => ['COMMISSIONS_REPORT', queryParams]

export const currentBalanceWidget = (queryParams?: GetWidgetRequest) => [
  'CURRENT_BALANCE_WIDGET',
  queryParams,
]

export const monthlyChargesWidget = (queryParams?: GetWidgetRequest) => [
  'MONTHLY_CHARGES_WIDGET',
  queryParams,
]

export const yearlyChargesWidget = (queryParams?: GetWidgetRequest) => ['YEARLY_CHARGES_WIDGET', queryParams]

export const translationList = (queryParams?: ListTranslationsRequest) => ['TRANSLATIONS_LIST', queryParams]
export const translationDetails = (translationId: string) => ['TRANSLATION_DETAILS', translationId]
export const clientServedWidget = (queryParams?: GetWidgetRequest) => ['CLIENT_SERVED_WIDGET', queryParams]
export const messagesUnread = () => ['MESSAGES_UNREAD']
export const messageList = () => ['MESSAGE_LIST']
export const messageDetails = (messageId: number) => ['MESSAGE_DETAILS', messageId]
