import { config } from 'core'
import { getAxios } from './apiService'
import * as Api from 'types/api/messages'
import { AxiosError, AxiosResponse } from 'axios'
import { ApiSearchResponse } from 'types/api/common'
import { getApiError, getAuthenticationHeader } from './common'

const axios = getAxios()

export async function listMessagesUnread(): Promise<Api.MessagesUnreadData> {
  try {
    const response = await axios.get(config.api.messages(), await getAuthenticationHeader())
    return response.data as Api.MessagesUnreadData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function listMessages(): Promise<ApiSearchResponse<Api.MessageListData>> {
  try {
    const response = await axios.get(config.api.listMessages(), await getAuthenticationHeader())
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getMessageDetails(messageId: number): Promise<Api.MessageListData> {
  try {
    const response = await axios.get(config.api.getMessage(messageId), await getAuthenticationHeader())

    return response.data as Api.MessageListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function deleteMessage(messageId): Promise<AxiosResponse> {
  try {
    const response = await axios.delete(config.api.deleteMessage(messageId), await getAuthenticationHeader())
    return response as AxiosResponse
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function readMessage(messageId: number): Promise<Api.MessageListData> {
  try {
    const response = await axios.put(
      config.api.readMessage(messageId),
      { id: messageId },
      await getAuthenticationHeader(),
    )
    return response.data as Api.MessageListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function createMessage(model: Api.MessageCreateRequest): Promise<Api.MessageListData[]> {
  try {
    const response = await axios.post(config.api.newMessage(), model, await getAuthenticationHeader())
    return response.data as Api.MessageListData[]
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
