import React, { useEffect, useState } from 'react'
import Debug from 'debug'
import CurrencyEdit from 'components/currencies/CurrencyEdit'
import CurrencyDetails from 'components/currencies/CurrencyDetails'
import { Currency } from 'types/app/admin'
import { useCreateCurrency, useEditCurrency } from 'services/query/admin'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import routes from 'config/routes'
import { notification } from 'antd'
import { useRouter } from 'core/useRouter'
import { useTranslation } from 'react-i18next'

interface Props {
  currency: Currency
}

const debug = Debug('Frontend:CurrencyDetailsContainer')

const CurrencyDetailsContainer: React.FC<Props> = ({ currency }) => {
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const createCurrency = useCreateCurrency()
  const editCurrency = useEditCurrency()
  const navigate = useNavigate()
  const router = useRouter()
  const { id } = useParams()

  useEffect(() => {
    if (router.query.editmode) {
      setEditMode(true)
    } else {
      setEditMode(false)
    }
  }, [router.query])

  const onDetailsSaved = async (model: Currency) => {
    debug('onCurrencyDetailsSaved', model)
    try {
      if (currency?.id) {
        await editCurrency({ ...currency, ...model })
        navigate(generatePath(routes.currencies.url), { replace: true })
        notification.success({ message: t('messagesCrud.currencyUpdated') })
      } else {
        await createCurrency(model)
        navigate(generatePath(routes.currencies.url), { replace: true })
        notification.success({ message: t('messagesCrud.currencyCreated') })
      }
      navigate(generatePath(routes.currencies.url), { replace: true })
    } catch (error) {
      console.error(error)
      notification.error({ message: t('messagesCrud.saveError') })
    }
  }

  if (id === 'new') {
    return <CurrencyEdit onDetailsSaved={onDetailsSaved} />
  }

  if (editMode) {
    return <CurrencyEdit item={currency} onDetailsSaved={onDetailsSaved}></CurrencyEdit>
  }
  return (
    <>
      <CurrencyDetails item={currency}></CurrencyDetails>
    </>
  )
}

export default CurrencyDetailsContainer
