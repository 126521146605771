import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom'
import routes from 'config/routes'
import { InstanceDetails } from 'types/app/admin'
import FloatingLabel from 'components/FloatingLabel'

import { Button, Checkbox, Col, Form, Input, Row, Space, Spin, InputNumber } from 'antd'
import style from './styles.module.scss'

interface Props {
  instance: InstanceDetails
  onSave: (values: Json) => void
  isFetching: boolean
}

const ChangeSettings: React.FC<Props> = ({ instance, onSave, isFetching }) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [formData, setFormData] = useState(instance)
  form.setFieldsValue({
    smsTemplate: instance.smsTemplate,
    chargeExpirationDays: instance.chargeExpirationDays,
  })
  const onFinish = (values: Json) => {
    onSave(values)
  }

  if (isFetching)
    return (
      <Row align="middle" justify="center" style={{ height: '200px' }}>
        <Spin size="large" />
      </Row>
    )
  return (
    <section className={style['change-settings']}>
      <Form name="changeInstanceSettings" initialValues={{ ...instance }} onFinish={onFinish}>
        <Row gutter={[24, 24]} align="bottom">
          <Col span={12}>
            <FloatingLabel value={formData?.siteUrl}>
              <Form.Item label={t('instance.siteUrl')} name="siteUrl">
                <Input type="text" onChange={(e) => setFormData({ ...formData, siteUrl: e.target.value })} />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={12}>
            <FloatingLabel value={formData?.smsAuthId}>
              <Form.Item label={t('instance.smsAuthId')} name="smsAuthId">
                <Input
                  type="text"
                  onChange={(e) => setFormData({ ...formData, smsAuthId: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>

          <Col span={12}>
            <FloatingLabel value={formData?.smsAuthToken}>
              <Form.Item label={t('instance.smsAuthToken')} name="smsAuthToken">
                <Input
                  type="text"
                  onChange={(e) => setFormData({ ...formData, smsAuthToken: e.target.value })}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={6}>
            <Form.Item label={t('instance.sendSms')} name="sendSms" valuePropName="checked" className="m-0">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={t('instance.viewCode')} name="viewCode" valuePropName="checked" className="m-0">
              <Checkbox type="checkbox" />
            </Form.Item>
          </Col>
          <Col span={20}>
            <FloatingLabel value={formData?.smsTemplate}>
              <Form.Item
                label={t('instance.smsTemplate')}
                name="smsTemplate"
                dependencies={['chargeExpirationDays']}
                rules={[
                  {
                    required: true,
                    pattern:
                      formData.chargeExpirationDays === 0
                        ? /^(?=.*{(\bamount\b)})(?=.*{(\bcode\b)}).*$/
                        : /^(?=.*{(\bamount\b)})(?=.*{(\bcode\b)})(?=.*{(\bexpiration\b)}).*$/,
                    message: <span>{t('validationForm.smsTemplate')}</span>,
                  },
                ]}
              >
                <Input
                  type="text"
                  onChange={(e) => {
                    form.setFieldsValue({
                      smsTemplate: e.target.value,
                    })
                    setFormData({ ...formData, smsTemplate: e.target.value })
                  }}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
          <Col span={4}>
            <FloatingLabel value={formData?.chargeExpirationDays}>
              <Form.Item
                label={t('instance.chargeExpirationDays')}
                name="chargeExpirationDays"
                rules={[
                  {
                    required: true,
                    message: <span>{t('validationForm.fieldRequired')}</span>,
                  },
                ]}
              >
                <InputNumber
                  min={0}
                  keyboard={false}
                  onChange={(value) => {
                    form.setFieldsValue({
                      chargeExpirationDays: value,
                    })
                    setFormData({ ...formData, chargeExpirationDays: value })
                  }}
                />
              </Form.Item>
            </FloatingLabel>
          </Col>
        </Row>
        <Col span={24} className="align-right">
          <Form.Item className="btn mt-50" wrapperCol={{ offset: 8, span: 16 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                <Link to={routes.instances.url}>{t('btn.cancel')}</Link>
              </Button>
              <Button type="default" htmlType="submit">
                {t('btn.save')}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Form>
    </section>
  )
}

ChangeSettings.displayName = 'ChangeSettings'

export default ChangeSettings
