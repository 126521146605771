import Countries from 'components/countries/CountriesList'
import React from 'react'
import { useLoadCountryList } from 'services/query/admin'

const CountriesContainer: React.FC = () => {
  const { data, isFetched, isLoading } = useLoadCountryList()
  const dataLoading: boolean = isLoading || !isFetched
  const countriesList = data
  return <Countries isLoading={dataLoading} countriesList={countriesList} />
}

CountriesContainer.displayName = 'CountriesContainer'

export default CountriesContainer
