import { config } from 'core'
import { AxiosError } from 'axios'
import { getAxios } from './apiService'
import { getApiError, getAuthenticationHeader } from './common'
import { ApiSearchResponse } from 'types/api/common'

import * as Api from 'types/api/invoices'

const axios = getAxios()

export async function createInvoice(): Promise<Api.InvoiceListData> {
  try {
    const response = await axios.post(
      config.api.invoices(),
      { preview: false },
      await getAuthenticationHeader(),
    )
    return response.data as Api.InvoiceListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function previewInvoice(): Promise<Api.InvoiceListData> {
  try {
    const response = await axios.post(
      config.api.invoices(),
      { preview: true },
      await getAuthenticationHeader(),
    )
    return response.data as Api.InvoiceListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function payInvoice(invoiceId: string, instanceId?: number): Promise<Api.InvoiceListData> {
  try {
    const response = await axios.put(
      config.api.payInvoice(invoiceId, instanceId),
      { id: invoiceId },
      await getAuthenticationHeader(),
    )
    return response.data as Api.InvoiceListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function getInvoiceDetails(
  invoiceId: string,
  instanceId?: number,
): Promise<Api.InvoiceListData> {
  try {
    const response = await axios.get(
      config.api.invoice(invoiceId, instanceId),
      await getAuthenticationHeader(),
    )
    return response.data as Api.InvoiceListData
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}

export async function searchInvoices(
  queryParams: Api.SearchInvoicesRequest,
): Promise<ApiSearchResponse<Api.InvoiceListData>> {
  try {
    const response = await axios.post(
      config.api.searchInvoices(),
      queryParams || {},
      await getAuthenticationHeader(),
    )
    return response.data
  } catch (error) {
    throw getApiError(error as AxiosError)
  }
}
