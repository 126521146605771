import CustomersTableContainer from 'containers/customers/CustomersTable'
import React from 'react'

const CustomersPage: React.FC = () => {
  return <CustomersTableContainer />
}

CustomersPage.displayName = 'CustomersPage'

export default CustomersPage
